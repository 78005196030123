import React from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import './reviewphoto.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu'
import { Row, Col, Container } from 'react-bootstrap';
import Header from '../../../components/header/header';
import Button from '../../../components/Button/button'
import PreviewImg from '../../../assets/images/preview.svg';
import WebService from "../../../utils/Webservice"
import "react-datepicker/dist/react-datepicker.css";

class ReviewPhoto extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: new Date(),
            sNonSmilePhoto: '',
            sSmilePhoto: '',
            sLeftPhoto: '',
            sRightPhoto: '',
            sFullLeftPhoto: '',
            sFullRightPhoto: '',
            _id: localStorage.getItem("_id"),
        }
        setLocalActiveClass("AddPhoto");
        this.handleChange = this.handleChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);


    }

    onGoClick() {
        localStorage.setItem("iPhotoId", "")
        this.props.history.push('/freewayspace', { isViewFrom: 'onboarding' })
    }
    componentDidMount() {
        this.getPhotosWebServices()
    }
    handleChange(date) {
        this.setState({
            startDate: date
        });
    }
    endDateChange(date) {
        this.setState({
            endDate: date
        });
    }
    onEditFrontClick() {
        this.props.history.push('/frontface', { isViewFrom: "reviewPhoto" })
    }
    onEditSmileClick() {
        this.props.history.push('/smilingface', { isViewFrom: "reviewPhoto" })
    }
    onEditLeftClick() {
        this.props.history.push('/leftface', { isViewFrom: "reviewPhoto" })
    }
    onEditRightClick() {
        this.props.history.push('/rightface', { isViewFrom: "reviewPhoto" })
    }
    onEditFullLeftClick() {
        this.props.history.push('/leftbody', { isViewFrom: "reviewPhoto" })
    }
    onEditFullRightClick() {
        this.props.history.push('/rightbody', { isViewFrom: "reviewPhoto" })
    }
    // getPhotosWebServices
    async getPhotosWebServices() {
        try {
            const data = new FormData()
            data.append('iUserId', this.state._id)
            let response = await WebService.getPhotos(data);

            this.setState({ isLoading: false });
            if (response.status) {
                console.log("response" + JSON.stringify(response))
                this.handleSuccessResponse(response);
            } else {
                console.log("response" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleSuccessResponse(response) {
        let responseData = response.data.userPhotoData

        // responseData.map((item, i) =>
        if (response.data.userPhotoData.length > 0) {
            this.setState({
                sNonSmilePhoto: responseData[0].sNonSmilePhoto,
                sSmilePhoto: responseData[0].sSmilePhoto,
                sLeftPhoto: responseData[0].sLeftPhoto,
                sRightPhoto: responseData[0].sRightPhoto,
                sFullLeftPhoto: responseData[0].sFullLeftPhoto,
                sFullRightPhoto: responseData[0].sFullRightPhoto
            })
            // )
        }
    }

    render() {
        let { sNonSmilePhoto, sSmilePhoto, sLeftPhoto, sRightPhoto, sFullLeftPhoto, sFullRightPhoto } = this.state
        console.log(sNonSmilePhoto);

        return (
            <div className='main-ReviewPhoto'>
                <Header></Header>
                <SideMenu {...this.props}></SideMenu>
                <div className='inner-ReviewPhoto'>
                    <Scrollbars className='review-Scroll'>
                        <div className='main-Container'>
                            <div className='img-Container'>
                                {/* <img src={PreviewImg} className="logo" alt="logo" /> */}
                            </div>
                            <h2 className='paddingB24'>Review your images</h2>
                            <Container>
                                <Row>
                                    <Col lg={4} md={4} sm={6} xs={12}>
                                        <div className='main-Img-Section'>
                                            <div className='preview-Section'>
                                                {sNonSmilePhoto == undefined || "" ? <img resizemode="stretch" src={require("../../../assets/images/Front Face.png")} /> : <img resizemode="stretch" src={sNonSmilePhoto} />}
                                                <div className='link-Section'>
                                                    <span className='innerTitle'>Front</span>
                                                    <span className="floatR aLink" onClick={() => this.onEditFrontClick()}>Edit</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={4} sm={6} xs={12}>
                                        <div className='main-Img-Section'>
                                            <div className='preview-Section'>
                                                {sSmilePhoto == undefined || "" ? <img resizemode="stretch" src={require("../../../assets/images/Smile Face.png")} /> : <img resizemode="stretch" src={sSmilePhoto} />}
                                                {/* <img src={sSmilePhoto} /> */}
                                                <div className='link-Section'>
                                                    <span className='innerTitle'>Smile</span>
                                                    <span className="floatR aLink" onClick={() => this.onEditSmileClick()}>Edit</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={4} sm={6} xs={12}>
                                        <div className='main-Img-Section'>
                                            <div className='preview-Section'>
                                                {sLeftPhoto == undefined || "" ? <img resizemode="stretch" src={require("../../../assets/images/Left Face.png")} /> : <img resizemode="stretch" src={sLeftPhoto} />}
                                                {/* <img src={sLeftPhoto} /> */}
                                                <div className='link-Section'>
                                                    <span className='innerTitle'>Left</span>
                                                    <span className="floatR aLink" onClick={() => this.onEditLeftClick()}>Edit</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={4} sm={6} xs={12}>
                                        <div className='main-Img-Section'>
                                            <div className='preview-Section'>
                                                {sRightPhoto == undefined || "" ? <img resizemode="stretch" src={require("../../../assets/images/Right Face.png")} /> : <img resizemode="stretch" src={sRightPhoto} />}
                                                {/* <img src={sRightPhoto} /> */}
                                                <div className='link-Section'>
                                                    <span className='innerTitle'>Right</span>
                                                    <span className="floatR aLink" onClick={() => this.onEditRightClick()}>Edit</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={4} sm={6} xs={12}>
                                        <div className='main-Img-Section'>
                                            <div className='preview-Section'>
                                                {sFullLeftPhoto == undefined || "" ? <img resizemode="stretch" src={require("../../../assets/images/Full Left.png")} /> : <img resizemode="stretch" src={sFullLeftPhoto} />}
                                                {/* <img src={sFullLeftPhoto} /> */}
                                                <div className='link-Section'>
                                                    <span className='innerTitle'>Full Left</span>
                                                    <span className="floatR aLink" onClick={() => this.onEditFullLeftClick()}>Edit</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={4} sm={6} xs={12}>
                                        <div className='main-Img-Section'>
                                            <div className='preview-Section'>
                                                {sFullRightPhoto == undefined || "" ? <img resizemode="stretch" src={require("../../../assets/images/Full Right.png")} /> : <img resizemode="stretch" src={sFullRightPhoto} />}
                                                {/* <img src={sFullRightPhoto} /> */}
                                                <div className='link-Section'>
                                                    <span className='innerTitle'>Full Right</span>
                                                    <span className="floatR aLink" onClick={() => this.onEditFullRightClick()}>Edit</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <Button title='Submit' onClick={() => this.onGoClick()}></Button>
                        </div>
                    </Scrollbars>
                </div>
            </div >

        )
    }
}




export default ReviewPhoto;

