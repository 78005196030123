import React from 'react';
import { Button } from 'react-bootstrap';
import './button.scss';
import PropTypes from 'prop-types';
import loaderImg from '../../assets/images/loaderImg.svg';


class ButtonCustom extends React.Component {
    constructor(props, context) {
        super(props, context);
    }





    render() {
        let { title, children, disabled, loading } = this.props
        return (
            <Button
                className='submitBtn'
                onKeyDown={this.props.onKeyDown}
                disabled={disabled}
                onClick={this.props.onClick}>{!loading && title}{children}
                {loading &&
                    <img className="loaderImg" src={loaderImg} />
                }</Button>
        )
    }
}


Button.propTypes = {
    onClick: PropTypes.func,
    loading: false,
};

export default ButtonCustom;

