import React from 'react';
import { setLocalActiveClass, setStoredData, getStoredData } from "../../../utils/helper";
import './setreminder.scss';
import { Scrollbars } from "react-custom-scrollbars";
import { Row, Col } from 'react-bootstrap';
import HomeSideMenu from '../../../components/homesidebar/homesidebar'
import HomeHeader from '../../../components/homeheader/homeheader';
import Button from '../../../components/Button/button'
import DropdownMenu from '../../../components/dropdown/dropdown';
import DatePicker from "react-datepicker";
import WebService from "../../../utils/Webservice"
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import "react-datepicker/dist/react-datepicker.css";
var moment = require('moment');

const optionsAM = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '24:00']
const optionsPM = ['13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '24:00']
const optionsTouch = ['AM', 'PM']
class QuestionBiweekly extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: '',
            titleAm: '',
            titlePm: '',
            title: '',
            toaster: false,
            message: "",
            isShow: false,
            errorText: false,
            iUserId: getStoredData("_id") === 'undefined' ? Buffer.from(this.props.match.params._id, 'base64').toString("ascii") : getStoredData("_id"),
            disableBtnAm: false,
            disableBtnPm: false, disableBtn: false
        }
        setLocalActiveClass("Home")
    }
    onGoClick() {
        this.setReminderWebServices()
        //    this.props.history.push('/')
    }
    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }
    handleChange(date) {
        // console.log(date);
        (date !== '' || date !== undefined ? this.setState({ disableBtnPm: true }) : this.setState({ disableBtnPm: false }))
        this.setState({
            startDate: date
        }, () => {
            let diff = moment(new Date(this.state.startDate)).diff(moment(new Date()), 'days')
            if (diff === 0) {
                let selectedDateTime = moment(this.state.startDate).format("YYYY-MM-DD") + " " + this.state.selOption + ":00"
                if (new Date(selectedDateTime) <= new Date()) {
                    this.setState({ errorText: true })
                } else {
                    this.setState({ errorText: false })
                }
            } else {
                this.setState({ errorText: false })
            }
        });
    }
    endDateChange(date) {
        this.setState({
            endDate: date
        });
    }
    handleSelectEventAm(eventKey) {
        this.setState({ sEthnicity: eventKey })
    }
    onItemSelectValueAm(e, option, eventKey) {
        console.log(e.target.text, option, eventKey);

        (e.target.text.length > 0 ? this.setState({ disableBtnAm: true }) : this.setState({ disableBtnAm: false }))
        if (this.state.startDate === '') {
        } else {
            let diff = moment(new Date(this.state.startDate)).diff(moment(new Date()), 'days')
            if (diff === 0) {
                let selectedDateTime = moment(this.state.startDate).format("YYYY-MM-DD") + " " + option + ":00"
                if (new Date(selectedDateTime) <= new Date()) {
                    this.setState({ errorText: true })
                } else {
                    this.setState({ errorText: false })
                }
            }
        }

        this.setState({
            titleAm: e.target.text,
            selOption: option,
            value: eventKey,
        })

    }
    handleSelectEventPm(eventKey) {
        this.setState({ sEthnicity: eventKey })
    }

    onItemSelectValuePm(e, option, eventKey) {
        (e.target.text.length > 0 ? this.setState({ disableBtnPm: true }) : this.setState({ disableBtnPm: false }))

        this.setState({
            titlePm: e.target.text,
            selOption: option,
            value: eventKey,
        })
    }
    handleSelectEvent(eventKey) {
        this.setState({ sEthnicity: eventKey })
    }

    onItemSelectValue(e, option, eventKey) {
        (e.target.text.length > 0 ? this.setState({ disableBtn: true }) : this.setState({ disableBtn: false }))

        this.setState({
            title: e.target.text,
            selOption: option,
            value: eventKey,
        })
    }
    async  setReminderWebServices() {
        let isQuestionnaireDue = getStoredData("isQuestionnaireDue")
        let { titlePm, titleAm, titleSession, title, startDate, iUserId
        } = this.state
        let date = startDate === '' ? new Date() : startDate
        this.setState({ isLoading: true });
        try {
            let obj = {
                iUserId: iUserId,
                dReminderDate: date,
                sReminderTime: titleAm,
                // sReminderPM: titlePm,
                // nTouchTime: optionsTouch.indexOf(title)

            };
            //  console.log(JSON.stringify(obj));

            let response = await WebService.setReminder(obj);
            this.setState({ isLoading: false });

            if (response.status) {
                console.log("response" + response.status);
                this.setState({ toaster: true, message: response.MESSAGES, isShow: true })
                //setFilledFlags(CONSTANTS.KEYS.KeyPreferencesFilled, true)
                // this.props.history.push('/savepreferences', { isViewFrom: this.state.isViewFrom })
                setTimeout(() => {
                    if (isQuestionnaireDue === 'true') {
                        console.log(isQuestionnaireDue, "isQuestionnaireDue");

                        this.props.history.push('/QuestionBiweekly', { isViewFrom: 'home' })

                    } else {
                        this.props.history.push('/3mmhome', { isViewFrom: 'home' })

                    }
                }, 3000)
            } else {
                this.setState({ toaster: true, message: response.MESSAGES, isShow: true })
                console.log("response" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }
    render() {
        let { titleAm, titlePm, toaster, message, isShow, titleSession, value, title, errorText } = this.state
        let amTitle = titleAm === null || titleAm === '' ? "Select" : this.state.titleAm
        let pmTitle = titlePm === null || titlePm === '' ? "Select" : this.state.titlePm
        let setTitle = title === null || title === '' ? "Select" : this.state.title

        let disable = (this.state.disableBtnAm && this.state.disableBtnPm && !errorText) ? false : true
        return (
            <div className='main-SetReminder'>
                <HomeSideMenu {...this.props}></HomeSideMenu>
                <HomeHeader></HomeHeader>
                <div className='inner-SetReminder'>
                    <Scrollbars>
                        <div className='main-Container'>
                            {toaster && <ToastComponent message={message} show={isShow} onClose={() => this.setState({ toaster: false })} delay={3000} autohide >}></ToastComponent>}

                            <h4 className='paddingB15'>When would you like to set your next reminder ?</h4>
                            <form className='question-Form'>

                                <Row>

                                    <Col lg={6} sm={6} xs={6} className='custom-Datepicker'>
                                        <label className='cust-Label'>Date</label>
                                        <DatePicker
                                            showYearDropdown
                                            showMonthDropdown
                                            peekNextMonths
                                            minDate={new Date()}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YY"
                                            onChangeRaw={this.handleDateChangeRaw}
                                            className="inputBox"
                                            selected={this.state.startDate}
                                            onChange={this.handleChange.bind(this)}
                                        />
                                        {/* <span className='error-msg'>Touch test should not be more than 60 minutes</span> */}
                                    </Col>
                                    <Col lg={6} sm={6} xs={6} className='paddingB15'>
                                        <label className='cust-Label'>Time</label>
                                        <DropdownMenu onSelect={this.handleSelectEventAm.bind(this)} onItemSelect={this.onItemSelectValueAm.bind(this)} title={amTitle} options={optionsAM}></DropdownMenu>
                                        {errorText && <span className='error-msg'>Please select the time greater than current time.</span>}
                                    </Col>
                                </Row>
                            </form>
                            <Button title='Submit' disabled={disable} onClick={() => this.onGoClick()}></Button>
                        </div>
                    </Scrollbars>
                </div>
            </div>

        )
    }
}




export default QuestionBiweekly;

