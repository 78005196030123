import React from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import './addphotoinfo.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu';
import Header from '../../../components/header/header';
import Addphoto from '../../../assets/images/addPhoto.svg';

class AddPhotoInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
        setLocalActiveClass("AddPhoto")
    }
    onNextClick() {
        this.props.history.push('/frontface', { isViewFrom: "addPhotoInfo" })
    }


    render() {
        return (
            <div className='main-photoInfoPage'>
                <Header></Header>
                <SideMenu {...this.props}></SideMenu>
                <div className='inner-photoInfo'>
                    {/* <Scrollbars> */}
                    <div className='main-Container'>
                        <div className='img-Container'>
                            <img src={Addphoto} className="logo" alt="logo" />
                        </div>
                        <h2 className='paddingB15'>Upload Photo</h2>
                        <div className='content-Section'>
                            <h5 className='paddingB15'>It is important to line your photo up as close as possible to the outline on the camera screen, to ensure that your photos are as matching when it comes to compare them. It is important to align the pupils of the eye as closely as possible. The shape of the face can change but the distance between the pupils will tend to remain the same.</h5>
                        </div>
                        <div className='content-Section'>
                            <h5 className='paddingB15'>Photographs should be standardised with the same lighting and no shadows. It is best to avoid standing near a window as outside light can affect the picture. Try to keep the pictures the same size and keep the same settings on the camera. Ideally, the face should be makeup free and the hair tied back with a band.</h5>
                        </div>
                        <span className='paddingB15 nextLink' ><span onClick={() => this.onNextClick()} className='highlighted textCapital '>next<span className='icon-right icon-css'></span></span></span>
                    </div>
                    {/* </Scrollbars> */}
                </div>
            </div >
        )
    }
}




export default AddPhotoInfo;

