import React, { useState } from 'react';
import './adminchangepwd.scss';
import { getStoredData } from "../../../utils/helper";
import { Scrollbars } from "react-custom-scrollbars";
import AdminHeader from '../../../components/adminheader/adminheader';
import TextField from '../../../components/textfield/textfield';
import Button from "../../../components/Button/button";
import CONSTANTS from '../../../utils/constants';
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import WebService from "../../../utils/Webservice"
class AdminChangePassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            _UserId: getStoredData("adminId"),
            toaster: false,
            message: "", isShow: false,
            errorText: false,
            oldPassword: {
                value: "",
                isValid: false,
                message: ""
            },
            password: {
                value: "",
                isValid: false,
                message: ""
            },
            confirmPassword: {
                value: "",
                isValid: false,
                message: ""
            },
            errorMessage: '',
            isToastShow: false,
            showPass: false, showConfirmPass: false
        }
    }

    onSubmit() {
        this.validateFields('password', this.state.password.value)
        this.validateFields('confirmPassword', this.state.confirmPassword.value)
        this.changePasswordWebServices()
    }
    handleUserInput = e => {
        let name = e.target.name;
        let value = e.target.value;
        var obj = {};
        obj[name] = {
            value: value
        };
        this.setState({
            showEye: name == "password" && value != "" ? true : false
        });
        this.setState(obj, () => {
            this.validateField(name, value);
        });
    };
    // text on change //
    validateField(fieldName, value) {
        let valid = false;
        let message = "";
        switch (fieldName) {
            case "password":
                valid = value.length > 0;

                !valid && (message = CONSTANTS.MESSAGES.VALIDATION.PASSWORD);
                break;
            case "confirmPassword":
                valid = this.state.password.value == value;

                !valid && (message = CONSTANTS.MESSAGES.VALIDATION.CONFIRM_PASSWORD);
                break;
        }
        var obj = {};
        obj[fieldName] = {
            message: message,
            isValid: valid,
            value: value
        };
        this.setState(obj, () => {
            fieldName == "forgetEmail"
                ? this.validateForgotForm()
                : this.validateForm();
        });
    }
    // validation on Button//
    validateFields(fieldName, value) {
        let valid = false;
        let message = "";
        switch (fieldName) {
            case "password":
                valid = value.length > 7 && value.match(CONSTANTS.REGEX.SPECIAL_CHARECTERS) && value.match(CONSTANTS.REGEX.NUMBER)
                    && value.match(CONSTANTS.REGEX.CAPITAL) && value.match(CONSTANTS.REGEX.LOWERCASE);

                !valid &&
                    (message =
                        value.length <= 0
                            ? CONSTANTS.MESSAGES.VALIDATION.PASSWORD
                            : CONSTANTS.MESSAGES.VALIDATION.INVALID_PASSWORD);
                break;
        }
        var obj = {};
        if (!valid) {
            this.setState({
                isToastShow: true,
                errorMessage: message
            })
        }

        this.setState(obj, () => {
            fieldName == "forgetEmail"
                ? this.validateForgotForm()
                : this.validateForm();
        });
    }

    validateForm() {
        this.setState({
            isFormValid: this.state.confirmPassword.isValid
        });
    }

    //*****************************Forgot Password Validation*******************
    validateForgotForm() {
        this.setState({
            isForgotValid: this.state.forgetEmail.isValid
        });
    }
    onForgotPassword = () => {
        this.setState({
            loginForm: false,
            forgotForm: true,
            showTopBar: false,
            animated: true
        });
    };

    async changePasswordWebServices() {

        try {
            let obj = {
                _UserId: this.state._UserId,
                oldpassword: this.state.oldPassword.value,
                newpassword: this.state.confirmPassword.value
            };
            let response = await WebService.adminChangePassword(obj);
            this.setState({ isLoading: false });
            if (response.status) {
                this.setState({ toaster: true, message: response.MESSAGES, isShow: true, errorText: false, isWarning: false })
                setTimeout(() => {
                    this.props.history.push('/admindashboard')
                }, 3000);
                // console.log("SUCCESS::" + response.status);
            } else {
                this.setState({ toaster: true, message: response.MESSAGES, isShow: true, isWarning: true, errorText: true })
                //console.log("Error::" + response.status);
            }
        } catch (error) {
            // console.log(error);
        }
    }
    render() {
        let { profession, treatment, toaster, message, isShow, isWarning } = this.state
        return (
            <div className='main-AdminChangepwd'>
                <AdminHeader {...this.props} ></AdminHeader>
                <div className='inner-Profile'>
                    <Scrollbars className='home-Scroll'>
                        {toaster && <ToastComponent isWarning={isWarning} message={message} show={isShow} onClose={() => { this.setState({ toaster: false }); }} delay={3000} autohide ></ToastComponent>}
                        <div className='main-Container'>
                            <div className='img-upload'>
                                <img src={require('../../../assets/images/resetpassword.png')} className='profileimg' />
                            </div>
                            <h4>Change Password</h4>
                            <h5>Please provide below mentioned details to change your password.</h5>
                            <div className='form'>
                                <TextField title="Old Password" type="password"
                                    autoFocus={true}
                                    value={this.state.oldPassword.value}
                                    name="oldPassword"
                                    onChange={this.handleUserInput.bind(this)}
                                    errorMessage={this.state.password.message}
                                    placeholder="******" >
                                </TextField>
                                <TextField title="Password"
                                    value={this.state.password.value}
                                    name="password"
                                    type={this.state.showPass ? "text" : "password"}
                                    iconClass={
                                        this.state.showEye
                                            ? this.state.showPass
                                                ? "icon-eye"
                                                : "icon-eyeoff"
                                            : "icon-eyeoff"
                                    }
                                    onClick={() =>
                                        this.setState({ showPass: !this.state.showPass })
                                    }
                                    onChange={this.handleUserInput.bind(this)}
                                    errorMessage={this.state.password.message}
                                    placeholder="******" >
                                </TextField>
                                <TextField title="Confirm Password" 
                                    value={this.state.confirmPassword.value}
                                    name="confirmPassword"
                                    type={this.state.showConfirmPass ? "text" : "password"}
                                    iconClass={
                                        this.state.showEye
                                            ? this.state.showConfirmPass
                                                ? "icon-eye"
                                                : "icon-eyeoff"
                                            : "icon-eyeoff"
                                    }
                                    onClick={() =>
                                        this.setState({ showConfirmPass: !this.state.showConfirmPass })
                                    }
                                    onChange={this.handleUserInput.bind(this)}
                                    errorMessage={this.state.confirmPassword.message}
                                    placeholder="******" >
                                </TextField>
                                <Button title="Change Password"
                                    disabled={!this.state.isFormValid} onClick={() => this.onSubmit()}></Button>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        )
    }
}




export default AdminChangePassword;

