import React from 'react';
import { setLocalActiveClass, getStoredData, setStoredData } from "../../../utils/helper";
import './photos.scss';
import { Scrollbars } from "react-custom-scrollbars";
import HomeSideMenu from '../../../components/homesidebar/homesidebar';
import AdminSideMenu from '../../../components/adminsidebar/adminsidebar';
import HomeHeader from '../../../components/homeheader/homeheader';
import AdminHeader from '../../../components/adminheader/adminheader';
import Button from '../../../components/Button/button'
import CompareModal from '../../../components/comparemodal/comparemodal';
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import WeeklyPhotos from '../../../components/weeklyphotos/weeklyphotos';
import WebService from "../../../utils/Webservice"
import CalenderFilter from '../../../components/CalenderFilter/CalenderFilter';
import nophoto from '../../../assets/images/nophotos.svg';
var moment = require('moment');



class Photos extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: new Date(),
            isChecked: false,
            setModalShow: false,
            modalShow: false,
            CalendermodalShow: false,
            Checked: false,
            isDisabled: false,
            selectedImg: '',
            sNonSmilePhoto: '',
            sSmilePhoto: '',
            sLeftPhoto: '',
            sRightPhoto: '',
            sFullLeftPhoto: '',
            sFullRightPhoto: '',
            response: [],
            selectedFirstPhoto: '',
            selectedSecondPhoto: '',
            filteredPhotos: [],
            iUserId: (this.props.history.location.state === undefined) ? Buffer.from(this.props.match.params._id, 'base64').toString("ascii") : (this.props.history.location.state.isViewFrom === 'adminPhoto') ? getStoredData("userId") : getStoredData("_id"),
            isViewFrom: this.props.history.location.state === undefined ? 'adminPhoto' : this.props.history.location.state.isViewFrom,
            isFilterApplied: false,
            firstSelectedDate: "December 05, 2019",
            lastSelectedDate: "January 01,2019",
            noPhotosFound: false, defaultStateVal: false

        }
        this.props.history.location.state === undefined ? setLocalActiveClass("AdminPhoto") : this.props.history.location.state.isViewFrom === 'home' || this.props.history.location.state.isViewFrom === 'homeSidemenu' ? setLocalActiveClass("Photo") : setLocalActiveClass("AdminPhoto")
    }

    componentDidMount() {
        setStoredData('userId', this.state.iUserId)
        this.getUserPhotosWebServices()
    }

    async getUserPhotosWebServices() {
        try {
            let obj = {
                iUserId: this.state.iUserId,
            };
            let response = await WebService.getUserPhotos(obj);
            this.setState({ isLoading: false });

            if (response.status) {
                this.disableCompareButton(true)
                console.log("response" + response.status);
                this.handleSuccessResponse(response);
            } else {
                console.log("response" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleSuccessResponse(response) {
        let responseData = response.data.userPhotoData
        { (responseData.length) === 0 && this.setState({ noPhotosFound: true }) }
        let resultDates = responseData.map(item => ({ dCreatedAt: item.dCreatedAt, dFormatted: (moment(item.dCreatedAt).format('MMMM DD, YYYY')) }))
        { (resultDates.length) === 1 ? this.setState({ isDisabled: true }) : this.setState({ isDisabled: false }) }
        this.setState({
            response: responseData,
            responseDate: resultDates,
            firstSelectedDate: moment(responseData[0].dCreatedAt).format('MMMM DD, YYYY'),
            lastSelectedDate: moment(responseData[responseData.length - 1].dCreatedAt).format('MMMM DD, YYYY'),
        })
    }

    onAddPhotoClick() {
        setStoredData("iPhotoId", "")
        this.props.history.push('/frontface', { isViewFrom: "home" })
    }

    onCompareClick() {
        if (this.state.isChecked) {
            this.setModalShow(true)
        }
        else {
            this.setState({
                isChecked: !this.state.isChecked,
                isDisabled: true
            })
        }
    }
    getImageUrl(identifier, imgName) {
        switch (imgName) {
            case 'Front':
                return identifier.sNonSmilePhoto
            case "Smile":
                return identifier.sSmilePhoto
            case "Left":
                return identifier.sLeftPhoto
            case "Right":
                return identifier.sRightPhoto
            case "FullLeft":
                return identifier.sFullLeftPhoto
            case "FullRight":
                return identifier.sFullRightPhoto
        }
    }

    onCheckedClick(value) {
        this.setState({
            Checked: !this.state.Checked,
            selectedImg: value,
        })
    }

    setModalShow = (show) => {
        this.setState({ modalShow: show })
    }

    disableCompareButton(isDisabled) {
        this.setState({ isDisabled })
    }

    setCalenderModalShow() {
        if (this.state.CalendermodalShow != true) {
            this.setState({ CalendermodalShow: true })
        } else {
            this.setState({ CalendermodalShow: false })
        }
    }


    getSelectedPhotos(selectedImages) {
        console.log(selectedImages);
        let firstImage = ''
        let secondImage = ''
        for (let i = 0; i < selectedImages.length; i++) {
            let temp = selectedImages[i].split("_")
            let item = this.state.response[temp[0]]
            if (i === 0) {
                firstImage = this.getImageUrl(item, temp[1])
            } else {
                secondImage = this.getImageUrl(item, temp[1])
            }
        }
        this.setState({
            selectedFirstPhoto: firstImage,
            selectedSecondPhoto: secondImage
        })
    }



    calenderHide(selectedDate) {
        this.setState({ CalendermodalShow: false })
        let resultDates = this.state.response.map(item => ({ dCreatedAt: item.dCreatedAt, dFormatted: (moment(item.dCreatedAt).format('MMMM DD, YYYY')) }))
        this.setState({ responseDate: resultDates })
        this.setState({
            firstSelectedDate: selectedDate[0],
            lastSelectedDate: selectedDate.length > 1 ? (moment(selectedDate[1]).format('MMMM DD, YYYY')) : (moment(this.state.response[this.state.response.length - 1].dCreatedAt).format('MMMM DD, YYYY')),
            isFilterApplied: true
        }, () => {
            let firstSelDateArray = []
            let lastSelDateArray = []
            this.state.filteredPhotos = []
            firstSelDateArray = this.state.response.filter(object => {
                return ((moment(object.dCreatedAt).format('MMMM DD, YYYY')) === this.state.firstSelectedDate)
            });
            lastSelDateArray = this.state.response.filter(obj => {
                return ((moment(obj.dCreatedAt).format('MMMM DD, YYYY')) === this.state.lastSelectedDate)
            });
            this.setState({ filteredPhotos: firstSelDateArray.concat(lastSelDateArray) })
        })
    }
    defaultState = (val) => {
        this.setState({ defaultStateVal: false })
    }
    onClickClose = (show) => {
        // window.location.reload(false)
        this.setState({
            modalShow: show,
            defaultStateVal: true,
            isChecked: false,
            // isDisabled: false,
        })

    }
    render() {
        const { modalShow, CalendermodalShow, responseDate, noPhotosFound, isViewFrom, firstSelectedDate, lastSelectedDate } = this.state
        // console.log(this.state.isFilterApplied, "this.state.isFilterApplied");
        // console.log(this.state.selectedFirstPhoto, "+" + this.state.selectedSecondPhoto);
        return (
            <div className='main-Photos'>
                {isViewFrom === 'adminPhoto' && < AdminHeader  {...this.props} ></AdminHeader>}
                {
                    this.state.isViewFrom === "home" ? <HomeSideMenu {...this.props} showMorePopup={true}></HomeSideMenu> :
                        this.state.isViewFrom === "adminPhoto" ? <AdminSideMenu {...this.props}></AdminSideMenu> : <HomeSideMenu {...this.props}></HomeSideMenu>
                }
                {isViewFrom != 'adminPhoto' && <HomeHeader {...this.props}></HomeHeader>}
                <div className='inner-Photos'>
                    <CompareModal  {...this.props} onClickClose={() => this.onClickClose(false)} leftImage={this.state.selectedFirstPhoto} rightImage={this.state.selectedSecondPhoto} show={modalShow} onHide={() => this.setModalShow(false)}></CompareModal>

                    <div className='main-Container'>
                        {isViewFrom !== 'adminPhoto' && <div className="tooltip_main">
                            <div className={this.state.infoView ? 'divclass show ' : 'divclass'} onClick={() => this.setState({ infoView: !this.state.infoView })}></div>
                            <div className={this.state.infoView ? 'tool-Tips show' : 'tool-Tips'} >
                                <a className="tip icon-info cursor" onClick={() => this.setState({ infoView: !this.state.infoView })}>
                                    <span className="info-detail">This is where you will be able to compare sets of photographs. When you have more than one set of photographs, a compare button will appear at the bottom of the screen. Remember to first select the "before" picture and then select the most recent one.You can only add photos on your smartphone.
                            </span>
                                </a>
                            </div>
                        </div>
                        }                        {isViewFrom !== 'adminPhoto' && <div className='add-Photos paddingB24'>
                            <div className='upload-Button'>
                                {!noPhotosFound && <div className="" >
                                    <span className="date-col" onClick={this.setCalenderModalShow.bind(this)}>{firstSelectedDate} - {lastSelectedDate} </span>
                                    {CalendermodalShow && <CalenderFilter
                                        filteredPhotos={this.state.filteredPhotos}
                                        calenderShow={CalendermodalShow}
                                        onHide={this.calenderHide.bind(this)}
                                        responseDate={responseDate} />}
                                </div>}

                                <div className="addphotos">

                                    {/* <Button title='+ Add photos' onClick={() => this.onAddPhotoClick()}></Button> */}
                                </div>
                            </div>
                        </div>}
                        {this.state.response.length == 0 ? <div className="Nodata"><img src={nophoto} /><p>No Photo Found</p></div> :
                            <div>
                                <Scrollbars className='photo-Scrolls'>

                                    <WeeklyPhotos
                                        disableCompareButton={this.disableCompareButton.bind(this)}
                                        setData={this.getSelectedPhotos.bind(this)}
                                        title={"Saturday October 05"}
                                        {...this.props} photosResponse={this.state.isFilterApplied ? this.state.filteredPhotos : this.state.response}
                                        selectedImg={this.state.selectedImg}
                                        isChecked={this.state.isChecked}
                                        isDisabled={this.state.isDisabled}
                                        defaultStateVal={this.state.defaultStateVal}
                                        defaultState={() => this.defaultState()}
                                        onCheckedClick={() => this.onCheckedClick("test")}></WeeklyPhotos>

                                </Scrollbars>
                                <div className='compare-Button'>
                                    {!noPhotosFound && <Button title='Compare' disabled={this.state.isDisabled} onClick={() => this.onCompareClick()}><span className='icon-compare icon-css'></span></Button>}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div >

        )
    }
}




export default Photos;

