import React from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import './viewQuestionnaireBiweekly.scss';
import { Scrollbars } from "react-custom-scrollbars";
import { Row, Col } from 'react-bootstrap';
import HomeSideMenu from '../../../components/homesidebar/homesidebar'
import HomeHeader from '../../../components/homeheader/homeheader';
import QuestionImg from '../../../assets/images/question.svg';
import WebService from "../../../utils/Webservice"
import AdminSideMenu from '../../../components/adminsidebar/adminsidebar';
import CalenderFilterQue from '../../../components/CalenderFilterQue/CalenderFilterQue';
import AdminHeader from '../../../components/adminheader/adminheader';
var moment = require('moment');

class QuestionBiweekly extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isViewFrom: this.props.history.location.state.isViewFrom,
            _id: (this.props.history.location.state === undefined) ? Buffer.from(this.props.match.params._id, 'base64').toString("ascii") : (this.props.history.location.state.isViewFrom !== 'homeQuestionnaire-Biweekly') ? localStorage.getItem("userId") : localStorage.getItem("_id"),
            response: [],
            CalendermodalShow: false,
            filteredPhotos: [],
            responseDate: [],
            ansArray: [],
            firstSelectedDate: "",
            noDataFound: false
        }
        setLocalActiveClass("viewQuestionnaireBiweekly")
    }

    componentDidMount() {
        this.getQuestionnaireWebService()
    }
    async  getQuestionnaireWebService() {
        try {
            let obj = {
                iUserId: this.state._id,
            };
            let response = await WebService.getQuestionnaire(obj);
            this.setState({ isLoading: false });

            if (response.status) {
                console.log(JSON.stringify(response));
                this.handleSuccessResponse(response.data);
            } else {
                console.log("response" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }
    handleSuccessResponse(response) {
        if (response.length === 0) {
            this.setState({ noDataFound: true });
            return;
        }
    
        let resultDates = response.map(item => ({
            dCreatedAt: item.dCreatedAt,
            dFormatted: moment(item.dCreatedAt).format('MMMM DD, YYYY')
        }));
    
        this.setState({ isDisabled: resultDates.length === 1 });
    
        let defaultData = response[response.length - 1]["aQuestionBiweekly"];
    
        // Handle both formats
        let vals = defaultData.map(a => {
            let value = Object.values(a)[0];
            return typeof value === 'string' ? value : value.value;
        });
    
        this.setState({
            ansArray: vals,
            response,
            responseDate: resultDates,
            firstSelectedDate: moment(response[response.length - 1].dCreatedAt).format('MMMM DD, YYYY'),
        });
    }
    setCalenderModalShow() {
        if (this.state.CalendermodalShow != true) {
            this.setState({ CalendermodalShow: true })
        } else {
            this.setState({ CalendermodalShow: false })
        }
    }

    calenderHide(selectedDate) {
        this.setState({ CalendermodalShow: false });
    
        let resultDates = this.state.response.map(item => ({
            dCreatedAt: item.dCreatedAt,
            dFormatted: moment(item.dCreatedAt).format('MMMM DD, YYYY')
        }));
    
        this.setState({
            responseDate: resultDates,
            firstSelectedDate: selectedDate[0],
            isFilterApplied: true
        }, () => {
            let firstSelDateArray = this.state.response.filter(object => 
                moment(object.dCreatedAt).format('MMMM DD, YYYY') === this.state.firstSelectedDate
            );
    
            let finalArray = firstSelDateArray[0]["aQuestionBiweekly"];
    
            // Handle both formats
            let vals = finalArray.map(a => {
                let value = Object.values(a)[0];
                return typeof value === 'string' ? value : value.value;
            });
    
            this.setState({ ansArray: vals });
        });
    }
    render() {
        let { CalendermodalShow, responseDate, noDataFound, finalResponse, firstSelectedDate, ansArray, isViewFrom } = this.state

        return (
            <div className='main-QuestionBiweekly-view'>
                {isViewFrom === 'adminQuestionnaire-Biweekly' && <AdminHeader  {...this.props} ></AdminHeader>}
                {isViewFrom === 'homeQuestionnaire-Biweekly' ? <HomeSideMenu {...this.props} showMorePopup={true}></HomeSideMenu> : <AdminSideMenu {...this.props} showMorePopup={true}></AdminSideMenu>}
                {isViewFrom === 'homeQuestionnaire-Biweekly' && <HomeHeader {...this.props}></HomeHeader>}
                <div className='inner-QuestionBiweekly'>
                    <Scrollbars>
                        <div className='main-Container'>
                            <div className='img-Container'>
                                <img src={QuestionImg} className="logo" alt="logo" />
                            </div>
                            <h2 className='paddingB15'>Questionnaire - Biweekly</h2>
                            {!noDataFound ? <div>
                                <div className="upload-Button" >
                                    <span className="date-col" onClick={this.setCalenderModalShow.bind(this)}>{firstSelectedDate} </span>
                                    {CalendermodalShow &&
                                        <CalenderFilterQue
                                            filteredPhotos={this.state.filteredPhotos}
                                            calenderShow={CalendermodalShow}
                                            onHide={this.calenderHide.bind(this)}
                                            responseDate={responseDate} />}
                                </div>
                                <form className='question-Form'>
                                    <Row>

                                        <Col xl={6} lg={6} md={6} sm={6} className='paddingB24'>
                                            <h5 className='fontM'> Do you clench your teeth in day time?</h5>
                                            <h5>{ansArray[0]}</h5>
                                        </Col>

                                        <Col xl={6} lg={6} md={6} sm={6} className='paddingB24'>
                                            <h5 className='fontM'> Do your teeth even touch in daytime?</h5>
                                            <h5>{ansArray[1]}</h5>
                                        </Col>

                                        <Col xl={6} lg={6} md={6} sm={6} className='paddingB24'>
                                            <h5 className='fontM'>Do you clench your teeth at night?</h5>
                                            <h5>{ansArray[2]}</h5>
                                        </Col>

                                        <Col xl={6} lg={6} md={6} sm={6} className='paddingB24'>
                                            <h5 className='fontM'>Are you more aware of your freeway space?</h5>
                                            <h5>{ansArray[3]}</h5>
                                        </Col>

                                    </Row>
                                </form>
                            </div> : "No Data Found"}
                        </div>
                    </Scrollbars>
                </div>
            </div>

        )
    }
}




export default QuestionBiweekly;

