/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  setLocalActiveClass,
  getStoredData,
  setStoredData,
} from "../../../utils/helper";
import "./progress.scss";
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from "../../../components/sidebar/sidemenu";
import HomeSideMenu from "../../../components/homesidebar/homesidebar";
import AdminSideMenu from "../../../components/adminsidebar/adminsidebar";
import HomeHeader from "../../../components/homeheader/homeheader";
import AdminHeader from "../../../components/adminheader/adminheader";
import Highchart from "../../../components/Highchart/Highchart";
import DropdownMenu from "../../../components/dropdown/dropdown";
import ProgressWeekly from "../../../components/progressweekly/progressweekly";
import WebService from "../../../utils/Webservice";
import Calender from "./Calender";
import { Row, Col } from "react-bootstrap";
import "./calender.css";
import threemmDeviceImg from "../../../assets/imageforcalender/3_mm_picture_for_legend.png";
import fivemmDeviceImg from "../../../assets/imageforcalender/5mm_for_legend.png";
import cameraImg from "../../../assets/imageforcalender/cameraIcon.png";
import { el } from "date-fns/locale";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
var _ = require("lodash");
var moment = require("moment");
var moment_timezone = require("moment-timezone");
const dataLength = 7;
const optionsProgress = ["Weekly", "Monthly", "2 Monthly"];
const machine = ["3 mm", "5 mm"];
Array.range = function (a, b, step) {
  var A = [];
  if (typeof a == "number") {
    A[0] = a;
    step = step || 1;
    while (a + step <= b) {
      A[A.length] = a += step;
    }
  } else {
    var s = "abcdefghijklmnopqrstuvwxyz";
    if (a === a.toUpperCase()) {
      b = b.toUpperCase();
      s = s.toUpperCase();
    }
    s = s.substring(s.indexOf(a), s.indexOf(b) + 1);
    A = s.split("");
  }
  return A;
};
class Homeprogress extends React.Component {
  constructor(props) {
    const default_timezone =
      localStorage.getItem("timezone") || "Europe/London";
    console.log("default_timezone:::____", default_timezone); // Output: "default_timezone!"
    super(props);

    console.log("token : ", this.props.match.params.token);
    this.state = {
      touchTestDates: [],
      showToast: true,
      default_timezone: default_timezone,
      setModalShow: false,
      modalShow: false,
      isWeeksTxt: "",
      isyearslected: "",
      isWeeks: "0",
      arrayResDate: [],
      weeks: [],
      months: [],
      monthly: [],
      iUserId:
        this.props.history.location.state === undefined &&
          this.props.match.params._id != undefined
          ? this.props.match.params._id
          : this.props.match?.params?.token
            ? this.props.match.params.token
            : this.props.history.location.state?.isViewFrom === "adminDashboard"
              ? getStoredData("userId")
              : getStoredData("_id"),
      isMachineType: 0,
      machineTxt: "",
      machineType: 0,
      isMachineTxt: "",
      allDates: [],
      weekStartDate: "",
      isViewFrom:
        this.props.history.location.state === undefined &&
          this.props.match.params._id === undefined
          ? "adminDashboard"
          : this.props.history.location.state?.isViewFrom,
      dataWeekWise: [],
      amData: [],
      pmData: [],
      graphDateArray: [],
      endIndex: 7,
      amGraphData: [],
      pmGraphData: [],
      monthwiseDates: [],
      isTouchTestDue: getStoredData("isTouchTestDue"),
      isQuestionnaireDue: getStoredData("isQuestionnaireDue"),
      //  defaultSelWeeks: ''
    };

    console.log("this.state.iUser", this.state.iUserId);
    this.props.history.location.state === undefined &&
      this.props.match.params._id === undefined
      ? setLocalActiveClass("Progress")
      : this.props.history.location.state?.isViewFrom === "home" ||
        this.props.history.location.state?.isViewFrom === "homeSidemenu"
        ? setLocalActiveClass("HomeProgress")
        : setLocalActiveClass("Progress");
    let touchTestGraphDates = [];
  }

  onGoClick() {
    this.props.history.push("/setreminder");
  }
  onReadMore3mmClick() { }

  onManualClick() {
    this.props.history.push("/questionbiweekly");
  }
  showToast() {
    this.setState({
      show: true,
    });
    setTimeout(() => {
      this.setState({
        show: false,
      });
    }, 3000);
  }
  setModalShow = (show) => {
    this.setState({
      modalShow: show,
    });
  };
  handleSelect(eventKey, event) {
    this.setState({ isWeeks: eventKey });
  }
  handleSelectyear(e) {
    this.setState({ isyearslected: e.target.text }, () =>
      this.onYearChangeGetMonth(this.state.isyearslected, this.state.value)
    );
  }
  onyearchangeGetTwoMonthaly(year) {
    let multiMonths = [];

    let yyyy = year;

    console.log("this.state.amData : ", this.state.amData);
    this.state.amData.map((item) => {
      if (String(new Date(item._id).getFullYear()) === String(yyyy)) {
        let monthString = moment()
          .month(new Date(item._id).getMonth())
          .format("MMM");
        // //console.log("monthString::::", monthString)
        multiMonths.push(this.getMultiMonths(monthString));
      }
    });

    if (multiMonths.length > 0) {
      const distinct = (value, index, self) => {
        return self.indexOf(value) === index;
      };
      multiMonths = multiMonths.filter(distinct);
    }
    this.setState(
      {
        selMultiMonth: multiMonths[multiMonths.length - 1],
        monthly: multiMonths,
      },
      () => this.onClickTwoMonthData(this.state.selMultiMonth)
    );
  }
  onYearChangeGetMonth(year, isweek) {
    if (Number(isweek) === 1) {
      let yyyy = year;
      let graphMonthIndex = [];
      this.state.amData.map((item) => {
        if (String(new Date(item._id).getFullYear()) === String(yyyy)) {
          let monthString = moment()
            .month(new Date(item._id).getMonth())
            .format("MMM");
          if (!graphMonthIndex.includes(monthString))
            graphMonthIndex.push(this.getMonths(monthString));
        }
      });
      this.setState(
        {
          selMonth: graphMonthIndex[graphMonthIndex.length - 1],
          months: graphMonthIndex,
        },
        () => this.onCickMonthData(graphMonthIndex[graphMonthIndex.length - 1])
      );
    } else {
      this.onyearchangeGetTwoMonthaly(this.state.isyearslected);
    }
  }
  onItemHandleEvent(e, option, eventKey) {
    this.setState(
      {
        isWeeksTxt: e.target.text,
        selOption: option,
        value: eventKey,
        isWeeks: eventKey,
      },
      () => {
        switch (eventKey) {
          case "0":
            let { weeks } = this.state;
            let lastWeek = weeks[weeks.length - 1];
            this.setState(
              {
                selectedWeek: lastWeek,
              },
              () => {
                this.filterWeeksData(lastWeek);
              }
            );
            break;
          case "1":
            this.onYearChangeGetMonth(this.state.isyearslected, 1);
            break;
          case "2":
            this.onyearchangeGetTwoMonthaly(this.state.isyearslected);
            break;
          case "3":
            let lastMonthy = this.state.months[this.state.months.length - 1];

            this.setState(
              {
                selectedMonth: lastMonthy,
              },
              () => {
                this.filterMonthsData(lastMonthy);
              }
            );
            break;
          default:
            break;
        }
      }
    );
  }
  handleSelectMachine(eventKey) {
    this.setState({
      isMachineType: eventKey,
      //  isWeeksTxt:"Weeks"
    });
  }
  onHandlEvent(e, option, eventKey) {
    this.setState(
      {
        isMachineTxt: e.target.text,
        selOption: option,
        value: eventKey,
        isMachineType: eventKey,
      },
      () => {
        this.progressDataWebServices();
      }
    );
  }
  handleMachineType(eventKey) {
    this.setState({ machineType: eventKey });
  }
  onHandleMachineType(e, option, eventKey) {
    this.setState({
      machineTxt: e.target.text,
      selOption: option,
      value: eventKey,
      machineType: eventKey,
    });
  }
  componentDidMount() {
    this.progressDataWebServices();
    this.getCalenderDates();
  }
  async progressDataWebServices() {
    try {
      let obj = {
        iUserId: this.state.iUserId,
        nDeviceType: this.state.isMachineType,
      };
      let responseProgressData = await WebService.getProgressData(
        obj,
        this.props.match?.params?.token
      );
      let responseTouchTestData = await WebService.getTouchTestData(
        obj,
        this.props.match?.params?.token
      );
      console.log("response Progress Data : ", responseProgressData.data);
      console.log("response Touch TestData : ", responseTouchTestData.data);
      // return false;
      if (responseTouchTestData.status) {

        responseTouchTestData.data.map((res) => {
          console.log("iteration of touchTest before:", res);

          // res.tz = res?.tz || this.state.default_timezone;
          res.dCreatedAt = moment(res.dCreatedAt)
            .tz( this.state.default_timezone)
            .format('YYYY-MM-DD');
          res._id = res.dCreatedAt;
          console.log("iteration of touchTest after:", res);
        });
      }

      console.log("response Touch Test Data After converted: ", responseTouchTestData.data)
      this.setState({ isLoading: false });

      console.log("default Tz : ", this.state.default_timezone);
      if (responseProgressData.status) {
        responseProgressData.data.AM.map((amRes) => {
          amRes.tz = this.state.default_timezone;

          amRes.dCreatedAt = moment(amRes.dCreatedAt)
            .tz(this.state.default_timezone)
            .format();
          amRes._id = amRes.dCreatedAt;
          console.log("am response :", amRes);
        });

        responseProgressData.data.PM.map((pmRes) => {
          pmRes.tz = this.state.default_timezone;

          pmRes.dCreatedAt = moment(pmRes.dCreatedAt)
            .tz(this.state.default_timezone)
            .format();
          pmRes._id = pmRes.dCreatedAt;
          console.log("pmRes : ", pmRes.tz, pmRes);
        });

        /**
         * @comment Successfully convertetd date its return correct date
         * @TODO
         */
        console.log("after time converted : ", responseProgressData.data)
        this.handleSuccessResponse(responseProgressData.data, responseTouchTestData.data);
      } else {
        this.setState({
          graphDate: [],
          amGraphData: [],
          pmGraphData: [],
          graphTouchTestDate: [],
          graphTouchTestData: [],
          isDropdownDisabled: false,
        });
      }
    } catch (error) {
      console.log("catch :responseProgressData::---", error);
    }
  }

  async handleSuccessResponse(response, responseTouchTest) {
    console.log("Graph and touchtest DATA :", { graphData: response, touchTest: responseTouchTest });

    /**
     * @comment we getting correct data from here in response attributes
     */
    let amData = response.AM;
    let pmData = response.PM;
    let touchTestData = responseTouchTest;
    let responseDate = [];
    let weeks = [];
    if (amData.length <= 0) {
      return;
    }
    let firstDate = null;
    let lastDate = null;
    const count = amData.length;

    console.log("amData after handle success before _id formated * ", amData);

    amData.map((item, index) => {
      console.log("armRes *******", item.dCreatedAt);
      item._id = item.dCreatedAt.split("T")[0];
      /**
       * @comment converted date with spefic timezone
       */
      if (index === 0) {
        firstDate = item.dCreatedAt.split("T")[0];
        console.log("firstDate **", firstDate);
      } else if (index === count - 1) {

        lastDate = item.dCreatedAt.split("T")[0];
      }
      if (count === 1) {
        lastDate = item.dCreatedAt.split("T")[0];
      }
    });
    console.log("amData after handle success after _id formated : ", amData);
    if (firstDate && lastDate) {
      weeks = this.getWeeks(firstDate, lastDate);
    }
    console.log("weeks : ", weeks);

    this.setState({ weeks });
    amData.map((index) => {
      responseDate.push(index._id);
    });

    let graphDateArray = [];
    amData.map((obj) => {
      // console.log("AM Obj:", amData)
      obj["y"] = obj["total"];
      obj["photos"].length === 0
        ? (obj["isPhotos"] = false)
        : (obj["isPhotos"] = true);
    });
    console.log("pmData after handle success before converted : ", pmData);
    // return 0;
    pmData.map((obj) => {
      console.log("YYYY-MM-DD : ", obj._id);
      obj._id = obj._id.split("T")[0];

      obj["y"] = obj["total"];
      obj["photos"].length === 0
        ? (obj["isPhotos"] = false)
        : (obj["isPhotos"] = true);
    });
    console.log("pmData after handle success after converted : ", pmData);
    // return 0;

    touchTestData.map((obj) => {
      obj["y"] = obj["total"];
      obj["photos"].length === 0
        ? (obj["isPhotos"] = false)
        : (obj["isPhotos"] = true);
    });
    console.log("touchTestData after iteration : ", touchTestData);

    weeks = this.state.weeks;
    let lastWeek = weeks[weeks.length - 1];
    console.log("amData :  after converted", amData);
    console.log("pmData :  after converted", pmData);

    this.setState(
      {
        selectedWeek: lastWeek,
        amData: amData,
        pmData: pmData,
        touchTestData: touchTestData,
      },
      () => {
        this.filterWeeksData(lastWeek);
      }
    );
  }

  onClickTwoMonthData(item) {
    this.setState(
      {
        selectedMultiMonth: item,
      },
      () => {
        this.filterMultiMonthsData(this.state.selectedMultiMonth);
      }
    );
  }
  compareMultiMonths = (data, month) => {
    if (data === undefined) {
      return;
    }
    if (data.length <= 0) {
      return;
    }
    let months = month.split("-");
    return data.filter((item) => {
      let monthString = moment(item["_id"]).format("MMM-YYYY");
      return (
        monthString === `${months[0]}-${this.state.isyearslected}` ||
        monthString === `${months[1]}-${this.state.isyearslected}`
      );
    });
  };

  /**
   * @comment 2 month data 
   */
  filterMultiMonthsData = (filter) => {
    let AM = this.compareMultiMonths(this.state.amData, filter);
    let PM = this.compareMultiMonths(this.state.pmData, filter);
    console.log("filterMultiMonthsData", { AM, PM });

    let touchTestData = this.compareMultiMonths(
      this.state.touchTestData,
      filter
    );
    if (AM === undefined) {
      return;
    }
    if (AM.length <= 0) {
      return;
    }
    let arrayDate = [];
    AM.map((amItem, index) => {
      console.log("2 month amItem :", amItem);
      if (index === 0) {
        const date = new Date(amItem._id);
        date.setDate(date.getDate() - 1);
        const previousDate = date.toISOString().split("T")[0];
        console.log("fiest day : ", previousDate);
        arrayDate.push(previousDate);
      }
      arrayDate.push(amItem._id);
    });
    // added for time tome

    // arrayDate Modify ********************
    arrayDate = [...new Set(arrayDate)];
    console.log("arrayDate**********for graph", arrayDate);

    // AM Data modify
    const resultMapAM = {};
    AM.forEach((item) => {
      if (!resultMapAM[item._id]) {
        resultMapAM[item._id] = { ...item };
      } else {
        resultMapAM[item._id].total += item.total;
        resultMapAM[item._id].y += item.total;
      }
    });
    AM = Object.values(resultMapAM);

    // PM Data modify
    const resultMapPM = {};
    PM.forEach((item) => {
      if (!resultMapPM[item._id]) {
        resultMapPM[item._id] = { ...item };
      } else {

        let existingTotal = parseFloat(resultMapPM[item._id].total) || 0;
        let newItemTotal = parseFloat(item.total) || 0;
        let existingY = parseFloat(resultMapPM[item._id].y) || 0;
        resultMapPM[item._id].total = (existingTotal + newItemTotal).toFixed(2);
        resultMapPM[item._id].y = (existingY + newItemTotal).toFixed(2);

        // resultMapPM[item._id].total += item.total;
        // resultMapPM[item._id].y += item.total;
      }
    });
    PM = Object.values(resultMapPM);

    // Touchtest Modify
    const touchtesObj = {};
    console.log("here");
    touchTestData.forEach((item) => {
      console.log("item **", item);
      if (!touchtesObj[item._id]) {
        touchtesObj[item._id] = { ...item };
      } else {
        touchtesObj[item._id].total += item.total;
        touchtesObj[item._id].y += item.total;
      }
    });
    touchTestData = Object.values(touchtesObj);
    let getTouchArrayDate = []
    touchTestData.map((amItem, index) => {
      console.log("touchTest, : ", amItem);
      const date = new Date(amItem._id);
      date.setDate(date.getDate());
      const previousDate = date.toISOString().split("T")[0];
      console.log("fiest day : ", previousDate);
      getTouchArrayDate.push(previousDate);
    });
    console.log("touchTest  2month :: ", touchTestData);
    console.log("get TouchArray Date 2 mont :", getTouchArrayDate);

    this.setState({
      selMultiMonth: filter,
      graphDate: arrayDate,
      amGraphData: AM,
      pmGraphData: PM,
      graphTouchTestDate: getTouchArrayDate, // @TODO
      graphTouchTestData: touchTestData,
    });
  };

  onWeeksClick(item) {
    this.setState(
      {
        selectedWeek: item,
      },
      () => {
        this.filterWeeksData(item);
      }
    );
  }

  compareWeeks = (data, sDate) => {
    console.log("compare weeks data with filter :", { data, sDate })
    var startDate = moment(sDate);
    startDate = startDate.startOf("week").toString();
    console.log("start Date of Weeks : ", startDate);
    if (data === undefined) {
      return;
    }
    if (data.length <= 0) {
      return;
    }
    return data.filter((item) => {
      let dateString = moment(item["_id"]).startOf("week").toString();
      console.log("dateString : ", { count: item?.y, dateString, startDate, "isSameDate": dateString === startDate });
      return dateString === startDate;
    });
  };

  /**
   * 
   * @comment weeks data filter 
   */
  filterWeeksData = (filter) => {
    console.log("weeks filter data : ", { amData: this.state.amData, pmData: this.state.pmData, touchTest: this.state.touchTestData });
    let AM = this.compareWeeks(this.state.amData, filter);
    let PM = this.compareWeeks(this.state.pmData, filter);
    let touchTest = this.compareWeeks(this.state.touchTestData, filter);

    // @TODO send directlly dates without filter need to cross varify all scenario
    // let AM = this.state.amData;
    // let PM = this.state.pmData;


    console.log("After compare weeks data : ", { AM, PM, touchTest });
    console.log("touchTest **", touchTest);
    let arrayDate = [];
    let getTouchArrayDate = [];
    if (AM === undefined) {
      return;
    }
    if (PM === undefined) {
      return;
    }
    console.log("AM::::length", AM);
    console.log("PM::::length", PM);
    AM.map((amItem, index) => {
      console.log("weeks filter data for graph date : ", amItem);
      if (index === 0) {
        const date = new Date(amItem._id);
        date.setDate(date.getDate() - 1);
        const previousDate = date.toISOString().split("T")[0];
        console.log("fiest day : ", previousDate);
        arrayDate.push(previousDate);
      }
      arrayDate.push(amItem._id);
    });

    console.log("arrayDate of graph Dates :", arrayDate);
    console.log("touchTest data : ", touchTest)
    touchTest.map((amItem, index) => {
      console.log("touchTest, : ", amItem);
      const date = new Date(amItem._id);
      date.setDate(date.getDate());
      const previousDate = date.toISOString().split("T")[0];
      console.log("fiest day : ", previousDate);
      getTouchArrayDate.push(previousDate);
    });

    // this.state.touchTestDates(getTouchArrayDate);
    // console.log("Touch Array Date : ", this.state.touchTestDates);

    if (AM.length <= 0) {
      return;
    }

    console.log("Data Before set to the state :", {
      clickIndex: filter,
      amGraphData: AM,
      pmGraphData: PM,
      graphDate: arrayDate,
      graphTouchTestData: touchTest,
      graphTouchTestDate: getTouchArrayDate,
    });

    this.setState({
      clickIndex: filter,
      amGraphData: AM,
      pmGraphData: PM,
      graphDate: arrayDate,
      graphTouchTestData: touchTest,
      graphTouchTestDate: getTouchArrayDate,
    });
  };

  getWeeks(firstDate, lastDate) {
    this.setState({
      yearlyoption: Array.range(
        Number(firstDate.split("-")[0]),
        Number(lastDate.split("-")[0]),
        1
      ).reverse(),
      isyearslected: Number(lastDate.split("-")[0]),
    });
    var firstDate = new Date(firstDate);
    var first = firstDate.getDate() - firstDate.getDay() + 1;
    var firstday = new Date(firstDate.setDate(first)).toUTCString();

    var lastDate = new Date(lastDate);
    var last = lastDate.getDate() - lastDate.getDay() + 7;
    var lastday = new Date(lastDate.setDate(last)).toUTCString();

    let date1 = new Date(firstday);
    let date2 = new Date(lastday);

    let weekStartDate = [firstday];

    for (let i = 0; i < this.numberfWeeks(date1, date2) - 1; i++) {
      let initStartDate = firstDate.setTime(
        firstDate.getTime() + 7 * 24 * 60 * 60 * 1000
      );
      let startDate = new Date(initStartDate).toUTCString();
      weekStartDate.push(startDate);
    }
    return weekStartDate;
  }

  getDates(startDate, endDate) {
    var dates = [];
    startDate = startDate.add(1, "days");
    while (startDate.format("YYYY-MM-DD") !== endDate.format("YYYY-MM-DD")) {
      dates.push(moment(startDate.toDate()).format("YYYY-MM-DD"));
      startDate = startDate.add(1, "days");
    }
    return dates;
  }
  numberfWeeks = (dt2, dt1) => {
    // return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7;
    return Math.abs(Math.round(diff));
  };
  compareMonths = (data, month) => {
    let mm = month + "-" + this.state.isyearslected;
    if (data.length <= 0) {
      return;
    }
    return data.filter((item) => {
      let monthString = moment(item["_id"]).format("MMM-YYYY");
      return monthString === mm;
    });
  };

  filterMonthsData = (filter) => {
    let AM = this.compareMonths(this.state.amData, filter);
    let PM = this.compareMonths(this.state.pmData, filter);
    let arrayDate = [];
    let touchTestArrayDate = [];
    if (AM === undefined) {
      return;
    }
    AM.map((amItem, index) => {
      console.log("ammLdate:: month : ", amItem._id);
      // arrayDate.push(moment_timezone.utc(amItem._id).tz(amItem.tz || this.state.default_timezone).format("YYYY-MM-DD"));
      // arrayDate.push(moment(new Date(amItem["_id"])).format("MMM DD,YYYY"));
      // arrayDate.push(amItem._id);
      if (index === 0) {
        const date = new Date(amItem._id);
        date.setDate(date.getDate() - 1);
        const previousDate = date.toISOString().split("T")[0];
        console.log("fiest day : ", previousDate);
        arrayDate.push(previousDate);
      }
      arrayDate.push(amItem._id);
    });
    if (AM.length <= 0) {
      return;
    }
    // added for time tome

    // arrayDate Modify ********************
    arrayDate = [...new Set(arrayDate)];
    console.log("arrayDate**********for graph", arrayDate);

    // AM Data modify
    const resultMapAM = {};
    AM.forEach((item) => {
      if (!resultMapAM[item._id]) {
        resultMapAM[item._id] = { ...item };
      } else {
        resultMapAM[item._id].total += item.total;
        resultMapAM[item._id].y += item.total;
      }
    });
    AM = Object.values(resultMapAM);

    // PM Data modify
    const resultMapPM = {};
    PM.forEach((item) => {
      if (!resultMapPM[item._id]) {
        resultMapPM[item._id] = { ...item };
      } else {

        // Convert the existing total and y to numbers before adding
        let existingTotal = parseFloat(resultMapPM[item._id].total) || 0;
        let newItemTotal = parseFloat(item.total) || 0;
        let existingY = parseFloat(resultMapPM[item._id].y) || 0;

        // Perform addition and update the object
        resultMapPM[item._id].total = (existingTotal + newItemTotal).toFixed(2);
        resultMapPM[item._id].y = (existingY + newItemTotal).toFixed(2);

        // resultMapPM[item._id].total += item.total;
        // resultMapPM[item._id].y += item.total;
      }
    });
    PM = Object.values(resultMapPM);

    //console.log("arrayDate**** 2", arrayDate)
    let touchTest = this.compareMonths(this.state.touchTestData, filter);
    // added for time tome
    console.log("touchTest **", touchTest);
    // Touchtest Modify
    const touchtesObj = {};
    touchTest.forEach((item) => {
      console.log("item **", item);
      if (!touchtesObj[item._id]) {
        touchtesObj[item._id] = { ...item };
      } else {
        touchtesObj[item._id].total += item.total;
        touchtesObj[item._id].y += item.total;
      }
    });
    touchTest = Object.values(touchtesObj);
    let getTouchArrayDate = []
    touchTest.map((amItem, index) => {
      console.log("touchTest, : ", amItem);
      const date = new Date(amItem._id);
      date.setDate(date.getDate());
      const previousDate = date.toISOString().split("T")[0];
      console.log("fiest day : ", previousDate);
      getTouchArrayDate.push(previousDate);
    });
    console.log("touchTest **", touchTest);
    console.log("get TouchArray Date **", getTouchArrayDate);
    this.setState({
      selMonth: filter,
      amGraphData: AM,
      pmGraphData: PM,
      graphDate: arrayDate,
      graphTouchTestData: touchTest,
      graphTouchTestDate: getTouchArrayDate,
    });
  };
  onCickMonthData(i) {
    this.setState(
      {
        selectedMonth: i,
      },
      () => {
        this.filterMonthsData(i);
      }
    );
  }

  getMultiMonths = (monthString) => {
    switch (monthString.toLowerCase()) {
      case "jan":
      case "feb": {
        return "Jan-Feb";
      }
      case "mar":
      case "apr": {
        return "Mar-Apr";
      }
      case "may":
      case "jun": {
        return "May-Jun";
      }
      case "jul":
      case "aug": {
        return "Jul-Aug";
      }
      case "sep":
      case "oct": {
        return "Sep-Oct";
      }
      case "nov":
      case "dec": {
        return "Nov-Dec";
      }
      default: {
        return "Jan";
      }
    }
  };

  getMonths = (monthString) => {
    switch (monthString.toLowerCase()) {
      case "jan":
        return "Jan";

      case "feb":
        return "Feb";
      case "mar":
        return "Mar";

      case "apr":
        return "Apr";
      case "may":
        return "May";

      case "jun":
        return "Jun";
      case "jul": {
        return "Jul";
      }
      case "aug":
        return "Aug";
      case "sep": {
        return "Sep";
      }
      case "oct":
        return "Oct";

      case "nov":
        return "Nov";
      case "dec":
        return "Dec";
      default: {
        return "Jan";
      }
    }
  };

  // vertsion 3.0 ---------------------------------
  async getCalenderDates() {
    // try {
    //   console.log("try start:)")
    //   let date = new Date();
    //   let m = date.getMonth() + 1;
    //   let month = m.toString().length >= 2 ? m : '0' + m
    //   let e = 32 - new Date(month, date.getFullYear(), 32).getDate();
    //   let startDate = `${date.getFullYear()}-${month}-01T00:00:00.000Z`
    //   let endDate = `${date.getFullYear()}-${month}-${e}T00:00:00.000Z`
    //   let reqObj = { startDate, endDate, iUserId: this.state.iUserId };
    //   let response = await WebService.sessionData(reqObj);
    //   this.setState({ isLoading: false });
    //   console.log("response:-----------------)", response)
    //   if (response.status) {
    //     console.log("try if response:)")
    //   }
    //   else {
    //     console.log("try else data not found:)")
    //   }
    // }
    // catch (err) {
    //   console.log("Error:::catch:)", err)
    // }
  }
  // end version 3.0 ----------------------------------

  /**
   * set timezone which is  getting from the DB if the timezone is not got then set the default time zone which is saved with the user profile
   */
  convertedDateWithTimezone(tz, date) {
    const convertedTime = moment_timezone.utc(date).tz(tz);
    console.log("convertedTime ****", convertedTime);
    // console.log("Session Timezone::--", tz, ",Session Date :- ", date, ",Converted Date", convertedTime.format());
    return convertedTime.format();
  }
  render() {
    const {
      modalShow,
      setModalShow,
      isWeeksTxt,
      machineTxt,
      isMachineTxt,
      weeks,
      isViewFrom,
      isQuestionnaireDue,
      isTouchTestDue,
      selMonth,
      defaultIndexMonth,
      selMultiMonth,
    } = this.state;
    let isWeeksTitle =
      this.state.isWeeksTxt === "" || undefined ? "Weeks" : isWeeksTxt;
    let machineTitle =
      this.state.isMachineTxt === "" || undefined ? "3 mm" : isMachineTxt;
    return (
      <div className="main-HomeProgress">
        {this.props.match.params?.token ? (
          console.log("yess")
        ) : (
          <>
            {isViewFrom === "adminDashboard" && (
              <AdminHeader {...this.props}></AdminHeader>
            )}
            {this.state.isViewFrom === "home" ? (
              <HomeSideMenu {...this.props} showMorePopup={true}></HomeSideMenu>
            ) : this.state.isViewFrom === "adminProgress" ||
              this.state.isViewFrom === "adminDashboard" ? (
              <AdminSideMenu {...this.props}></AdminSideMenu>
            ) : (
              <HomeSideMenu {...this.props}></HomeSideMenu>
            )}
            {isViewFrom !== "adminDashboard" && (
              <HomeHeader {...this.props}></HomeHeader>
            )}
          </>
        )}
        <div className="inner-HomeProgress">
          <Scrollbars className="home-Scroll">
            <div className="main-Container">
              {/* start tabs  */}

              <Tabs
                defaultActiveKey="graph"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                {/* garph view ------------------------------- */}
                <Tab eventKey="graph" title="Graph">
                  <div className="dropdown-Section">
                    <div
                      className={
                        this.state.infoView ? "divclass show " : "divclass"
                      }
                      onClick={() =>
                        this.setState({ infoView: !this.state.infoView })
                      }
                    ></div>
                    <div
                      className={
                        this.state.infoView ? "tool-Tips show" : "tool-Tips"
                      }
                    >
                      <a
                        href="void:0()"
                        class="tip icon-info cursor"
                        onClick={() =>
                          this.setState({ infoView: !this.state.infoView })
                        }
                      >
                        <span className="info-detail">
                          Device Wear and Touchtest graphs can be use to keep
                          track of your progress. Device wear tells you exactly
                          how much you have worn the appliance. The touch graph
                          is simply to show you your muscles are relaxing and
                          that you are getting less touches over time. The main
                          purpose of the touch test is to set up a trigger in
                          the brain.
                          <br />
                          <br />
                          Data on both graphs can be viewed on weekly, monthly
                          and 2 monthly timeline basis.
                          <br />
                          <br />
                          Photographs uploaded on a particular day will be
                          represented by a "Camera" icon, similarly overuse
                          reported will be represented by an "Exclamation" icon.{" "}
                        </span>
                      </a>
                    </div>

                    {this.state.isDropdownDisabled ? (
                      <DropdownMenu
                        title={isWeeksTitle}
                        onSelect={this.handleSelect.bind(this)}
                        onItemSelect={this.onItemHandleEvent.bind(this)}
                        options={optionsProgress}
                      ></DropdownMenu>
                    ) : (
                      <DropdownMenu
                        title={isWeeksTitle}
                        onSelect={this.handleSelect.bind(this)}
                        onItemSelect={this.onItemHandleEvent.bind(this)}
                        options={optionsProgress}
                      ></DropdownMenu>
                    )}

                    {(this.state.isWeeks === "1" ||
                      this.state.isWeeks === "2") && (
                        // <div className="dropdown-Section">
                        <DropdownMenu
                          title={
                            this.state.isyearslected === "" || undefined
                              ? "Yearly"
                              : this.state.isyearslected
                          }
                          // onSelect={this.handleSelectyear.bind(this)}
                          onItemSelect={this.handleSelectyear.bind(this)}
                          options={this.state.yearlyoption}
                        ></DropdownMenu>
                        // </div>
                      )}
                  </div>
                  <div className="inline">
                    <div className="weekly-Data home-Scroll">
                      <ProgressWeekly
                        selMonth={selMonth}
                        selMultiMonth={selMultiMonth}
                        defaultIndexMonth={defaultIndexMonth}
                        onClickTwoMonthData={this.onClickTwoMonthData.bind(
                          this
                        )}
                        clickIndex={this.state.clickIndex}
                        onCickMonthData={this.onCickMonthData.bind(this)}
                        onWeeksData={this.onWeeksClick.bind(this)}
                        title={this.state.isWeeks == 0 ? "Weeks" : "Months"}
                        selectedTime={this.state.isWeeks}
                        weeeks={this.state.weeks}
                        data={
                          this.state.isWeeks == 0
                            ? this.state.weeks
                            : this.state.isWeeks === "1" ||
                              this.state.isWeeks === "3"
                              ? this.state.months
                              : this.state.monthly
                        }
                      ></ProgressWeekly>
                    </div>
                  </div>
                  <div className="main-HighChart-Section">
                    <div className="width50">
                      <h5 className="title">Device Wear Graph</h5>
                      <div className="dropdown-Section">
                        <div className="ampmSection">
                          <a
                            href="void:0()"
                            className="choices active"
                            onClick={() => this.onReadMore3mmClick()}
                          >
                            <span className="list-icon-square-purple"></span> PM
                          </a>
                          <a
                            href="void:0()"
                            className="choices mLeft10"
                            onClick={() => this.onReadMore3mmClick()}
                          >
                            <span className="list-icon-square-lp"></span> AM
                          </a>
                        </div>
                        <div className="iconupsec">
                          <label className="label">
                            <span className="icon-camera icon-css"></span>
                            Photos Uploaded
                          </label>

                          <label className="label mLeft20">
                            <span className="icon-warning icon-css"></span>
                            Overuse Reported
                          </label>
                        </div>
                        <DropdownMenu
                          title={machineTitle}
                          onSelect={this.handleSelectMachine.bind(this)}
                          onItemSelect={this.onHandlEvent.bind(this)}
                          options={machine}
                        ></DropdownMenu>
                      </div>
                      <div className="highChart-Section">
                        {this.state.touchTestData === undefined && (
                          <p className="noData">No Data</p>
                        )}
                        {/* {typeof this.state.pmGraphData} */}
                        <Highchart
                          graphDate={this.state.graphDate}
                          amData={this.state.amGraphData}
                          pmData={this.state.pmGraphData}
                        ></Highchart>
                      </div>
                      <div className="iconsec">
                        <label className="label">
                          <span className="icon-camera icon-css"></span>
                          Photos Uploaded
                        </label>

                        <label className="label mLeft20">
                          <span className="icon-warning icon-css"></span>
                          Overuse Reported
                        </label>
                      </div>
                      {this.state.isQuestionnaireDue === "true" && (
                        <label className="biweeklyAlert label">
                          <span className="icon-warning icon-css"></span>
                          You havn't answered your biweekly questionnaire since
                          last 1 month.
                        </label>
                      )}
                    </div>
                    <div className="width50">
                      <h5 className="title">Touch Test Graph</h5>
                      <div className="dropdown-Section right">
                        <DropdownMenu
                          title={machineTitle}
                          onSelect={this.handleSelectMachine.bind(this)}
                          onItemSelect={this.onHandlEvent.bind(this)}
                          options={machine}
                        ></DropdownMenu>
                      </div>
                      <div className="highChart-Section">
                        {this.state.touchTestData === undefined && (
                          <p className="noData">No Data</p>
                        )}

                        <Highchart
                          graphTouchTestDate={this.state.graphTouchTestDate}
                          graphTouchTestData={this.state.graphTouchTestData}
                        ></Highchart>
                      </div>
                      {this.state.isTouchTestDue === "true" && (
                        <label className="biweeklyAlert label">
                          <span className="icon-warning icon-css"></span>
                          No touch test has been performed since last 2 weeks.
                        </label>
                      )}
                    </div>
                  </div>
                </Tab>
                {/* calenmder view ********************************************** */}
                <Tab eventKey="calender" title="Calendar">
                  <Calender
                    token={this.props.match?.params?.token}
                    iUserId={this.state.iUserId}
                  />
                </Tab>
              </Tabs>
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default Homeprogress;
