import React from 'react';
import './homeheader.scss';
import { getStoredData } from "../../utils/helper";
import { Badge } from 'react-bootstrap';
import Notification from '../notification/notification';
import user_profile from "../../assets/images/profile.png"
class HomeHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            notificationModal: false,
            sUserFirstName: getStoredData("sUserFirstName"),
            sUserLastName: getStoredData("sUserLastName"),
            sProfilePhoto: getStoredData("sProfilePhoto")

    
        }
    }


    toggleClass(element, className) {
        if (!element || !className) {
            return;
        }
        var classString = element.className, nameIndex = classString.indexOf(className);
        if (nameIndex == -1) {
            classString += ' ' + className;
        }
        else {
            classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
        }
        element.className = classString;
    }
    onNotificationClick() {
        let { hideParentModels } = this.props
        this.setState({
            notificationModal: true
        })
    }
    onNotificationClose() {
        this.setState({
            notificationModal: false
        })
    }

    render() {
        let { notificationModal, sUserFirstName, sUserLastName } = this.state
 console.log("this.state?.sProfilePhoto",this.state?.sProfilePhoto)

// need to check the type of image i.e string 
 var imagePath = this.state?.sProfilePhoto== "undefined" || this.state?.sProfilePhoto== "null"  ? user_profile : this.state?.sProfilePhoto;
 console.log("imagePath",imagePath);
 console.log("user_profile",user_profile);
 console.log(" this.state?.sProfilePhoto",typeof this.state?.sProfilePhoto);


        return (
            <div className='headerhome'>
                <span onClick={() => this.toggleClass(document.body, 'toggleOn')} className='icon-menu'>
                </span>
                <div className='main-header'>
                    <div className='profile-section'>
                        <div className="profile-Img">
            

                        {/* <img src={ user_profile }></img>                           */}
                        {/* <img src={this.state?.sProfilePhoto ? this.state?.sProfilePhoto :  user_profile }></img>                           */}
                          <img src={imagePath}></img>
                        </div>
                        <span className='userName'> {sUserFirstName} {sUserLastName}</span>
                    </div>
                </div>
                <Notification className={notificationModal ? "notification show" : "notification "} onClose={() => this.onNotificationClose()} />
            </div>
        )
    }
}




export default HomeHeader;

