import React, { useState } from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import './freewayspaceguide.scss';
import { Scrollbars } from "react-custom-scrollbars";
import HomeSideMenu from '../../../components/homesidebar/homesidebar'
import HomeHeader from '../../../components/homeheader/homeheader';

class FreeWaySpaceGuide extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
        setLocalActiveClass("HomeInstructions")
    }


    render() {
        const { modalShow, setModalShow } = this.state
        return (
            <div className='main-FreeWaySpace'>
                <HomeSideMenu {...this.props}></HomeSideMenu>
                <HomeHeader></HomeHeader>
                <div className='inner-FreeWaySpace'>
                    <Scrollbars className='home-Scroll'>
                        <div className='main-Container'>
                            <div>Read More Freeway Space</div>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        )
    }
}




export default FreeWaySpaceGuide;

