import React from 'react';
import { setLocalActiveClass, setFilledFlags } from "../../../utils/helper";
import './questionnairestep2.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu'
import HomeSideMenu from '../../../components/homesidebar/homesidebar'
import { Row, Col } from 'react-bootstrap';
import Header from '../../../components/header/header';
import Button from '../../../components/Button/button';
import QuestionImg from '../../../assets/images/questionNew.svg';
import "react-datepicker/dist/react-datepicker.css";
import CheckBox from '../../../components/checkbox/checkbox';
import { getFormDataObject, getCookie } from '../../../utils/helper';
import WebService from "../../../utils/Webservice"
import CONSTANT from "../../../utils/constants";
import ModalStep from '../../../components/modalStep/modalStep';

let wishlistArray = [
    {
        isChecked: false,
        title: "General improvement to the face"
    }, {
        isChecked: false,
        title: "Improvement in thread viens"

    }, {
        isChecked: false,
        title: "Reduction of wrinkles on forehead"
    }, {
        isChecked: false,
        title: "Reduction in nasio labial folds"
    }, {
        isChecked: false,
        title: "Reduction of wrinkles around the eyes"
    }, {
        isChecked: false,
        title: "Reduced pores and improved texture of the skin"
    }, {
        isChecked: false,
        title: "Reduced bags under the eyes"
    },
    {
        isChecked: false,
        title: "Fuller lips"
    }, {
        isChecked: false,
        title: "Would like cheeks to be fuller"
    }, {
        isChecked: false,
        title: "Reduced wrinkles around the mouth"
    }
    , {
        isChecked: false,
        title: "Reduced jowls"
    }]

class QuestionnaireStep2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: new Date(),
            setModalShow: false,
            modalShow: false,
            comment: '',
            isChecked: false,
            optionsChecked: [], isLoading: false, disableBtn: false,
            wishlistArray: JSON.parse(JSON.stringify(wishlistArray)),
            _id: localStorage.getItem("_id"),
            isViewFrom: this.props.history.location.state.isViewFrom,
            isPage: this.props.history.location.state.isPage
        }
        this.props.history.location.state.isViewFrom === 'homeViewQuestionnaire' ? setLocalActiveClass("ViewQuestionnaire")
            : setLocalActiveClass("Questionnaire")
        this.handleChange = this.handleChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this)
        console.log(this.state.isPage, "ispage");

    }

    onGoClick() {
        this.setState({ modalShow: true })
        this.whatYouWantWebService()

        // this.props.history.push('/addphotoguide')
    }
    onKeyDown(e) {
        e.keyCode == 13 && this.whatYouWantWebService()
        //  await this.props.history.push('/questionnairestep2')
    }
    componentDidMount() {
        this.getWishList()
    }

    //************************GET_USER_Info API Call****************************************
    async getWishList() {
        this.setState({ isLoading: true });
        try {
            let obj = {
                _id: this.state._id,
            };
            let response = await WebService.getUserAgreement(obj);
            this.setState({ isLoading: false });

            if (response.status) {
                console.log(JSON.stringify(response));
                await this.handleSuccessResponse(response);
            } else {
                console.log("response" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }
    handleSuccessResponse(response) {
        let { wishlistArray } = this.state

        let { aWishlist, sChangeForMe } = response.data

        if (aWishlist.length > 0) {
            for (let i = 0; i < wishlistArray.length; i++) {
                const element = wishlistArray[i].title;
                let array = aWishlist.includes(element)
                wishlistArray[i]['isChecked'] = array
            }
            //  this.setState({ disableBtn: true })
        }

        aWishlist.length === 0 ? this.setState({ disableBtn: true }) : this.setState({ disableBtn: false })

        this.setState({ wishlistArray, comment: sChangeForMe })
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });
    }
    endDateChange(date) {
        this.setState({
            endDate: date
        });
    }
    handleCheckChange(event, index) {
        let { wishlistArray } = this.state
        wishlistArray[index].isChecked = event.target.checked
        event.target.checked === true ? this.setState({ disableBtn: false }) : (wishlistArray.filter(props => props.isChecked).length === 0) ? this.setState({ disableBtn: true }) : this.setState({ disableBtn: false })
        this.setState({ wishlistArray })
    }
    setModalShow = (show, e) => {

        this.setState({ modalShow: !show })
        if (this.state.isViewFrom === "homeViewQuestionnaire" || this.state.isViewFrom === "homeSetPreferences") {
            this.props.history.push('/ViewQuestionnaire', { isViewFrom: "homeViewQuestionnaire" })
        } else {
            // console.log(this.state.isPage, 'this.state.isViewFrom');

            { this.state.isPage === 'editpreference' ? this.props.history.push('/savepreferences', { isViewFrom: this.state.isViewFrom }) : this.props.history.push('/addphotoguide', { isViewFrom: this.state.isViewFrom }) }
            //this.props.history.push('/addphotoguide')

        }
        // this.props.history.push('/setreminder')
    }

    //************************ WHAT YOU WANT API Call****************************************
    async whatYouWantWebService() {

        let { wishlistArray } = this.state
        for (let i = 0; i < wishlistArray.length; i++) {
            const element = wishlistArray[i];

            if (element.isChecked) {
                this.state.optionsChecked.push(element.title)
            }
        }

        try {
            this.setState({ isLoading: true });
            let obj = {
                _id: this.state._id,
                aWishlist: this.state.optionsChecked,
                sChangeForMe: this.state.comment
            };
            console.log("=>" + JSON.stringify(obj))
            let response = await WebService.whatYouWantUser(obj);
            this.setState({ isLoading: false });

            if (response.status) {
                // this.setState({ modalShow: false })
                console.log("response" + response.status);
                setFilledFlags(CONSTANT.KEYS.KeyQuestionaryFlag, true)

                // if (this.state.isViewFrom === "homeViewQuestionnaire" || this.state.isViewFrom === "homeSetPreferences") {
                //     this.props.history.push('/ViewQuestionnaire', { isViewFrom: "homeViewQuestionnaire" })
                // } else {
                //     // console.log(this.state.isPage, 'this.state.isViewFrom');

                //     { this.state.isPage === 'editpreference' ? this.props.history.push('/savepreferences', { isViewFrom: this.state.isViewFrom }) : this.props.history.push('/addphotoguide', { isViewFrom: this.state.isViewFrom }) }
                //     //this.props.history.push('/addphotoguide')

                // }
            } else {
                console.log("response" + response.status);
                this.handleErrorResponse(response);
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        let { wishlistArray, modalShow, setModalShow, } = this.state
        return (
            <div className='main-QuestionPageStep2'>
                <Header></Header>

                {this.state.isViewFrom === "homeViewQuestionnaire" || this.state.isViewFrom === "homeSetPreferences" ? <HomeSideMenu {...this.props} showMorePopup={true}></HomeSideMenu> : <SideMenu {...this.props}></SideMenu>}

                {this.state.isLoading != true ?

                    <div className='questionPage'>
                        <Scrollbars className='question-Scroll'>
                            <div className='main-Container'>
                                <div className='img-Container'>
                                    <img src={QuestionImg} className="logo" alt="logo" />
                                </div>
                                <h2 className='paddingB15'>Questionnaire - What do you want to get from using Oralift</h2>
                                <div className='content-Section'>
                                    <h5 className='paddingB15'>If you had a “Wish List” of what you would like Oralift to do for you, what would be on your list and what would be on the top of your list?</h5>
                                </div>
                                <div className='checkbox-Section'>
                                    <Row>
                                        {
                                            wishlistArray.map((item, i) => (<Col key={i} lg={6} sm={6} sm={12} >
                                                <CheckBox value={item.title} title={item.title} isChecked={item.isChecked} onChange={(e) => this.handleCheckChange(e, i)} Id={i}></CheckBox>
                                            </Col>
                                            ))
                                        }
                                    </Row>
                                    <label className='cust-Label'>Additional Comments</label>
                                    <textarea resize="none" className="textarea" value={this.state.comment} onChange={(e) => {
                                        this.setState({
                                            comment: e.target.value,
                                        })
                                    }} onKeyDown={this.onKeyDown.bind(this)} placeholder='Write your message here…'>
                                    </textarea>
                                    {
                                        this.state.comment &&
                                        <div>
                                            <h5 className=''>You may not see improvements in all the areas you have selected, but studying your photographs may surprise you, and you should see improvements. Please study the section of <span className='highlighted'>Understanding Your Photos.</span></h5>
                                        </div>

                                    }
                                </div>
                                <Button title='Submit' disabled={this.state.disableBtn} onClick={() => this.onGoClick()}></Button>
                                <ModalStep show={modalShow} onHide={this.setModalShow.bind(this)}></ModalStep>
                            </div>
                        </Scrollbars>
                    </div>
                    : <div></div>}
            </div>


        )
    }
}

export default QuestionnaireStep2;

