import React, { useState } from 'react';
import { setLocalActiveClass, setStoredData, getStoredData } from "../../../utils/helper";
import './manualentry.scss';
import { Scrollbars } from "react-custom-scrollbars";
import HomeSideMenu from '../../../components/homesidebar/homesidebar'
import HomeHeader from '../../../components/homeheader/homeheader';
import { Row, Col } from 'react-bootstrap';
import TextField from '../../../components/textfield/textfield';
import Button from '../../../components/Button/button';
import TouchTestImg from '../../../assets/images/touch.svg';
import DropdownMenu from '../../../components/dropdown/dropdown';
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import WebService from "../../../utils/Webservice"
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import ManualTouchTest5mm from "../../onboarding/manualtouchtest5mm/manualtouchtest5mm"
import moment from 'moment';
const optionEthnicity = ['AM', 'PM']
const wereDeviceOption = ['I wore the 3mm', 'I wore the 5mm']

class Manualentry extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: moment(new Date()).format("DD/MM/YYYY"),
            profession: '',
            profession: '',
            treatment: '',
            nMaritalStatus: '',
            sEthnicity: '',
            isManualEntry: false,
            nPrefered: moment(new Date(), 'ddd DD-MMM-YYYY,A').format('A') === 'AM' ? 0 : 1,
            // nPrefered: 0,
            // nPreferedTxt: '',
            errorText: false,
            disableTime: false,
            disableTouch: false,
            errorTextMin: false,
            errorTextMinSec: false,
            errorTextSec: false, disableTime: false, disableTouch: false,
            disableBtn: false, toaster: false,
            message: "", isShow: false,
            _id: getStoredData("_id"),
            // nDeviceType: this.props.history.location.state.nDeviceType
            nDeviceType: 0,
            deviceTxt: ''
        }
        setLocalActiveClass("Home")
    }

    // handleChange(date) {
    //     this.setState({
    //         startDate: date
    //     });
    // }
    // endDateChange(date) {
    //     this.setState({
    //         endDate: date
    //     });
    // }
    // handleSelect(eventKey) {
    //     this.setState({ nPrefered: eventKey })
    // }
    // onItemSelect(e, option, eventKey) {
    //     console.log(e, option, eventKey);

    //     (e.target.text.length > 0 ? this.setState({ disableBtn: true }) : this.setState({ disableBtn: false }))
    //     this.setState({
    //         nPreferedTxt: e.target.text,
    //         selOption: option,
    //         nPrefered: eventKey,
    //     })
    // }
    handleSelectDevice(eventKey) {
        this.setState({ nDeviceType: eventKey })
    }
    onItemSelectDevice(e, option, eventKey) {
        console.log(e, option, eventKey);

        (e.target.text.length > 0 ? this.setState({ disableBtn: true }) : this.setState({ disableBtn: false }))
        this.setState({
            deviceTxt: e.target.text,
            selOption: option,
            nDeviceType: eventKey,
        })
    }
    onHandleTimeChange(event) {
        let sTime = event.target.value.split(':')
        let min = Number(sTime[0])
        let sec = sTime[1]
        { min > 60 ? this.setState({ errorTextMin: true }) : this.setState({ errorTextMin: false }) }
        { sec > 59 ? this.setState({ errorTextSec: true }) : this.setState({ errorTextSec: false }) }
        { min === 60 && sec > 0 ? this.setState({ errorTextMinSec: true }) : this.setState({ errorTextMinSec: false }) }
        { min === 0 && (sec <= 0 || sec === undefined || sec === '') ? this.setState({ errorText: true }) : this.setState({ errorText: false }) }

        this.setState({ sTotalTime: event.target.value }, () => {
            { (this.state.sTotalTime.length > 0 && !this.state.errorTextMin && !this.state.errorTextSec && !this.state.errorText && !this.state.errorTextMinSec) ? this.setState({ disableTime: true }) : this.setState({ disableTime: false }) }
        })
    }
    onHandleTouchesChange(event) {
        this.setState({ nTouchCount: event.target.value })
    }

    onGoClick() {
        this.manualTouchTestWebServices()
    }
    async manualTouchTestWebServices() {
        let { nDeviceType, nPrefered, sTotalTime, nTouchCount, startDate } = this.state
        let sTime = this.state.sTotalTime.replace(":", ".")
        sTime = sTime.replace("_", "0")
        this.setState({ isLoading: true, linkDisable: true });
        console.log(this.state.startDate, "this.state.startDate");
        let date = startDate === '' ? new Date() : new Date(startDate)
        try {
            let obj = {
                iUserId: this.state._id,
                nTouchCount: 0,
                sTotalTime: sTime,
                nDeviceType: Number(nDeviceType),
                nPrefered: nPrefered,
                dCreatedAt: moment().format()
            };
            let response = await WebService.UserTouchTest(obj);
            this.setState({ isLoading: false });

            if (response.status) {
                this.setState({ toaster: true, message: response.MESSAGES, isShow: true })
                console.log("response" + response.status);
                this.handleSuccessResponse(response, sTime);
            } else {
                this.setState({ toaster: true, message: response.MESSAGES, isShow: true })
                console.log("response" + response.status);
            }
            if (nDeviceType === 0) {
                setStoredData("touchtest3mm", sTime)
            } else {
                setStoredData("touchtest5mm", sTime)
            }
        } catch (error) {
            console.log(error);
        }
    }
    handleSuccessResponse(response, sTime) {
        setStoredData("touchTestId", response.data.touchTestId)
        setTimeout(() => {
            // if (sTime < 300.0) {
            //     {
            //         this.state.nDeviceType === 0 ?
            //             this.props.history.push(('/3mmtestreport'), { isViewFrom: 'home' })
            //             :
            //             this.props.history.push(('/5mmtestreport'), { isViewFrom: 'home' })
            //     }
            // } else {
            // }
            this.props.history.push(('/3mmhome'), { isViewFrom: 'home' })
        }, 3000)
    }
    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }
    render() {
        let { profession, isManualEntry, toaster, deviceTxt, isShow, message, treatment, nPreferedTxt, disableTouch, startDate, nDeviceType } = this.state
        //  let nPrefered = nPreferedTxt === null || nPreferedTxt === '' ? "Select" : nPreferedTxt
        let nDeviceTitle = deviceTxt === null || deviceTxt === '' ? "Select" : deviceTxt

        let deviceTitle = nDeviceType === 0 || nDeviceType === '0' || nDeviceType === '' ? '3mm Device (minutes)' : '5mm Device (minutes)'
        // let touchTitle = nDeviceType === 0 || nDeviceType === '0' || nDeviceType === '' ? '3mm Device (touches)' : '5mm Device (touches)'
        let disable = (this.state.disableTime && this.state.disableBtn) ? false : true

        return (
            <div className='main-Entry'>
                <HomeSideMenu {...this.props}></HomeSideMenu>
                <HomeHeader {...this.props}></HomeHeader>
                <div className='inner-Entry'>
                    <Scrollbars>
                        {toaster && <ToastComponent message={message} show={isShow} onClose={() => this.setState({ toaster: false })} delay={3000} autohide >}></ToastComponent>}

                        <div className='main-Container'>
                            <div >
                                <div className='img-Container'>
                                    <img src={TouchTestImg} className="logo" alt="logo" />
                                </div>
                                <h1 className='paddingB15'>Record a session</h1>
                                <div className='content-Section'>
                                    <h5 className='paddingB15'>Entries can only be made immediately after your session. You cannot make retrospective entries.</h5>
                                </div>
                                <form className='question-Form'>
                                    <Row>
                                        {/* <Col lg={6} sm={6}>
                                            <label className='cust-Label'>AM/PM</label>
                                            <DropdownMenu onSelect={this.handleSelect.bind(this)} onItemSelect={this.onItemSelect.bind(this)} title={"nPrefered"} options={optionEthnicity}></DropdownMenu>
                                        </Col> */}
                                        <Col lg={6} md={6} sm={6} className='custom-Datepicker'>
                                            <label className='cust-Label'>Date</label>
                                            <InputMask {...this.props} value={startDate} className="customMask " maskChar={null} />
                                            {/* <DatePicker
                                                showYearDropdown
                                                showMonthDropdown
                                                peekNextMonth
                                                minDate={(new Date(), 5)}
                                                maxDate={new Date()}
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText="DD/MM/YY"
                                                className="inputBox"
                                                onChangeRaw={this.handleDateChangeRaw}
                                                selected={startDate}
                                                onChange={this.handleChange.bind(this)}
                                            /> */}
                                        </Col>
                                        <Col lg={6} md={6} sm={6} className='custom-Datepicker-Device' >
                                            <label className="cust-Label">Device</label>
                                            <DropdownMenu onSelect={this.handleSelectDevice.bind(this)} onItemSelect={this.onItemSelectDevice.bind(this)} title={nDeviceTitle} options={wereDeviceOption}></DropdownMenu>

                                            {/* <InputMask {...this.props} placeholder={'000'} onChange={this.onHandleTouchesChange.bind(this)} mask="999" type="tel" className="customMask" maskChar={null} /> */}
                                        </Col>
                                        <Col lg={6} md={6} sm={6} className='mTop10'>
                                            <label className="cust-Label">{deviceTitle}</label>
                                            <InputMask {...this.props} placeholder={'mm:ss'} onChange={this.onHandleTimeChange.bind(this)} mask="99:99" type="tel" className="customMask" maskChar={null} />
                                            {(this.state.errorText || this.state.errorTextMin || this.state.errorTextSec || this.state.errorTextMinSec) === true ? <span className='error-msg'>Touch test should not be more than 60 minutes</span> : ''}
                                        </Col>
                                    </Row>
                                </form>
                                <Button title='Save' disabled={disable} onClick={() => this.onGoClick()}></Button>

                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        )
    }
}




export default Manualentry;

