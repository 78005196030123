import React from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import './savepreferences.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu';
import AdminSideMenu from '../../../components/adminsidebar/adminsidebar';
import HomeSideMenu from '../../../components/homesidebar/homesidebar';
import AdminHeader from '../../../components/adminheader/adminheader';
import { Row, Col } from 'react-bootstrap';
import Header from '../../../components/header/header';
import Button from '../../../components/Button/button'
import SettingImg from '../../../assets/images/happiness.svg';
import WebService from "../../../utils/Webservice";
import HomeHeader from '../../../components/homeheader/homeheader';
const moment = require('moment');
class SavePreferences extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isViewFrom: this.props.history.location.state.isViewFrom,
            _id: (this.props.history.location.state.isViewFrom === 'adminQuestionnaire' || this.props.history.location.state.isViewFrom === 'adminPreferences') ? localStorage.getItem("userId") : localStorage.getItem("_id"),
            sProfession: '',
            sAntiAgeingTreatement: '',
            nMaritalStatus: '',
            sEthnicity: '',
            dob: '',
            wishlistArray: []
        }
        this.props.history.location.state.isViewFrom === 'homeViewQuestionnaire' ? setLocalActiveClass("ViewQuestionnaire") :
            this.props.history.location.state.isViewFrom === 'homeSetPreferences' ? setLocalActiveClass("ViewPreferences")
                : this.props.history.location.state.isViewFrom === 'adminQuestionnaire' ? setLocalActiveClass("ViewQuestionnaire") : this.props.history.location.state.isViewFrom === 'adminPreferences' ? setLocalActiveClass("ViewPreferences") : setLocalActiveClass("SetPreferences")

    }
    componentDidMount() {
        this.getUserPreferencesWebservice()
    }
    async getUserPreferencesWebservice() {
        try {
            let obj = {
                _id: this.state._id,
            };
            let response = await WebService.getUserDetails(obj);
            this.setState({ isLoading: false });
            if (response.status) {
                console.log("Success::" + response.status);
                this.handleSuccessResponse(response);
            } else {
                this.handleErrorResponse(response);
                console.log("Error::" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }


    handleSuccessResponse(response) {
        let { dob, sProfession, sAntiAgeingTreatement, sMaritalStatus, sEthnicity, aWishlist, amPreference, pmPreference, nPrefered, nAntiAgeingFlag } = response.data
        let sAntiAgeing = (nAntiAgeingFlag === null || nAntiAgeingFlag === 0) ? 'No' : sAntiAgeingTreatement
        let formattedDob = (dob === null || dob === undefined || dob === "1970-01-01") ? '' : moment(new Date(dob).toString()).format("YYYY-MM-DD")
        let amPreferences = amPreference === null || amPreference === undefined ? '9:30' : amPreference
        let pmPreferences = pmPreference === null || pmPreference === undefined ? '9:30' : pmPreference
        this.setState({
            sProfession: sProfession,
            sAntiAgeingTreatement: sAntiAgeing,
            dob: formattedDob,
            sMaritalStatus: sMaritalStatus,
            sEthnicity: sEthnicity,
            wishlistArray: aWishlist,
            amPreference: amPreferences,
            pmPreference: pmPreferences,
            nPrefered: nPrefered === 0 ? "With my AM Preference" : "With my PM Preference"
        })

    }
    onPreferencesClick() {
        this.props.history.push('/congratulations')
    }
    onRetakeClick() {
        this.props.history.push('/5mmtouchtest')
    }
    onEditQuestionnaireClick() {
        console.log(this.state.isViewFrom);

        this.props.history.push('/questionnaire', { isViewFrom: this.state.isViewFrom, isPage: 'editpreference' })
    }
    onEditPreferencesClick() {
        this.props.history.push('/setpreferences', { isViewFrom: this.state.isViewFrom })
    }
    render() {
        let { isViewFrom, amPreference, pmPreference } = this.state
        let amPreDefault = amPreference === null || amPreference === 'undefined:00' ? '9:30' : amPreference
        let pmPreDefault = pmPreference === null || pmPreference === 'undefined:00' ? '9:30' : pmPreference
        return (
            <div className={isViewFrom === 'onboarding' ? 'main-SavePreferences' : 'main-SavePreferences centerAlign'}>

                {(isViewFrom == 'adminPreferences' || isViewFrom == 'adminQuestionnaire') && <AdminHeader  {...this.props} ></AdminHeader>}
                {this.state.isViewFrom === "homeViewQuestionnaire" || this.state.isViewFrom === "homeSetPreferences" ? <HomeSideMenu {...this.props} showMorePopup={true}></HomeSideMenu> :
                    this.state.isViewFrom === "adminQuestionnaire" || this.state.isViewFrom === "adminPreferences" ? <AdminSideMenu  {...this.props} showMorePopup={true}></AdminSideMenu> : <SideMenu {...this.props}></SideMenu>}
                {(isViewFrom === "homeViewQuestionnaire" || isViewFrom == 'homeSetPreferences' || isViewFrom == 'home') && <HomeHeader  {...this.props} ></HomeHeader>}
                <div className='inner-SavePreferences'>
                    <Scrollbars className='testreport-Scroll'>
                        <div className='main-Container'>
                            <div className='img-Container'>
                                <img src={SettingImg} className="logo" alt="logo" />
                            </div>
                            {this.state.isViewFrom === "homeViewQuestionnaire" || this.state.isViewFrom === "adminQuestionnaire" ? <h2 className='paddingB15'>Questionnaire</h2> : this.state.isViewFrom === "homeSetPreferences" ? <h2 className='paddingB15'>Set Preferences</h2> : <h2 className='paddingB24'>Please review your details below</h2>}

                            {this.state.isViewFrom === "homeSetPreferences" || this.state.isViewFrom === "adminPreferences" ? "" :
                                <div className='question-Form'>
                                    <Row>
                                        <Col xl={4} lg={4} md={4} sm={4} className='paddingB24'>
                                            <h5 className='fontM'> Date of Birth:</h5>
                                            <h5>{this.state.dob}</h5>
                                        </Col>
                                        <Col xl={4} lg={4} md={4} sm={4} className='paddingB24'>
                                            <h5 className='fontM'> Marital Status:</h5>
                                            <h5>{this.state.sMaritalStatus}</h5>
                                        </Col>
                                        {/* <Col xl={4} lg={4} md={4} sm={4} className='paddingB24'>
                                            <h5 className='fontM'> Ethnicity:</h5>
                                            <h5>{this.state.sEthnicity}</h5>
                                        </Col> */}
                                        {/* <Col xl={4} lg={4} md={4} sm={4} className='paddingB24'>
                                            <h5 className='fontM'> Profession:</h5>
                                            <h5>{this.state.sProfession}</h5>
                                        </Col> */}
                                        <Col xl={4} lg={4} md={4} sm={4} className='paddingB24'>
                                            <h5 className='fontM'> Other Anti Ageing Treatments:</h5>
                                            <h5>{this.state.sAntiAgeingTreatement}</h5>
                                        </Col>
                                    </Row>


                                    <div className='wishlist-Details'>
                                        <h5 className='fontM'> Wishlist</h5>
                                        <div className='list-Details'>
                                            <Row>
                                                {
                                                    this.state.wishlistArray.map((item, i) => (<Col lg={6} md={6} sm={6} xs={6}><p className='list-text'>{item}</p></Col>))

                                                }
                                            </Row>
                                        </div>
                                    </div>

                                    {this.state.isViewFrom === "onboarding" ?
                                        <div className='edit-Section'>
                                            <span className='aLink' onClick={() => this.onEditQuestionnaireClick()}>
                                                Edit Questionnaire
                                </span>
                                        </div> : ''}
                                </div>}
                            {this.state.isViewFrom === "homeViewQuestionnaire" || this.state.isViewFrom === "adminQuestionnaire" ? '' :
                                <div className='question-Form paddingTop'>
                                    <Row>
                                        <Col xl={4} lg={4} md={4} sm={4} className='paddingB24'>
                                            <h5 className='fontM'> AM Wear Time:</h5>
                                            <h5>{amPreDefault}</h5>
                                        </Col>
                                        <Col xl={4} lg={4} md={4} sm={4} className='paddingB24'>
                                            <h5 className='fontM'> PM Wear Time:</h5>
                                            <h5>{pmPreDefault}</h5>
                                        </Col>
                                        <Col xl={4} lg={4} md={4} sm={4} className='paddingB24'>
                                            <h5 className='fontM'> Touch Test Time</h5>
                                            <h5>{this.state.nPrefered}</h5>
                                        </Col>
                                    </Row>
                                    {this.state.isViewFrom === "onboarding" ?
                                        <div className='edit-Section bottomP'>

                                            <span className='aLink' onClick={() => this.onEditPreferencesClick()}>
                                                Edit Set Preferences
                                        </span>
                                        </div> : ''}
                                </div>}
                            {this.state.isViewFrom === "homeViewQuestionnaire" || this.state.isViewFrom === "homeSetPreferences" || this.state.isViewFrom === "adminQuestionnaire" || this.state.isViewFrom === "adminPreferences" ? '' : <Button title='confirm details' onClick={() => this.onPreferencesClick()}></Button>}
                        </div>
                    </Scrollbars>
                </div>
            </div >

        )
    }
}




export default SavePreferences;

