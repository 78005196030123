import React, { useState } from "react";
import { setLocalActiveClass } from "../../../utils/helper";
import "./userSetPassword.scss";
import { Scrollbars } from "react-custom-scrollbars";
import WebService from "../../../utils/Webservice";
import Button from "../../../components/Button/button";
import TextField from "../../../components/textfield/textfield";
import CONSTANTS from "../../../utils/constants";
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
class UserSetPassword extends React.Component {
  // this.toast.configure()
  constructor(props) {
    super(props);
    let queries = queryString.parse(this.props.location.search);
    //console.log(queries);
    if (
      queries.string == undefined ||
      queries.string == null ||
      queries.string == ""
    ) {
      this.props.history.push("/");
    }
    //console.log("querystring data:::", queries.string)
    this.state = {
      loginForm: true,
      forgotForm: false,
      sPassword: {
        value: "",
        isValid: false,
        message: "",
      },
      sConfirmPassword: {
        value: "",
        isValid: false,
        message: "",
      },
      matchData: {
        value: queries.string,
        isValid: false,
        message: "",
      },

      forgetEmail: {
        value: "",
        isValid: false,
        message: "",
      },
      errorMessage: "",
      isToastShow: false,
      showPass: false,
    };
  }

  async onSendLink() {
    // console.log("TTT", this.state.sPassword, this.state.sConfirmPassword);

    //console.log("forgotPasswordWebServices works", this.state)
    // toastr.success("sdfsdfsdfsdfsdfsdf")
    // return 0;
    // console.log("this.state.sPassword.value", this.state.sPassword.value)
    // console.log("this.state.sConfirmPassword.value", this.state.sConfirmPassword.value)
    try {
      let obj = {
        sPassword: this.state.sPassword.value,
        sConfirmPassword: this.state.sConfirmPassword.value,
        matchData: this.state.matchData.value,
      };
      //console.log("obj", obj)
      if (obj.sPassword == obj.sConfirmPassword) {
        let response = await WebService.userSetPassword(obj);
        this.setState({ isLoading: false });
        console.log("sss::", response);
        if (response.status) {
          console.log("Success::", response);
          this.setState({
            toaster: true,
            errorMessage: response.MESSAGES,
            isToastShow: true,
            isWarning: false,
          });
          this.setState({
            sPassword: {
              value: "",
              isValid: false,
              message: "",
            },
          });
          this.setState({
            sConfirmPassword: {
              value: "",
              isValid: false,
              message: "",
            },
          });
          toastr.success(response.MESSAGES);
          // this.props.history.push('/')
        } else {
          // this.handleErrorResponse(response);
          this.setState({
            toaster: true,
            errorMessage: response.MESSAGES,
            isToastShow: true,
            isWarning: true,
          });
          this.setState({
            sPassword: {
              value: "",
              isValid: false,
              message: "",
            },
          });
          this.setState({
            sConfirmPassword: {
              value: "",
              isValid: false,
              message: "",
            },
          });
          console.log("Error::", response);
          toastr.warning(response.MESSAGES);
        }
        //console.log("Confirm Password Match::");
      } else {
        //console.log("Confirm Password Not Match::");
        toastr.warning("The confirm password does not match. Please retry.");
      }
    } catch (error) {
      console.log("error,,,", error);
      toastr.warning("Sorry! This link has expired. Please try with new link.");
    }
  }

  handleUserInput = (e) => {
    //console.log("aaaaa,,", e.target.name, e.target.value)

    let name = e.target.name;
    let value = e.target.value;

    var obj = {};
    obj[name] = {
      value: value,
    };
    this.setState({
      showEye: name == "password" && value != "" ? true : false,
    });

    this.setState(obj, () => {
      this.validateField(name, value);
    });
  };
  // text on change //
  validateField(fieldName, value) {
    let valid = false;
    let message = "";
    //    console.log("fieldName, value,,", fieldName, value, this.state.sPassword.value)
    switch (fieldName) {
      case "sPassword":
        valid = value.match(CONSTANTS.REGEX.PASSWORD) && value.length >= 0;
        // console.log('valid', valid)
        !valid &&
          (message =
            value.length <= 0
              ? CONSTANTS.MESSAGES.VALIDATION.PASSWORD
              : CONSTANTS.MESSAGES.VALIDATION.INVALID_PASSWORD);
      case "sConfirmPassword":
        valid = value == this.state.sPassword.value && value.length >= 0;
        console.log("valid", valid);
        console.log(
          "fieldName, value,,",
          fieldName,
          value,
          this.state.sPassword.value
        );
        !valid &&
          (message =
            value.length <= 0
              ? CONSTANTS.MESSAGES.VALIDATION.CONFIRM_PASSWORD
              : CONSTANTS.MESSAGES.VALIDATION.CONFIRM_PASSWORD);
        // case "sConfirmPassword":
        //     valid = (value.match(this.state.sPassword.value) && this.state.sPassword.value == this.state.sConfirmPassword.value) && value.length >= 0;
        //     console.log("valid", valid)
        //     !valid &&
        //         (message =
        //             value.length <= 0
        //                 ? CONSTANTS.MESSAGES.VALIDATION.CONFIRM_PASSWORD
        //                 : CONSTANTS.MESSAGES.VALIDATION.CONFIRM_PASSWORD);
        break;
    }
    var obj = {};
    obj[fieldName] = {
      message: message,
      isValid: valid,
      value: value,
    };
    this.setState(obj, () => {
      fieldName == "forgetEmail"
        ? this.validateForgotForm()
        : this.validateForm();
    });
  }

  //------------ validfate for button disable
  validateForm() {
    console.log("sPAsswored:", this.state.sPassword);
    console.log("sConfirmPassword:", this.state.sConfirmPassword);
    this.setState({
      isFormValid:
        this.state.sPassword.isValid &&
        this.state.sConfirmPassword.isValid &&
        this.state.sPassword.message != "Password is Invalid" &&
        this.state.sPassword.value == this.state.sConfirmPassword.value,
    });
  }

  render() {
    let { disabled, toaster, isToastShow, errorMessage, isWarning } =
      this.state;
    return (
      <div className="main-ForgotPassword">
        {toaster && (
          <ToastComponent
            isWarning={isWarning}
            message={errorMessage}
            show={isToastShow}
            onClose={() => {
              this.setState({ toaster: false });
            }}
            delay={3000}
            autohide
          ></ToastComponent>
        )}

        <div className="inner-ForgotPassword">
          <Scrollbars className="home-Scroll">
            <div
              className="main-Container height-width"
              style={{ height: 600 }}
            >
              <div className="img-upload">
                <img
                  src={require("../../../assets/images/forgot.png")}
                  className="profileimg"
                />
              </div>
              <h4>Set New Password</h4>
              <h5>Enter your new password and confirm password. </h5>
              <div className="form">
                <TextField
                  title="New Password"
                  type="Password"
                  value={this.state.sPassword.value}
                  autoFocus={true}
                  name="sPassword"
                  iconClass={"icon-lock"}
                  onChange={this.handleUserInput.bind(this)}
                  errorMessage={this.state.sPassword.message}
                  // onKeyDown={this.onKeyDown.bind(this)}
                  placeholder="********"
                ></TextField>
                {/* <span> {this.state.sPassword.message}</span> */}

                {/* <TextField title="Password" type="password"
                                    value={this.state.password.value}
                                    name="password"
                                    type={this.state.showPass ? "text" : "password"}
                                    iconClass={
                                        this.state.showEye
                                            ? this.state.showPass
                                                ? "icon-eye"
                                                : "icon-eyeoff"
                                            : "icon-eyeoff"
                                    }
                                    onKeyDown={this.onKeyDown.bind(this)}
                                    onClick={() =>
                                        this.setState({ showPass: !this.state.showPass })
                                    }
                                    onChange={this.handleUserInput.bind(this)}
                                    errorMessage={this.state.password.message}
                                    placeholder="******" ></TextField> */}
                <TextField
                  title="Confirm Password"
                  value={this.state.sConfirmPassword.value}
                  autoFocus={true}
                  type={this.state.showPass ? "text" : "password"}
                  iconClass={
                    this.state.showEye
                      ? this.state.showPass
                        ? "icon-eye"
                        : "icon-eyeoff"
                      : "icon-eyeoff"
                  }
                  name="sConfirmPassword"
                  onChange={this.handleUserInput.bind(this)}
                  onClick={() =>
                    this.setState({ showPass: !this.state.showPass })
                  }
                  errorMessage={this.state.sConfirmPassword.message}
                  // onKeyDown={this.onKeyDown.bind(this)}
                  placeholder="********"
                ></TextField>
                <span className="TxtStyle">
                  Password should contain lower & uppercase, a number, and a
                  special character with minimum 6 character.
                </span>
                <Button
                  title="Submit"
                  disabled={!this.state.isFormValid}
                  onClick={() => this.onSendLink()}
                ></Button>
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default UserSetPassword;
