import React from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import './questionnaire.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu';
import HomeSideMenu from '../../../components/homesidebar/homesidebar';
import AdminSideMenu from '../../../components/adminsidebar/adminsidebar';
import { Row, Col } from 'react-bootstrap';
import Header from '../../../components//header/header';
import Button from '../../../components/Button/button'
import QuestionImg from '../../../assets/images/questionNew.svg';
import TextField from '../../../components/textfield/textfield';
import DropdownMenu from '../../../components/dropdown/dropdown';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getFormDataObject, getCookie, checkForHistoryParam } from '../../../utils/helper';
import WebService from "../../../utils/Webservice"
import ToastComponent from '../../../components/toastcomponent/toastcomponent';
import CONSTANTS from '../../../utils/constants';
import HomeHeader from '../../../components/homeheader/homeheader';
import { object } from 'prop-types';
const moment = require('moment');

const options = ['Widow', 'Single', 'Divorced', 'Married']
const optionEthnicity = ['European', 'Black', 'Asian', 'Hispanic', 'Arabic', 'Latino']
const antiAgeingOption = ['No', 'Yes']

class Questionnaire extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            startDate: '',
            sProfession: '',
            sAntiAgeingTreatement: '',
            nMaritalStatus: '',
            nAntiAgeingFlag: '',
            nAntiAgeingValue: '',
            sEthnicity: '',
            title: '',
            sEthnicityTitle: '',
            defaultOptionVal: '',
            disableBtn: false,
            selOption: '',
            toaster: this.props.history.action == 'PUSH' ? (checkForHistoryParam(this.props.history.location.state, 'toaster') || false) : false,
            message: checkForHistoryParam(this.props.history.location.state, 'toaster') ? CONSTANTS.MESSAGES.SUCCESS.AGREEMENT : '',
            _id: localStorage.getItem("_id"),
            isViewFrom: this.props.history.location.state.isViewFrom,
            isPage: this.props.history.location.state.isPage
        }

        this.props.history.location.state.isViewFrom === 'homeViewQuestionnaire' ? setLocalActiveClass("ViewQuestionnaire")
            : setLocalActiveClass("Questionnaire")
        // console.log(this.state.isViewFrom, "isViewFrom");

    }

    onGoClick() {

        this.aboutyouWebServices()
        // await this.props.history.push('/questionnairestep2')
    }
    onKeyDown(e) {
        e.keyCode == 13 && this.aboutyouWebServices()
        //  await this.props.history.push('/questionnairestep2')
    }

    componentDidMount() {
        this.aboutYouUserData()
    }

    handleChange(date) {
        console.log(date)
        this.setState({
            startDate: date
        })
    }
    endDateChange(date) {
        this.setState({
            endDate: date
        });
    }
    handleTextChange(e) {
        this.setState({ sProfession: e.target.value })
    }
    handletreatmentTextChange(e) {
        { e.target.value.length > 0 ? this.setState({ disableBtn: false }) : this.setState({ disableBtn: true }) }

        this.setState({ sAntiAgeingTreatement: e.target.value })
    }
    handleSelect(eventKey) {
        this.setState({ nMaritalStatus: eventKey })
    }
    handleSelectEvent(eventKey) {
        this.setState({ sEthnicity: eventKey })
    }
    handlEvent(eventKey) {
        this.setState({ nAntiAgeingFlag: eventKey })
    }
    onItemSelect(e, option, eventKey) {
        this.setState({
            title: e.target.text,
            selOption: option,
            value: eventKey,
        })
    }
    onItemSelectValue(e, option, eventKey) {

        this.setState({
            sEthnicityTitle: e.target.text,
            selOption: option,
            value: eventKey,
        })
    }
    onItemHandleEvent(e, option, eventKey) {

        {
            option === 'No' ?
                this.setState({ disableBtn: false }) :
                ((this.state.sAntiAgeingTreatement == null || this.state.sAntiAgeingTreatement.length <= 0) ?
                    this.setState({ disableBtn: true }) : this.setState({ disableBtn: false }))
        }
        this.setState({
            nAntiAgeingFlag: e.target.text,
            selOption: option,
            value: eventKey,
            nAntiAgeingValue: eventKey,
        })
    }
    ////////////////////get Data/////////////////////
    async aboutYouUserData() {
        try {
            let obj = {
                _id: this.state._id,
            };
            let response = await WebService.getUserDetails(obj);
            this.setState({ isLoading: false });
            if (response.status) {
                console.log("Success::" + response.status);
                this.handleSuccessResponse(response);
            } else {
                this.handleErrorResponse(response);
                console.log("Error::" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }
    handleSuccessResponse(response) {
        let { dob, sProfession, sAntiAgeingTreatement, nAntiAgeingFlag, sMaritalStatus, sEthnicity } = response.data
        // console.log(sMaritalStatus, sEthnicity, sAntiAgeingTreatement, nAntiAgeingFlag, "Sucess Handle")
        // console.log(dob, "req.dob");
        let dDate = dob === undefined ? '' : new Date(dob)
        if (nAntiAgeingFlag != null) {
            {
                nAntiAgeingFlag === 0 ?
                    this.setState({ disableBtn: false }) :
                    ((sAntiAgeingTreatement == null || sAntiAgeingTreatement.length <= 0) ?
                        this.setState({ disableBtn: true }) : this.setState({ disableBtn: false }))
            }
        } else {
            this.setState({ disableBtn: true })
        }
        this.setState({
            sProfession: sProfession,
            sAntiAgeingTreatement: sAntiAgeingTreatement,
            title: sMaritalStatus,
            sEthnicityTitle: sEthnicity,
            nAntiAgeingFlag: antiAgeingOption[nAntiAgeingFlag],
            nAntiAgeingValue: nAntiAgeingFlag,
            startDate: dDate,
        })
    }
    ////////////////////Add Data/////////////////////
    async aboutyouWebServices() {
        let nAntiAgeingFlag = this.state.nAntiAgeingFlag === 'Yes' ? true : false
        //  console.log(this.state.startDate);
        this.setState({ disableBtn: true })
        try {
            let obj = {
                _id: this.state._id,
                dob: this.state.startDate,
                sMaritalStatus: this.state.title,
                sEthnicity: this.state.sEthnicityTitle,
                sProfession: this.state.sProfession,
                nAntiAgeingFlag: this.state.nAntiAgeingValue
            };
            if (this.state.sMaritalStatus) {
                obj['sMaritalStatus'] = this.state.title
            }
            if (nAntiAgeingFlag) {
                obj['sAntiAgeingTreatement'] = this.state.sAntiAgeingTreatement
            }
            let response = await WebService.saveUserPreferences(obj);
            this.setState({ isLoading: false });
            if (response.status) {
                // console.log("Success::" + response.status);
                this.props.history.push('/questionnairestep2', { isViewFrom: this.state.isViewFrom, isPage: this.state.isPage })
            } else {
                this.handleErrorResponse(response);
                console.log("Error::" + response.status);
            }

        } catch (error) {
            console.log(error);
        }

    }

    render() {
        let { value } = this.props
        let { sProfession, sAntiAgeingTreatement, message, defaultOptionVal, nAntiAgeingFlag, toaster, sEthnicityTitle } = this.state
        let maritalTitle = this.state.title == null || undefined ? "Select" : this.state.title
        let ethnicityTitle = this.state.sEthnicityTitle == null || undefined ? "Select" : this.state.sEthnicityTitle
        let antiAgeingFlag = this.state.nAntiAgeingFlag == null || undefined ? "Select" : this.state.nAntiAgeingFlag
        return (
            <div className='main-QuestionPage' >
                <Header></Header>
                {this.state.isViewFrom === "homeViewQuestionnaire" || this.state.isViewFrom === "homeSetPreferences" ? <HomeSideMenu {...this.props} showMorePopup={true}></HomeSideMenu> : <SideMenu {...this.props}></SideMenu>}

                <div className='questionPage' >
                    <Scrollbars>
                        <div className='main-Container'>
                            <div className='img-Container'>
                                <img src={QuestionImg} className="logo" alt="logo" />
                            </div>
                            <h2 className='paddingB15'>Questionnaire - About you</h2>

                            <form className='question-Form'>
                                <Row>
                                    <Col lg={6} sm={6} className='custom-Datepicker'>
                                        <label className='cust-Label'>Date of Birth</label>
                                        <DatePicker
                                            showYearDropdown
                                            showMonthDropdown
                                            peekNextMonth
                                            minDate={(new Date(), 5)}
                                            maxDate={new Date()}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YY"
                                            className="inputBox"
                                            selected={this.state.startDate}
                                            onChange={this.handleChange.bind(this)}
                                        />
                                    </Col>
                                    <Col lg={6} sm={6}>
                                        <TextField title="Profession" value={sProfession} onChange={this.handleTextChange.bind(this)} placeholder="Profession" ></TextField>
                                    </Col>
                                    <Col lg={6} sm={6} className='paddingB15'>
                                        <label className='cust-Label'>Marital Status</label>
                                        <DropdownMenu onSelect={this.handleSelect.bind(this)} onItemSelect={this.onItemSelect.bind(this)} title={maritalTitle} options={options}></DropdownMenu>
                                    </Col>
                                    <Col lg={6} sm={6}>
                                        <label className='cust-Label'>Ethnicity</label>
                                        <DropdownMenu onSelect={this.handleSelectEvent.bind(this)} onItemSelect={this.onItemSelectValue.bind(this)} title={ethnicityTitle} options={optionEthnicity}></DropdownMenu>
                                    </Col>
                                    <Col lg={6} sm={6}>
                                        <label className='cust-Label'>Anti Ageing Treatments*</label>
                                        <DropdownMenu onSelect={this.handlEvent.bind(this)} title={antiAgeingFlag} onItemSelect={this.onItemHandleEvent.bind(this)} options={antiAgeingOption} ></DropdownMenu>
                                    </Col>
                                    <Col lg={6} sm={6}>
                                        <div className="antiYesfield">
                                            {this.state.nAntiAgeingFlag === 'Yes' ? <TextField onKeyDown={this.onKeyDown.bind(this)} value={sAntiAgeingTreatement} onChange={this.handletreatmentTextChange.bind(this)} placeholder="i.e, Botox"></TextField> : ''}
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                            <div className='content-Section'>
                                <h5 className='paddingB15'>We like to build up a picture of our users so that the Oralift programme can be tailored to suits the individual. You have the option not to provide this information if you prefer, except for anti-ageing treatment. Oralift can be complimentary to other treatment but ideally you should not have any other anti-ageing treatment while your results are being monitored.</h5>
                            </div>
                            <Button title='Submit' disabled={this.state.disableBtn} onClick={() => this.onGoClick()}></Button>
                        </div>
                    </Scrollbars>
                </div>
            </div >
        )
    }
}




export default Questionnaire;

