import React from 'react';
import { Button } from 'react-bootstrap';
import './checkbox.scss';
import PropTypes from 'prop-types';

class CheckBox extends React.Component {
    constructor(props, context) {
        super(props, context);
    }
    render() {
        let { title, Id, isChecked, value, name, disabled, } = this.props
        return (
            <div >
                <input Id={Id} disabled={disabled} type="checkbox" name={name} onChange={this.props.onChange} checked={isChecked} value={value} />
                <label for={Id} className='paddingB15'><span></span><span className='label-Css'>{title}</span></label>
            </div>
        )
    }
}




export default CheckBox;

