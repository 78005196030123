import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Button from '../Button/button';
import './CalenderFilter.scss';
var moment = require('moment');
class CalenderFilter extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            selectedDate: [],
            calenderShow: this.props.calenderShow,
            isDisabled: true,
            displayDate: []
        }
    }

    onDateClick(dFormatted) {

        let { selectedDate } = this.state
        if (selectedDate.includes(dFormatted)) {
            let indexOf = selectedDate.indexOf(dFormatted)
            selectedDate.splice(indexOf, 1)
        } else {
            if (selectedDate.length != 2) {
                selectedDate.push(dFormatted)
            }
        }
        if (selectedDate.length === 2) {
            this.setState({ isDisabled: false })
        } else {
            this.setState({ isDisabled: true })
        }
        this.setState({ selectedDate })

    }

    onHide() {
        this.setState({ calenderShow: false })
    }

    render() {
        let { calenderShow, responseDate, filteredPhotos } = this.props

        let { selectedDate, isDisabled, displayDate } = this.state
        //  const uniqueDates = displayDate.filter((val, id, array) => array.indexOf(val) == id)
        const uniqueTags = [];
        responseDate.map(item => {
            if (uniqueTags.indexOf(item.dFormatted) === -1) {
                uniqueTags.push(item.dFormatted)
            }
        });
        // console.log(selectedDate[1]);


        return (

            <div className="filters">
                {this.state.calenderShow === true ?
                    <div className="calenderfilter">

                        <ul className="pbt-6">
                            {
                                uniqueTags.map((item, i) => (
                                    <li
                                        onClick={() => this.onDateClick(item)}
                                        className={(selectedDate.includes(item)) ? "filterdate selected" : "filterdate"}>{item}

                                    </li>
                                ))
                            }
                            <span className="icon-close close-icon" onClick={() => this.onHide()}></span>
                        </ul>

                        <div className="divider"></div>
                        <div className="donebtn">
                            <Row>
                                <Col lg={8} md={8} sm={12} xs={12}>
                                    <div className="vsDate">
                                        <p>
                                            {selectedDate[0] === undefined ? responseDate[0].dFormatted : (moment(selectedDate[0]).format('MMMM DD, YYYY'))}
                                            <label>Vs</label>
                                            {selectedDate[1] === undefined ? responseDate[responseDate.length - 1].dFormatted : (moment(selectedDate[1]).format('MMMM DD, YYYY'))}
                                        </p>
                                    </div>
                                </Col>
                                <Col lg={4} md={4} sm={12} xs={12}>
                                    <Button disabled={isDisabled} onClick={() => this.props.onHide(this.state.selectedDate)}>Done</Button>
                                </Col>
                            </Row>

                        </div>
                    </div>
                    : ''}
            </div>



        )
    }
}



export default CalenderFilter;

