import React from 'react';
import { Nav, NavDropdown, Link, Item } from 'react-bootstrap';
import './adminnotification.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import DatePicker from "react-datepicker";

class AdminNotification extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        let { } = this.state
        let { responseData } = this.props
        return (
            <div className={this.props.className}>
                <div className="main-AdminNotification">
                    <div className='notification-Header'>
                        <span className="icon-close cursor" onClick={() => this.props.onClose()} ></span>
                        <h2 className='header'>Notifications</h2>
                    </div>
                    <Scrollbars className="notificationScroll">
                        {responseData.map((item, index) => (
                            <div>
                                {item.sType === 0 && <div className='notification-Details'>
                                    <div onClick={() => this.props.openPage(item.sType, item.iUserId)} className='inner-Details'>
                                        <p className='innerTitle'>New User Added</p>
                                        <p className='innerTitle'><span className='highlighted'>{item.sUserFirstName + ' ' + item.sUserLastName}</span> has been joined as new device user.</p>
                                    </div>
                                </div>}

                                {item.sType === 1 && <div className='notification-Details'>
                                    <div onClick={() => this.props.openPage(item.sType, item.iUserId)} className='inner-Details'>
                                        <p className='innerTitle'>Photograph Uploaded</p>
                                        <p className='innerTitle'><span className='highlighted'>{item.sUserFirstName + ' ' + item.sUserLastName}</span> has been uploaded new photographs.</p>
                                    </div>
                                </div>}

                                {item.sType === 2 && <div className='notification-Details'>
                                    <div onClick={() => this.props.openPage(item.sType, item.iUserId)} className='inner-Details'>
                                        <p className='innerTitle'>User Completed Touchtest</p>
                                        <p className='innerTitle'><span className='highlighted'>{item.sUserFirstName + ' ' + item.sUserLastName}</span> has completed touchtest.</p>
                                    </div>
                                </div>}

                                {item.sType === 3 && <div className='notification-Details'>
                                    <div onClick={() => this.props.openPage(item.sType, item.iUserId)} className='inner-Details'>
                                        <p className='innerTitle'>User Answered Biweekly Questions </p>
                                        <p className='innerTitle'><span className='highlighted'>{item.sUserFirstName + ' ' + item.sUserLastName}</span> answered biweekly questions.</p>
                                    </div>
                                </div>}
                            </div>
                        ))}
                    </Scrollbars>
                </div>

            </div>
        );
    }
}
AdminNotification.defaultProps = {
    responseData: []
}
export default AdminNotification;
