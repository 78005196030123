import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import $ from 'jquery';
import './comparemodal.scss';
import loaderImg from '../../assets/images/loaderImg.svg';
import InnerImageZoom from 'react-inner-image-zoom';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Cropper from 'react-easy-crop'
import PropTypes from 'prop-types';
import ReactCompareImage from 'react-compare-image';
import UnCheckedImg from '../../../src/assets/images/unchecked.svg';
import { debug } from 'util';
import { TIMEOUT } from 'dns';
class CompareModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            imgLeft: false,
            imgRight: false,
            defalutimage1: false,
            defalutimage2: false,
            loader: false
        }
        this.escFunction = this.escFunction.bind(this);
    }

    initComparisons() {
        var x, i;
        /*find all elements with an "overlay" class:*/
        setTimeout(() => {
            x = document.getElementsByClassName("img-comp-overlay");
            for (let i = 0; i < x.length; i++) {
                /*once for each "overlay" element:
                pass the "overlay" element as a parameter when executing the compareImages function:*/
                compareImages(x[i]);
            }
        }, 3000);

        function compareImages(img) {
            // https://www.w3schools.com/howto/tryit.asp?filename=tryhow_js_image_compare
            var slider, img, clicked = 0, w, h;
            /*get the width and height of the img element*/
            w = img.offsetWidth;
            h = img.offsetHeight;
            // debugger
            /*set the width of the img element to 50%:*/
            img.style.width = (w / 2) + "px";
            /*create slider:*/
            slider = document.createElement("DIV");
            slider.setAttribute("class", "img-comp-slider");
            /*insert slider*/
            img.parentElement.insertBefore(slider, img);
            /*position the slider in the middle:*/
            slider.style.top = (h / 2) - (slider.offsetHeight / 2) + "px";
            slider.style.left = (w / 2) - (slider.offsetWidth / 2) + "px";
            /*execute a function when the mouse button is pressed:*/
            slider.addEventListener("mousedown", slideReady);
            /*and another function when the mouse button is released:*/
            window.addEventListener("mouseup", slideFinish);
            /*or touched (for touch screens:*/
            slider.addEventListener("touchstart", slideReady);
            /*and released (for touch screens:*/
            window.addEventListener("touchstop", slideFinish);
            function slideReady(e) {
                /*prevent any other actions that may occur when moving over the image:*/
                e.preventDefault();
                /*the slider is now clicked and ready to move:*/
                clicked = 1;
                /*execute a function when the slider is moved:*/
                window.addEventListener("mousemove", slideMove);
                window.addEventListener("touchmove", slideMove);
            }
            function slideFinish() {
                /*the slider is no longer clicked:*/
                clicked = 0;
            }
            function slideMove(e) {
                var pos;
                /*if the slider is no longer clicked, exit this function:*/
                if (clicked == 0) return false;
                /*get the cursor's x position:*/
                pos = getCursorPos(e)
                /*prevent the slider from being positioned outside the image:*/
                if (pos < 0) pos = 0;
                if (pos > w) pos = w;
                /*execute a function that will resize the overlay image according to the cursor:*/
                slide(pos);
            }
            function getCursorPos(e) {
                var a, x = 0;
                e = e || window.event;
                /*get the x positions of the image:*/
                a = img.getBoundingClientRect();
                /*calculate the cursor's x coordinate, relative to the image:*/
                x = e.pageX - a.left;
                /*consider any page scrolling:*/
                x = x - window.pageXOffset;
                return x;
            }
            function slide(x) {
                /*resize the image:*/
                img.style.width = x + "px";
                /*position the slider:*/
                slider.style.left = img.offsetWidth - (slider.offsetWidth / 2) + "px";
            }

        }
    }

    imageTwoGrayScaleClick = () => {
        this.setState({ iamgeTwo: !this.state.iamgeTwo, defalutimage2: true }, () => {
            if (this.state.iamgeTwo) {
                document.getElementById("container2").style.filter = "grayscale(100%)";
            } else {
                document.getElementById("container2").style.filter = "grayscale(0%)";
            }
        })
    }
    imageOneGrayScaleClick = () => {
        this.setState({ iamgeOne: !this.state.iamgeOne, defalutimage1: true }, () => {
            if (this.state.iamgeOne) {
                document.getElementById("container1").style.filter = "grayscale(100%)";
            } else {
                document.getElementById("container1").style.filter = "grayscale(0%)";
            }
        })
    }
    displayImage() {
        if (!this.state.imgLeft && !this.state.imgRight) {
            return (
                <img className="loaderImg" src={loaderImg} />
            )
        }
    }

    defaultFunctionCall() {
        this.props.onHide()
        this.props.onClickClose()
        this.setState({ defalutimage2: false, defalutimage1: false, imgLeft: false, imgRight: false })
    }
    escFunction(event) {
        if (event.keyCode === 27) {
            this.defaultFunctionCall()
            //Do whatever when esc is pressed
        }
    }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }
    render() {
        let { show } = this.props
        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.show}
                className="CompareModal"
                sliderLineColor='red'
                sliderLineWidth={6}
                sliderLineColor="rebeccapurple"
            >
                <Modal.Body>
                    <a className='icon-close cursor icon-css' onClick={() => this.defaultFunctionCall()}></a>
                    <h2 className='paddingB24'>Compare</h2>

                    <div className="img-comp-container">
                        {this.displayImage()}
                        <div className="img-comp-img" id="container1" >
                            <div id="sub-container">
                                <TransformWrapper>
                                    <TransformComponent>
                                        <img src={this.props.leftImage} width="381.5" height="530" onLoad={() => this.setState({ imgLeft: true })} />
                                    </TransformComponent>
                                </TransformWrapper>
                            </div>                    </div>
                        <div className={this.state.iamgeOne === true ? 'grayscaleRightPink' : 'grayscaleRight'} onClick={() => show === true && this.imageOneGrayScaleClick()}>
                            <div className='halfRight'></div>
                            <div className='halfLeft'></div>
                        </div>
                        <div className="img-comp-img img-comp-overlay" id="container2">
                            <TransformWrapper>
                                <TransformComponent>
                                    <img src={this.props.rightImage} width="381.5" height="530" onLoad={() => this.setState({ imgRight: true })} />
                                </TransformComponent>
                            </TransformWrapper>
                            <div className={this.state.iamgeTwo ? 'grayscaleLeft' : 'grayscaleLeftblack'} onClick={() => show === true && this.imageTwoGrayScaleClick()}>
                                <div className='halfRight'></div>
                                <div className='halfLeft'></div>
                            </div>
                        </div>
                        <div className={this.state.iamgeTwo ? 'grayscaleLeft' : 'grayscaleLeftblack'} onClick={() => show === true && this.imageTwoGrayScaleClick()}>
                            <div className='halfRight'></div>
                            <div className='halfLeft'></div>
                        </div>
                    </div >
                    {show === true && (!this.state.defalutimage2 && !this.state.defalutimage1 && !this.state.imgLeft && !this.state.imgRight) && this.initComparisons()}
                </Modal.Body>
            </Modal>
        )
    }
}



export default CompareModal;

