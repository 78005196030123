import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import "./sidemenu.scss";
import logo from "../../assets/images/logo.svg";
import CONSTANTS from "../../utils/constants"
class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAgreementFilled: JSON.parse(localStorage.getItem("isAgreementFilled")),
      isQuestionaryFilled: JSON.parse(localStorage.getItem("isQuestionnairyFilled")),
      isPhotosAdded: JSON.parse(localStorage.getItem("isPhotoAdded")),
      is5mmTouch: JSON.parse(localStorage.getItem("5mmTest")),
      is3mmTouch: JSON.parse(localStorage.getItem("3mmTest"))
    }
  }

  toggleClass(element, className) {
    if (!element || !className) {
      return;
    }

    var classString = element.className,
      nameIndex = classString.indexOf(className);
    if (nameIndex == -1) {
      classString += " " + className;
    } else {
      classString =
        classString.substr(0, nameIndex) +
        classString.substr(nameIndex + className.length);
    }
    element.className = classString;
  }

  onSideItemClick(page) {
    let disabled = false
    switch (page) {
      case "Welcome":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/welcome");
        break;
      case "Agreement":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/agreement", { isViewFrom: "onboarding" });
        break;
      case "Questionnaire":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/questionnaire", { isViewFrom: "onboarding" });

        break;
      case "AddPhoto":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/addphotoguide", { isViewFrom: "onboarding" });

        break;
      case "FreeWay":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/freewayspace", { isViewFrom: "onboarding" });

        break;
      case "5mmFittingStep1":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/5mmfittingstep1", { isViewFrom: "5mm Touch Test" });

        break;
      case "TouchTest":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/touchtest", { isViewFrom: "5mm Touch Test" });

        break;

      case "5mmTouchTest":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push('/5mmtouchtest', { isViewFrom: "5mm Touch Test" })

        break;
      case "3mmFittingStep1":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/3mmfittingstep1", { isViewFrom: "3mm Touch Test" });

        break;

      case "3mmTouchTest":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/3mmtouchtest", { isViewFrom: "3mm Touch Test" });

        break;
      case "Setpreferences":

        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/setpreferences", { isViewFrom: "onboarding" });

        break;
      case "Setpreferences":

        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/setpreferences", { isViewFrom: "onboarding" });

        break;
      case "Congratulations":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/congratulations");

        break;
    }

    if (!disabled) {
      console.log("==>" + disabled)
      localStorage.setItem("menuActiveitem", page);
      localStorage.setItem("MainActiveClass", "");

      this.setState({
        activeClass: page,
        MainActiveClass: "",
        hover: false
      });

    }
  }

  render() {
    let { } = this.props;
    let activeClass = localStorage.getItem("menuActiveitem");
    let disable = this.state.disabledMenu


    return (
      <div className="sideMenu">
        <div className="sideMenuOptions">
          <div className="img-Logo">
            <img src={logo} className="logo" alt="logo" />
            <span
              onClick={() => this.toggleClass(document.body, "toggleOn")}
              className="icon-close"
            ></span>
          </div>
          <Scrollbars className="menuScroll">
            <ul className="innerMenu">
              <li className={activeClass == "Welcome" && "active"}>
                <a onClick={() => this.onSideItemClick("Welcome")}>Welcome</a>
              </li>
              <li className={activeClass == "Agreement" && "active"}>
                <a onClick={() => this.onSideItemClick("Agreement")}>
                  Agreement
                </a>
              </li>
              <li className={(this.state.isAgreementFilled) ? (activeClass == "Questionnaire" && "active") : "disabledCss"} >
                <a onClick={() => { this.onSideItemClick("Questionnaire") }} >
                  Questionnaire
                </a>
              </li>
              <li className={(this.state.isQuestionaryFilled) ? (activeClass == "AddPhoto" && "active") : "disabledCss"}>
                <a onClick={() => this.onSideItemClick("AddPhoto")}>
                  Add Photos
                </a>
              </li>
              <li className={this.state.isPhotosAdded || this.state.is5mmTouch ? (activeClass == "FreeWay" && "active") : "disabledCss"}>
                <a onClick={() => this.onSideItemClick("FreeWay")}>
                  Freeway Space
                </a>
              </li>

              <li className={(this.state.isPhotosAdded || this.state.is5mmTouch) ? activeClass == "5mmFittingStep1" && "active" : "disabledCss"}>
                <a onClick={() => this.onSideItemClick("5mmFittingStep1")}>
                  5mm Fittings</a>
              </li>

              <li className={(this.state.isPhotosAdded || this.state.is5mmTouch) ? activeClass == "TouchTest" && "active" : "disabledCss"}>
                <a onClick={() => this.onSideItemClick("TouchTest")}>
                  Touch Test Introduction</a>
              </li>
              <li className={(this.state.isPhotosAdded || this.state.is5mmTouch) ? activeClass == "5mmTouchTest" && "active" : "disabledCss"}>
                <a onClick={() => this.onSideItemClick("5mmTouchTest")}>5mm Touch Test</a>
              </li>

              <li className={(this.state.isPhotosAdded || this.state.is5mmTouch) ? activeClass == "3mmFittingStep1" && "active" : "disabledCss"}>
                <a onClick={() => this.onSideItemClick("3mmFittingStep1")}> 3mm Fittings</a>
              </li>

              <li className={(this.state.is5mmTouch || this.state.is3mmTouch) ? activeClass == "3mmTouchTest" && "active" : "disabledCss"} >
                <a onClick={() => this.onSideItemClick("3mmTouchTest")}>3mm Touch Test</a>
              </li>
              <li className={this.state.is3mmTouch ? activeClass == "SetPreferences" && "active" : "disabledCss"}>
                <a onClick={() => this.onSideItemClick("Setpreferences")}>Set Preferences</a>
              </li>
              <li className={this.state.is3mmTouch ? activeClass == "Congratulations" && "active" : "disabledCss"}>
                <a onClick={() => this.onSideItemClick("Congratulations")}>Complete</a>
              </li>
            </ul>
          </Scrollbars>
        </div>
      </div >
    );
  }
}
export default SideMenu;
