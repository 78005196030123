import React from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import './smilingface.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu'
import Button from '../../../components/Button/button'
import Header from '../../../components/header/header';
import Addphoto from '../../../assets/images/addPhoto.svg';
import { Row, Col } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import HomeSideMenu from '../../../components/homesidebar/homesidebar';
import WebService from "../../../utils/Webservice"
import getCroppedImg from '../../../utils/cropImage'
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import CONSTANTS from '../../../utils/constants';
import Webcam from "react-webcam";
import loaderImg from '../../../assets/images/loaderImg.svg';


class SmilingFace extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            imagePreviewUrl: '',
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 4 / 3,
            _id: localStorage.getItem("_id"),
            iPhotoId: localStorage.getItem("iPhotoId"),
            croppedAreaPixels: "",
            croppedImage: '',
            disableBtn: true,
            isViewFrom: this.props.history.location.state.isViewFrom,
            btnTitle: 'take photo',
            saveBtnDisable: false,
            webcamEnabled: true,
            successToast: false,
            isLoading: false

        }
        this.croppie = React.createRef();
        { this.state.isViewFrom === 'home' ? setLocalActiveClass("Photo") : setLocalActiveClass("AddPhoto") }
        this.updateRange = this.updateRange.bind(this);
    }
    componentDidMount() {
        let isConnected = false
        const self = this
        navigator.mediaDevices.getUserMedia({ video: true }).then(function (result) {
            isConnected = true
        }).catch(function (err) {
            self.setState({ btnTitle: 'upload photo' })
            console.log(err.name + ": " + err.message);
        });
    }


    onSaveAlignClick() {
        this.showCroppedImage()
    }
    onSkipClick() {
        this.props.history.push('/questionnaire')
    }
    _crop() {
        // image in dataUrl
        console.log(this.refs.cropper.getCroppedCanvas().toDataURL());
    }

    onCropChange = crop => {
        this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        // console.log(croppedArea, croppedAreaPixels)
        this.setState({ croppedAreaPixels, disableBtn: false })
    }

    onZoomChange = zoom => {
        this.setState({ zoom })
    }
    updateRange(event) {
        this.setState({ zoom: event.target.value })
    }
    _handleImageChange(e) {

        if (e.target.files.length <= 0) {
            return
        }
        let isValidImage = true
        var allowedExtension = ".jpg";
        var allowedExtensionPng = '.png'
        var allowedExtensionJpeg = '.jpeg'
        var hasInvalidFiles = false;

        var file = e.target.files[0];

        if (file.name.endsWith(allowedExtension) || file.name.endsWith(allowedExtensionPng) || file.name.endsWith(allowedExtensionJpeg)) {
            hasInvalidFiles = true;
        } else {
            hasInvalidFiles = false;
        }
        if (!hasInvalidFiles) {
            e.target.files.value = "";
            // alert("only JPG, JPEG, PNG  files are allowed.");
            this.setState({ toaster: true, message: CONSTANTS.PHOTOS.JPGALLOW, isShow: true, isWarning: true })
            return
        }

        const fsize = file.size;
        console.log(fsize, "fSize");

        const fileSize = Math.round((fsize / 1024));
        console.log(fileSize, "fSize");
        if (fileSize < 1024) {
            isValidImage = false
            // alert(fileSize);
            this.setState({ toaster: true, message: CONSTANTS.PHOTOS.FILE_SIZE, isShow: true, isWarning: true })
            return
        }
        var _URL = window.URL || window.webkitURL;

        var files, img;
        if ((files = file)) {
            img = new Image();
            img.onload = function () {
                if (this.height < 376 && this.width < 568) {
                    // alert(this.width + " " + this.height);
                    this.setState({ toaster: true, message: CONSTANTS.PHOTOS.FILE_SIZE, isShow: true, isWarning: true })
                    isValidImage = false
                    return
                }
            };
            img.src = _URL.createObjectURL(files);
        }

        //  console.log(img.height, "height");
        if (isValidImage === true) {
            let reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    file: file,
                    imagePreviewUrl: reader.result, btnTitle: 'done', saveBtnDisable: true
                });
            };
            reader.readAsDataURL(file);
        }

    }
    async showCroppedImage() {
        try {
            const croppedImage = await getCroppedImg(
                this.state.imagePreviewUrl,
                this.state.croppedAreaPixels,
            )
            this.setState({ croppedImage, saveBtnDisable: false })
        } catch (e) {
            console.error(e)
        }
    }
    async addSmilePhotoWebService() {
        this.setState({ isLoading: true, btnTitle: '' })

        try {
            const data = new FormData()
            data.append('iUserId', this.state._id)
            data.append('iPhotoId', this.state.iPhotoId)
            data.append('sSmilePhoto', this.state.croppedImage)

            let response = await WebService.uploadSmilePhoto(data);

            this.setState({ isLoading: false, btnTitle: 'done' });
            if (response.status) {
                console.log("response" + response.status);
                this.setState({ successToast: true, message: response.msg, isShow: true, isWarning: false })

                this.handleSuccessResponse(response);

            } else {
                this.setState({ successToast: true, errorMessage: response.msg, isShow: true, isWarning: true })

                console.log("response" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }
    handleSuccessResponse(response) {
        setTimeout(() => {
            { (this.state.isViewFrom === "addPhotoInfo" || this.state.isViewFrom === "home") ? this.props.history.push('/leftface', { isViewFrom: this.state.isViewFrom }) : this.props.history.push('/reviewphoto') }
        }, 3000)
    }
    setRef = webcam => {
        this.webcam = webcam;
    };
    capturePhoto() {
        const screenshot = this.webcam.getScreenshot();
        console.log(screenshot, "screenshot");

        this.setState({ webcamEnabled: !this.state.webcamEnabled, imagePreviewUrl: screenshot, btnTitle: 'done', saveBtnDisable: true });
    }

    render() {
        let { disableBtn, btnTitle, saveBtnDisable, successToast, isLoading, message, toaster, isShow, isWarning, imagePreviewUrl, webcamEnabled, crop, zoom, aspect } = this.state
        const videoConstraints = {
            width: 445,
            height: 548,
            facingMode: "user"
        };
        return (
            <div className='main-SmilingFace'>
                <Header></Header>
                {this.state.isViewFrom === "home" ? <HomeSideMenu {...this.props}></HomeSideMenu> : <SideMenu {...this.props}></SideMenu>}
                <div className='inner-SmilingFace'>
                    {successToast && <ToastComponent isWarning={isWarning} message={message} show={isShow} onClose={() => { this.setState({ successToast: false }); }} delay={3000} autohide ></ToastComponent>}

                    <Scrollbars className='upload-Scroll'>
                        <div className='main-Container'>
                            <div className='img-Container'>
                                <img src={Addphoto} className="logo" alt="logo" />
                            </div>
                            <h2 className='title-face paddingB15'>2 Smile : The smile should be same each time and widest possible
                            <div className={this.state.infoView ? 'divclass show ' : 'divclass'} onClick={() => this.setState({ infoView: !this.state.infoView })}></div>
                                <div className={this.state.infoView ? 'tool-Tips show' : 'tool-Tips'}>
                                    <a className="tip icon-info cursor" onClick={() => this.setState({ infoView: !this.state.infoView })}><span>It is important to line up your photograph as accurately as possible to the outline on the camera screen to ensure that your photos match as closely as possible when it comes to comparing them. It is important to align the pupils of the eyes as closely as possible. The shape of the face can change but the distance between the pupils will tend to remain the same.</span></a>
                                </div>
                            </h2>
                            <div className='content-Section'>
                                <h5 className='paddingB15'>Please upload a photo of your smiling face front on. Please align the photo with the face below. You can zoom in and out and drag your photo to get your aligned as close to the outline as possible. Once you are happy that your image is aligned press the “Save Aligned Button”</h5>
                            </div>
                            <Row className='custom-Ul'>
                                <Col lg={7} md={6} sm={9} className='frame-section'>
                                    <div className='photo-Plugin'>
                                        <div className='frame-Img'>
                                            {webcamEnabled && <Webcam
                                                audio={false}
                                                ref={this.setRef}
                                                className="videoimgFrame"
                                                screenshotFormat="image/jpeg"
                                                videoConstraints={videoConstraints}
                                            />}
                                            {imagePreviewUrl != '' ? <Cropper
                                                image={imagePreviewUrl}
                                                crop={crop}
                                                zoom={zoom}
                                                aspect={aspect}
                                                onCropChange={this.onCropChange}
                                                onCropComplete={this.onCropComplete}
                                                onZoomChange={this.onZoomChange}
                                            /> :

                                                <div className='frame-ImgSection'></div>
                                            }

                                        </div>
                                        {toaster && <ToastComponent isWarning={isWarning} message={message} show={isShow} onClose={() => { this.setState({ toaster: false }); }} delay={5000} autohide ></ToastComponent>}

                                    </div>
                                    <div className="cr-slider-wrap">
                                        <input className="cr-slider" type="range" value={this.state.zoom} min="1" max="3" step="0.1" onChange={this.updateRange}></input>

                                    </div>
                                    <div className='save-Button'>
                                        <Button title='save alignment' disabled={disableBtn} onClick={() => this.onSaveAlignClick()}></Button>
                                    </div>
                                </Col>
                                <Col lg={5} md={6} sm={12} className='content-li'>
                                    <div className='info-Section'>
                                        <p className='innerTitle'>
                                            <span className='warning-Icon'></span>   It is important to line your photo up as close as possible to the outline, to ensure that your photos are as matching when it comes to compare them. It is important to align the pupiles of the eye as closely as possible. The shape of the face can change but the distance between the pupils will tend to remain the same.
                                        </p>
                                    </div>
                                    <div className='info-Section'>
                                        <p className='innerTitle marginT10'>
                                            <span className='warning-Icon'></span>
                                            Photographs should be standardised with the same lighting and no shadows. It is best to avoid standing near a window as outside light can affect the picture. Try to keep the picture the same size and keep the same settings on the camera. Ideally, the hair should be make up free and the hair tied back with a band.
                            </p>
                                    </div>
                                    <div className='upload-Button'>
                                        <Button disabled={saveBtnDisable} onClick={btnTitle === 'done' ? this.addSmilePhotoWebService.bind(this) : this.capturePhoto.bind(this)} title={btnTitle} >{isLoading && <img className="loaderImg" src={loaderImg} />}</Button>
                                        {btnTitle === 'done' || btnTitle === 'take photo' ? '' :
                                            <input
                                                className="fileInput"
                                                type="file"
                                                accept="image/*"
                                                onChange={e => { this._handleImageChange(e); e.target.value = null }}
                                            />
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Scrollbars>
                </div>

            </div>
        )
    }
}




export default SmilingFace;

