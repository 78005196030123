import React from 'react';
import { Form } from 'react-bootstrap';
import './textfield.scss';

class TextField extends React.Component {
    constructor(props) {

        super(props)
        this.state = {

        }
    }

    render() {
        let { placeholder, title, value, type, iconClass, name, maxLength, errorMessage, pattern, readOnly, autoFocus } = this.props
        return (
            <Form.Group controlId="formBasicEmail" className='textFiled'>
                {errorMessage && <span className='error-msg'>{errorMessage}</span>}
                {!errorMessage && <Form.Label>{title}</Form.Label>}

                <Form.Control autoFocus={autoFocus} readOnly={readOnly} value={value} type={type} pattern={pattern} require={require} maxLength={maxLength} onKeyDown={this.props.onKeyDown} onChange={this.props.onChange} name={name} placeholder={placeholder} />
                <span className={"icon-custom " + iconClass} onClick={this.props.onClick}></span>
            </Form.Group>

        )
    }
}




export default TextField;

