import React, { useState } from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import './resetpassword.scss';
import { Scrollbars } from "react-custom-scrollbars";
import Button from "../../../components/Button/button";
import TextField from '../../../components/textfield/textfield';
import CONSTANTS from '../../../utils/constants';
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import WebService from "../../../utils/Webservice"

class ResetPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loginForm: true,
            forgotForm: false,
            // email: {
            //     value: "",
            //     isValid: false,
            //     message: ""
            // },
            password: {
                value: "",
                isValid: false,
                message: ""
            },
            confirmPassword: {
                value: "",
                isValid: false,
                message: ""
            },
            errorMessage: '',
            isToastShow: false,
            showPass: false,
            _id: ''
        }


    }

    componentDidMount() {

        this.checkResetPasswordWebServices()
    }

    async checkResetPasswordWebServices() {
        const { token } = this.props.match.params
        // console.log(token);
        try {
            let obj = {
                resetToken: token
                // nVerificationCode: this.state.verificationCode,
            }
            let response = await WebService.resetPasswordLink(obj);
            // console.log(JSON.stringify(obj));

            this.setState({ isLoading: false });

            if (response.status) {
                //  this.setState({ toaster: true, errorMessage: response.MESSAGES, isToastShow: true, isWarning: false })
                //  this.handleSuccessResponse(response.data);

                this.setState({ _id: response.data._UserId })
                // console.log("SUCCESS::" + response.status);
            } else {
                // this.setState({ toaster: true, message: response.MESSAGES, isShow: true, isWarning: false })
                this.setState({ toaster: true, errorMessage: response.MESSAGES, isToastShow: true, isWarning: true })
                // console.log("Error::" + response.status);
            }
        } catch (error) {
            // console.log(error);
        }
    }

    onSubmit() {
        // console.log(this.state.username)
        //this.validateFields('email', this.state.email.value)
        this.validateFields('password', this.state.password.value)
        this.validateFields('confirmPassword', this.state.confirmPassword.value)

        this.resetPasswordWebServices()
    }
    async resetPasswordWebServices() {
        let { _id } = this.state
        try {
            let obj = {
                _userId: _id,
                sPassword: this.state.confirmPassword.value
                // nVerificationCode: this.state.verificationCode,
            }
            let response = await WebService.adminResetPassword(obj);
            //   console.log(JSON.stringify(obj));

            this.setState({ isLoading: false });

            if (response.status) {
                this.setState({ toaster: true, errorMessage: response.MESSAGES, isToastShow: true, isWarning: false })
                setTimeout(() => {
                    this.props.history.push("/admin");
                }, 3000)
                // console.log("SUCCESS::" + response.status);
            } else {
                this.setState({ toaster: true, errorMessage: response.MESSAGES, isToastShow: true, isWarning: true })
                // console.log("Error::" + response.status);
            }
        } catch (error) {
            // console.log(error);
        }
    }
    // handleSuccessResponse(response) {

    // }

    handleUserInput = e => {
        let name = e.target.name;
        let value = e.target.value;

        var obj = {};
        obj[name] = {
            value: value
        };
        this.setState({
            showEye: name == "password" && value != "" ? true : false
        });

        this.setState(obj, () => {
            this.validateField(name, value);
        });
    };
    // text on change //
    validateField(fieldName, value) {
        let valid = false;
        let message = "";

        switch (fieldName) {
            // case "email":
            //     valid = value.match(CONSTANTS.REGEX.EMAIL) && value.length >= 0;
            //     !valid &&
            //         (message =
            //             value.length <= 0
            //                 ? CONSTANTS.MESSAGES.VALIDATION.EMAIL_LENGTH
            //                 : CONSTANTS.MESSAGES.VALIDATION.EMAIL);
            //     break;
            case "password":
                valid = value.length > 0;

                !valid && (message = CONSTANTS.MESSAGES.VALIDATION.PASSWORD);
                break;
            case "confirmPassword":
                valid = this.state.password.value == value;

                !valid && (message = CONSTANTS.MESSAGES.VALIDATION.CONFIRM_PASSWORD);
                break;
        }
        var obj = {};
        obj[fieldName] = {
            message: message,
            isValid: valid,
            value: value
        };
        this.setState(obj, () => {
            fieldName == "forgetEmail"
                ? this.validateForgotForm()
                : this.validateForm();
        });
    }
    // validation on Button//
    validateFields(fieldName, value) {
        let valid = false;
        let message = "";

        switch (fieldName) {
            // case "email":
            //     valid = value.match(CONSTANTS.REGEX.EMAIL) && value.length >= 0;
            //     !valid &&
            //         (message =
            //             value.length <= 0
            //                 ? CONSTANTS.MESSAGES.VALIDATION.EMAIL_LENGTH
            //                 : CONSTANTS.MESSAGES.VALIDATION.EMAIL);
            //     break;
            case "password":
                valid = value.length > 7 && value.match(CONSTANTS.REGEX.SPECIAL_CHARECTERS) && value.match(CONSTANTS.REGEX.NUMBER)
                    && value.match(CONSTANTS.REGEX.CAPITAL) && value.match(CONSTANTS.REGEX.LOWERCASE);

                !valid &&
                    (message =
                        value.length <= 0
                            ? CONSTANTS.MESSAGES.VALIDATION.PASSWORD
                            : CONSTANTS.MESSAGES.VALIDATION.INVALID_PASSWORD);
                break;

        }
        var obj = {};
        // obj[fieldName] = {
        //     message: message,
        //     isValid: valid,
        //     value: value
        // };
        // toast message show //
        if (!valid) {
            this.setState({
                isToastShow: true,
                errorMessage: message
            })
        }

        this.setState(obj, () => {
            fieldName == "forgetEmail"
                ? this.validateForgotForm()
                : this.validateForm();
        });
    }
    validateForm() {
        this.setState({
            isFormValid: this.state.password.isValid && this.state.confirmPassword.isValid
        });
    }

    //*****************************Forgot Password Validation*******************
    validateForgotForm() {
        this.setState({
            isForgotValid: this.state.forgetEmail.isValid
        });
    }
    onForgotPassword = () => {
        this.setState({
            loginForm: false,
            forgotForm: true,
            showTopBar: false,
            animated: true
        });
    };
    render() {
        let { disabled, toaster, isToastShow, errorMessage, isWarning } = this.state
        return (
            <div className='main-Resetpassword'>
                {toaster && <ToastComponent isWarning={isWarning} message={errorMessage} show={isToastShow} onClose={() => { this.setState({ toaster: false }); }} delay={3000} autohide ></ToastComponent>}

                {/* <ToastComponent message={this.state.errorMessage} show={this.state.isToastShow}></ToastComponent> */}
                <div className='inner-AdminLogin'>
                    <Scrollbars className='home-Scroll'>
                        <div className='main-Container'>
                            <div className='img-upload'>
                                <img src={require('../../../assets/images/resetpassword.png')} className='profileimg' />
                            </div>
                            <h4>Reset Password</h4>
                            <h5>Please provide below mentioned details to reset your password. </h5>
                            <div className='form'>
                                {/* <TextField title="Email"
                                    value={this.state.email.value}
                                    name="email"
                                    iconClass={"icon-mail"}
                                    onChange={this.handleUserInput.bind(this)}
                                    errorMessage={this.state.email.message}
                                    placeholder="johndoe@gmail.com" ></TextField> */}
                                <TextField title="Password" value={this.state.password.value}
                                    name="password"
                                    type={this.state.showPass ? "text" : "password"}
                                    // iconClass={this.state.showPass
                                    //     ? "icon-eye"
                                    //     : "icon-eyeoff"
                                    // }
                                    iconClass={
                                        this.state.showEye
                                            ? this.state.showPass
                                                ? "icon-eye"
                                                : "icon-eyeoff"
                                            : "icon-eyeoff"
                                    }
                                    onClick={() =>
                                        this.setState({ showPass: !this.state.showPass })
                                    }
                                    onChange={this.handleUserInput.bind(this)}
                                    errorMessage={this.state.password.message}
                                    placeholder="******" >
                                </TextField>
                                <TextField title="Confirm Password" 
                                    value={this.state.confirmPassword.value}
                                    name="confirmPassword"
                                    type={this.state.showPass ? "text" : "password"}
                                    // iconClass={this.state.showPass
                                    //     ? "icon-eye"
                                    //     : "icon-eyeoff"
                                    // }
                                    iconClass={
                                        this.state.showEye
                                            ? this.state.showPass
                                                ? "icon-eye"
                                                : "icon-eyeoff"
                                            : "icon-eyeoff"
                                    }
                                    onClick={() =>
                                        this.setState({ showPass: !this.state.showPass })
                                    }
                                    onChange={this.handleUserInput.bind(this)}
                                    errorMessage={this.state.confirmPassword.message}
                                    placeholder="******" >
                                </TextField>
                                <Button title="Reset Password"
                                    disabled={!this.state.isFormValid} onClick={() => this.onSubmit()}></Button>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        )
    }
}




export default ResetPassword;

