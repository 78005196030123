import React from 'react';
import { setLocalActiveClass, setStoredData } from "../../../utils/helper";
import HomeSideMenu from '../../../components/homesidebar/homesidebar'
import './5mmreportdetail.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu'
import { Row, Col } from 'react-bootstrap';
import Header from '../../../components/header/header';
import Button from '../../../components/Button/button'
import ReportImg from '../../../assets/images/warning.svg';
import DropdownMenu from '../../../components/dropdown/dropdown'
import WebService from "../../../utils/Webservice"
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import { el } from 'date-fns/esm/locale';

const options = ['No', 'Yes']
const options1 = ['Muscles trembling', 'Pain on facial muscles', 'Starting a headache',]
class reportdetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {



            //isDataFrom: this.props.history.location.state.isViewFrom,
            isDevicesType: this.props.history.location.state.isDevicesType
        }

        setLocalActiveClass("Home")
        console.log(this.state.isViewFrom);
    }


    onSubmitClick() {
        this.props.history.push('/3mmhome', { isViewFrom: "home", isDevicesType: this.state.isDevicesType })
        setStoredData("touchTestId", '')
        setStoredData('isShowPopup', true)
    }


    render() {
        let { isPain, isDataFrom, message, isShow, toaster } = this.state
        return (
            <div className='main-reportdetail'>
                <Header></Header>
                <HomeSideMenu {...this.props}></HomeSideMenu>

                <div className='inner-TestReport5mm'>
                    <Scrollbars className='testreport-Scroll'>
                        <div className='main-Container'>


                            <div className='img-Container'>
                                <img src={ReportImg} className="logo" alt="logo" />
                            </div>
                            <h2 className='paddingB32'>Touch Test-Report Pain or Discomfort</h2>



                            <div className='only-Content' >
                                <p className='contents'>
                                    If you had no pain with either device, your starting point after two days of rest will be 15 minutes with the 3mm device morning and evening.
                                </p>
                                <p className='contents'>
                                    If you suffered any pain with either device, you should start with the time you were able to wear the 3mm device without any pain .
                                </p>
                            </div>
                            <Button title='Next' onClick={() => this.onSubmitClick()}></Button>


                        </div>
                    </Scrollbars>
                </div>
            </div >

        )
    }
}




export default reportdetail;

