import React from 'react';
import { setLocalActiveClass, setFilledFlags } from "../../../utils/helper";
import './signofoveruse.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu';

import Overuse from '../../../assets/images/overuse.svg';
import { getFormDataObject, getCookie, checkForHistoryPushParam } from '../../../utils/helper';
import HomeSideMenu from '../../../components/homesidebar/homesidebar';
import HomeHeader from '../../../components/homeheader/homeheader';




class Signofoveruse extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {

        let { isViewFrom } = this.state

        return (
            <div className='overusemain'>
                <HomeSideMenu {...this.props}></HomeSideMenu>
                <HomeHeader {...this.props}></HomeHeader>
                <div className='agreementPage'>
                    <Scrollbars>
                        <div className='main-Container'>
                            <div className='img-Container'>
                                <img src={Overuse} className="logo" alt="logo" />
                            </div>
                            <h1 className='paddingB15'>What Are Signs of Overuse?</h1>
                            <div className='content-Section'>
                                <h5 className='paddingB15'>When you experience pain, discomfort or trembling of the muscles, this is an indication that your muscles are tensed and are having difficulty adapting your lower jaw to a new position. The time when you had to stop your test is used to set your programme. In your case, you’ll have to proceed more slowly than a person whose muscles are able to adapt very quickly. Not everyone can run a mile if they have never run before. Some people will be exhausted after only 100 yards! Oralift is a lifetime programme and you should not try to proceed too quickly. This does not mean that you will not get good results. In fact, the opposite may be the case. Tense muscles are usually the indication of stress, which can lead to clenching and grinding of teeth, which can cause premature ageing.</h5>
                            </div>


                        </div>
                    </Scrollbars>
                </div>
            </div>

        )
    }
}




export default Signofoveruse;

