import React, { useState } from "react";
import {
  setLocalActiveClass,
  setStoredData,
  getStoredData,
} from "../../../utils/helper";
import "./adminprofile.scss";
import { Scrollbars } from "react-custom-scrollbars";
import AdminHeader from "../../../components/adminheader/adminheader";
import Button from "../../../components/Button/button";
import TextField from "../../../components/textfield/textfield";
import CONSTANTS from "../../../utils/constants";

import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import { getFormDataObject } from "../../../utils/helper";
import WebService from "../../../utils/Webservice";
class AdminProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: "false",
      toaster: false,
      fileToaster: false,

      loginForm: true,
      isWarning: false,
      _id: getStoredData("adminId"),
      imagePreviewUrl: getStoredData("adminProfile"),
      email: {
        value: "",
        isValid: false,
        message: "",
      },
      sFullName: {
        value: "",
        isValid: false,
        message: "",
      },
      sPhoneNumber: {
        value: "",
        isValid: false,
        message: "",
      },
      errorMessage: "",
      isToastShow: false,
      showPass: false,
      fileStatus: getStoredData("adminProfile") === "undefined" ? false : true,
      sucessData: false,
      message: "",
    };
  }
  componentDidMount() {
    this.getUserWebservice();
  }
  async getUserWebservice() {
    try {
      let obj = {
        _id: this.state._id,
      };
      let response = await WebService.getUserDetails(obj);
      this.setState({ isLoading: false });
      if (response.status) {
        // console.log("Success::" + response.status);
        this.handleSuccessProfile(response);
      } else {
        this.handleErrorResponse(response);
        // console.log("Error::" + response.status);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  handleSuccessProfile(response) {
    let {
      sUserFirstName,
      sUserLastName,
      sPhoneNumber,
      sEmailId,
      sProfilePhoto,
    } = response.data;
    let fullName = this.state.sFullName;
    fullName.value = sUserFirstName + " " + sUserLastName;
    fullName.isValid = true;
    fullName.message = "";
    let phoneNumber = this.state.sPhoneNumber;
    phoneNumber.value = sPhoneNumber;
    phoneNumber.isValid = true;
    phoneNumber.message = "";
    let emailId = this.state.email;
    emailId.value = sEmailId;
    emailId.isValid = true;
    emailId.message = "";

    this.setState({
      sFullName: fullName,
      sPhoneNumber: phoneNumber,
      email: emailId,
      imagePreviewUrl: sProfilePhoto,
    });
  }

  onEdit() {
    this.setState({
      isEdit: "true",
      isFormValid:
        this.state.email.isValid &&
        this.state.sPhoneNumber.isValid &&
        this.state.sFullName.isValid &&
        this.state.fileStatus,
    });
  }
  refreshPage() {
    window.location.reload(false);
  }
  onSubmit() {
    this.validateFields("email", this.state.email.value);
    this.validateFields("sFullName", this.state.email.value);
    this.validateFields("sPhoneNumber", this.state.email.value);
    this.adminProfileEditWebServices();
  }
  onKeyDown(e) {
    if (this.state.isFormValid === true) {
      e.keyCode == 13 && this.adminProfileEditWebServices();
    }
  }
  handleUserInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    var obj = {};
    obj[name] = {
      value: value,
    };
    this.setState(obj, () => {
      this.validateField(name, value);
    });
  };
  // text on change //
  validateField(fieldName, value) {
    let valid = false;
    let message = "";
    switch (fieldName) {
      case "email":
        // debugger
        valid = value.match(CONSTANTS.REGEX.EMAIL) && value.length >= 0;
        !valid &&
          (message =
            value.length <= 0
              ? CONSTANTS.MESSAGES.VALIDATION.EMAIL_LENGTH
              : CONSTANTS.MESSAGES.VALIDATION.EMAIL);
        break;
      case "sFullName":
        valid = value.length > 0;
        !valid && (message = CONSTANTS.MESSAGES.VALIDATION.FULNAME);
        break;
      case "sPhoneNumber":
        valid = value.match(CONSTANTS.REGEX.PHONE_NUMBER) && value.length >= 0;
        !valid &&
          (message =
            value.length <= 0
              ? CONSTANTS.MESSAGES.VALIDATION.PHONE_REQ
              : CONSTANTS.MESSAGES.VALIDATION.PHONE);
        break;
      default:
        break;
    }
    var obj = {};
    obj[fieldName] = {
      message: message,
      isValid: valid,
      value: value,
    };
    this.setState(obj, () => {
      this.validateForm();
    });
  }
  // validation on Button//
  validateFields(fieldName, value) {
    let valid = false;
    let message = "";

    switch (fieldName) {
      case "email":
        valid = value.match(CONSTANTS.REGEX.EMAIL) && value.length >= 0;
        !valid &&
          (message =
            value.length <= 0
              ? CONSTANTS.MESSAGES.VALIDATION.EMAIL_LENGTH
              : CONSTANTS.MESSAGES.VALIDATION.EMAIL);
        break;
      case "sFullName":
        valid = value.length > 0;
        !valid && (message = CONSTANTS.MESSAGES.VALIDATION.FULNAME);
        break;
      case "sPhoneNumber":
        valid = value.match(CONSTANTS.REGEX.PHONE_NUMBER) && value.length >= 0;
        !valid &&
          (message =
            value.length <= 0
              ? CONSTANTS.MESSAGES.VALIDATION.PHONE_REQ
              : CONSTANTS.MESSAGES.VALIDATION.PHONE);
        break;

      default:
        break;
    }
    var obj = {};

    // toast message show //
    if (!valid) {
      this.setState({
        isToastShow: true,
        errorMessage: message,
      });
    }
    this.setState(obj, () => {
      this.validateForm();
    });
  }
  validateForm() {
    this.setState({
      isFormValid:
        this.state.email.isValid &&
        this.state.sPhoneNumber.isValid &&
        this.state.sFullName.isValid &&
        this.state.fileStatus,
    });
  }
  _handleImageChange(e) {
    if (e.target.files.length <= 0) {
      return;
    }
    var allowedExtension = ".jpg";
    var allowedExtensionPng = ".png";
    var allowedExtensionJpeg = ".jpeg";
    var hasInvalidFiles = false;
    var file = e.target.files[0];
    if (
      file.name.endsWith(allowedExtension) ||
      file.name.endsWith(allowedExtensionPng) ||
      file.name.endsWith(allowedExtensionJpeg)
    ) {
      hasInvalidFiles = true;
    } else {
      hasInvalidFiles = false;
    }
    if (!hasInvalidFiles) {
      e.target.files.value = "";
      this.setState({
        fileToaster: true,
        message: CONSTANTS.PHOTOS.JPGALLOW,
        isToastShow: true,
        isWarning: true,
      });
      return;
    }
    const fsize = file.size;
    const fileSize = Math.round(fsize / 1024);
    let index = e.target.files.length;
    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState(
        {
          fileStatus:
            this.state.imagePreviewUrl !== "" && index !== 0 ? true : false,
          file: file,
          imagePreviewUrl: reader.result,
        },
        () => {
          this.validateForm();
        }
      );
    };
    reader.readAsDataURL(file);
  }

  async adminProfileEditWebServices() {
    let fullName = this.state.sFullName.value.includes(" ")
      ? this.state.sFullName.value.split(" ")
      : this.state.sFullName.value;
    try {
      let obj = {
        _id: this.state._id,
        sUserFirstName:
          typeof fullName === "string"
            ? fullName
            : fullName[0] === undefined
            ? fullName
            : fullName[0],
        sUserLastName:
          typeof fullName === "string"
            ? ""
            : fullName[1] === undefined
            ? fullName
            : fullName[1],
        sEmailId: this.state.email.value,
        sPhoneNumber: this.state.sPhoneNumber.value,
        sProfilePhoto: this.state.file,
      };
      let data = getFormDataObject(obj);
      let response = await WebService.adminProfileUpdate(data);
      this.setState({ isLoading: false });
      if (response.status) {
        this.setState({
          toaster: true,
          errorMessage: response.MESSAGES,
          isToastShow: true,
          isWarning: false,
        });
        this.handleSuccessResponse(response.data);
        //console.log("SUCCESS::" + response.status);
      } else {
        this.setState({
          toaster: true,
          errorMessage: response.MESSAGES,
          isToastShow: true,
          isWarning: true,
        });
        //console.log("Error::" + response.status);
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleSuccessResponse(response) {
    setStoredData("adminId", response._id);
    setStoredData("adminEmailId", response.sEmailId);
    setStoredData("adminPhoneNumber", response.sPhoneNumber);
    setStoredData("adminFirstName", response.sUserFirstName);
    setStoredData("adminLastName", response.sUserLastName);
    setStoredData("adminProfile", response.sProfilePhoto);
    setTimeout(() => {
      this.setState({
        isEdit: "false",
      });
      window.location.reload(false);
    }, 3000);
  }
  render() {
    let {
      profession,
      treatment,
      adminEmail,
      adminPhoneNumber,
      fileToaster,
      sucessData,
      adminFirstName,
      adminLastName,
      errorMessage,
      isToastShow,
      file,
      imagePreviewUrl,
      toaster,
      message,
      isShow,
      isWarning,
    } = this.state;
    return (
      <div className="main-AdminProfile">
        <AdminHeader {...this.props}></AdminHeader>
        <div className="inner-Profile">
          <Scrollbars className="home-Scroll">
            {toaster && (
              <ToastComponent
                isWarning={isWarning}
                message={errorMessage}
                show={isToastShow}
                onClose={() => {
                  this.setState({ toaster: false });
                }}
                delay={3000}
                autohide
              ></ToastComponent>
            )}
            {fileToaster && (
              <ToastComponent
                isWarning={isWarning}
                message={message}
                show={isToastShow}
                onClose={() => {
                  this.setState({ fileToaster: false });
                }}
                delay={5000}
                autohide
              ></ToastComponent>
            )}
            <div className="main-Container">
              <div className="img-upload">
                <div className="profile-img">
                  <img
                    onChange={(e) => {
                      this._handleImageChange(e);
                      e.target.value = null;
                    }}
                    src={
                      imagePreviewUrl === undefined
                        ? require("../../../assets/images/profileadmin.png")
                        : imagePreviewUrl
                    }
                    className="profileimg"
                  />
                </div>
                {this.state.isEdit !== "false" && (
                  <div class="img-edit">
                    <label for="file-input">
                      <span className="icon-camera cameraclass" />
                    </label>
                    <input
                      id="file-input"
                      accept="image/*"
                      type="file"
                      onChange={(e) => {
                        this._handleImageChange(e);
                        e.target.value = null;
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="form">
                <TextField
                  readOnly={this.state.isEdit == "false" ? "readOnly" : false}
                  title="Full Name"
                  name="sFullName"
                  autoFocus={this.state.isEdit === "true" && true}
                  placeholder={this.state.sFullName.value}
                  errorMessage={this.state.sFullName.message}
                  onChange={this.handleUserInput.bind(this)}
                  value={
                    this.state.isEdit == "false"
                      ? ""
                      : this.state.sFullName.value
                  }
                ></TextField>
                <TextField
                  readOnly={this.state.isEdit == "false" ? "readOnly" : false}
                  title="Email"
                  name="email"
                  errorMessage={this.state.email.message}
                  placeholder={this.state.email.value}
                  onChange={this.handleUserInput.bind(this)}
                  value={
                    this.state.isEdit == "false" ? "" : this.state.email.value
                  }
                ></TextField>
                <TextField
                  readOnly={this.state.isEdit == "false" ? "readOnly" : false}
                  title="Phone"
                  name="sPhoneNumber"
                  errorMessage={this.state.sPhoneNumber.message}
                  onChange={this.handleUserInput.bind(this)}
                  placeholder={this.state.sPhoneNumber.value}
                  value={
                    this.state.isEdit == "false"
                      ? ""
                      : this.state.sPhoneNumber.value
                  }
                  onKeyDown={this.onKeyDown.bind(this)}
                ></TextField>
              </div>
              {this.state.isEdit == "false" ? (
                <a
                  onClick={() => {
                    this.onEdit();
                  }}
                  className="aLink"
                >
                  Edit
                </a>
              ) : (
                <Button
                  title="save"
                  disabled={!this.state.isFormValid}
                  onClick={() => this.onSubmit()}
                ></Button>
              )}
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default AdminProfile;
