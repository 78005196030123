import React, { useState } from 'react';
import { setLocalActiveClass, getStoredData, setStoredData } from "../../utils/helper";
import './generateVerificationCode.scss';
import { Scrollbars } from "react-custom-scrollbars";
import HomeSideMenu from '../../components/homesidebar/homesidebar'
import HomeHeader from '../../components/homeheader/homeheader';
import { Row, Col } from 'react-bootstrap';
import TextField from '../../components/textfield/textfield';
import Button from "../../components/Button/button";
import CONSTANTS from '../../utils/constants';
import ToastComponent from "../../components/toastcomponent/toastcomponent";
import { getFormDataObject } from '../../utils/helper';
import WebService from "../../utils/Webservice"
import AdminSideMenu from '../../components/adminsidebar/adminsidebar';
import AdminHeader from '../../components/adminheader/adminheader';
import { throwStatement } from '@babel/types';
import { CSVLink } from "react-csv";
import loaderImg from '../../assets/images/loaderImg.svg';
// import { async } from 'q';
class generateVerificationCode extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errorMessage: '',
            isToastShow: false,
            toaster: false,
            showPass: false,
            sucessData: false,
            message: '',
            vCode: '',
            ArrayOfArray: [],
            vCodeArray: [],
            disabled: true, isWarning: false, isLoading: false
        }
    }

    nubOfGenerateCode = (e) => {
        let disabled = e.target.value.length > 0 ? false : true
        this.setState({ vCode: e.target.value, disabled })

        // (event.target.value.length > 0 ? this.setState({ disabled: false }) : this.setState({ disabled: true }))
        // this.setState({ vCode: event.target.value })
    }
    generateVerificationCodeApi = async () => {
        this.setState({ isLoading: true })
        let ArrayOfArray = []
        let { vCode, vCodeArray } = this.state

        try {
            let obj = { nubOfCode: vCode };
            let response = await WebService.generateCode(obj);
            this.setState({ isLoading: false });
            if (response.status) {
                let storedData = await WebService.storedVerificationCode({ code: response.data })
                response.data.map((item) => {
                    ArrayOfArray.push([item])
                })
                this.setState({ toaster: true, errorMessage: response.MESSAGES, isToastShow: true, isWarning: false, ArrayOfArray, vCode: '', vCodeArray: response.data, disabled: true })
            } else {
                this.setState({ toaster: true, errorMessage: response.MESSAGES, isToastShow: true, isWarning: true })
                console.log("Error::" + response.status);
            }
        } catch (error) {
            console.log(error);
        }


    }
    onKeyDown(e) {
        e.keyCode == 13 && this.generateVerificationCodeApi()
    }
    render() {
        let { errorMessage, toaster, disabled, vCode, ArrayOfArray, vCodeArray, isLoading } = this.state

        return (
            <div className='main-GenerateCode'>
                <AdminHeader   {...this.props} menuIcon={false}></AdminHeader>
                {toaster && <ToastComponent isWarning={false} message={errorMessage} show={true} onClose={() => { this.setState({ toaster: false }); }} delay={3000} autohide ></ToastComponent>}
                <div className='inner-Profile'>
                    <Scrollbars className='home-Scroll'>
                        <div className='main-Container'>
                            <div className='generateCode'>
                                <h2 className='paddingB15'>Generate Code</h2>
                                <div className='form'>

                                    <TextField
                                        title="Generate access code"
                                        name="vCode"
                                        type="Number"
                                        onChange={this.nubOfGenerateCode}
                                        onKeyDown={this.onKeyDown.bind(this)}
                                        value={vCode}
                                    ></TextField>
                                    <Button loading={isLoading} disabled={disabled} title={'Generate'}
                                        onClick={this.generateVerificationCodeApi}
                                    >  </Button>
                                </div>

                            </div>
                            <div className='codeResult'>
                                <CSVLink
                                    data={ArrayOfArray}
                                    className='downloadBtn'
                                    filename={"OraliftVerificationCode.csv"}
                                >
                                    <Button disabled={vCodeArray.length > 0 ? false : true}> Download me </Button>
                                </CSVLink>
                                <ul className='codeUl'>
                                    {vCodeArray.length > 0 && (
                                        vCodeArray.map((item) => {
                                            return (<li><div className='resultTxt'><p>{item}</p></div></li>)
                                        }))}
                                </ul>

                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </div >
        )
    }
}




export default generateVerificationCode;


