import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import Login from '../src/pages/onboarding/login/login'
import Welcome from '../src/pages/onboarding/welcome/welcome';
import Agreement from '../src/pages/onboarding/agreement/agreement'
import Questionnaire from '../src/pages/onboarding/questionnaire/questionnaire';
import QuestionnaireStep2 from '../src/pages/onboarding/questionnairestep2/questionnairestep2';
import AddPhotoGuide from '../src/pages/onboarding/addphotoguide/addphotoguide';
import AddPhotoInfo from '../src/pages/onboarding/addphotoinfo/addphotoinfo';
import FrontFace from '../src/pages/onboarding/frontface/frontface';
import SmilingFace from '../src/pages/onboarding/smilingface/smilingface';
import LeftFace from '../src/pages/onboarding/leftface/leftface';
import RightFace from '../src/pages/onboarding/rightface/rightface';
import LeftBody from '../src/pages/onboarding/leftbody/leftbody';
import RightBody from '../src/pages/onboarding/rightbody/rightbody';
import ReviewPhoto from '../src/pages/onboarding/reviewphoto/reviewphoto';
import FreewaySpace from '../src/pages/onboarding/freewayspace/freewayspace';
import FivemmFittingStep1 from '../src/pages/onboarding/5mmfittingstep1/5mmfittingstep1';
import FivemmFittingStep2 from '../src/pages/onboarding/5mmfittingstep2/5mmfittingstep2';
import FivemmFittingStep3 from '../src/pages/onboarding/5mmfittingstep3/5mmfittingstep3';
import TouchTest from '../src/pages/onboarding/touchtest/touchtest';
import FiveMMTouchTest from '../src/pages/onboarding/5mmtouchtest/5mmtouchtest';
import ThreemmFittingStep1 from '../src/pages/onboarding/5mmfittingstep1/5mmfittingstep1';
import ThreemmFittingStep2 from '../src/pages/onboarding/5mmfittingstep2/5mmfittingstep2';
import ThreemmFittingStep3 from '../src/pages/onboarding/5mmfittingstep3/5mmfittingstep3';
import ThreeMMTouchTest from '../src/pages/onboarding/5mmtouchtest/5mmtouchtest';
import TestReport5mm from '../src/pages/onboarding/5mmtestreport/5mmtestreport';
import TestReport3mm from '../src/pages/onboarding/5mmtestreport/5mmtestreport';
import ManualTouchTest5mm from '../src/pages/onboarding/manualtouchtest5mm/manualtouchtest5mm';
import ThankYou from '../src/pages/onboarding/thankyou/thankyou';
import SimpleSteps from '../src/pages/onboarding/5simplesteps/5simplesteps';
import SetPreferences from '../src/pages/onboarding/setpreferences/setpreferences';
import SavePreferences from '../src/pages/onboarding/savepreferences/savepreferences';
import Congratulations from '../src/pages/onboarding/congratulations/congratulations';
import startingPoint from './pages/startingPoint/startingPoint'
import CompleteStep from '../src/pages/onboarding/completeStep/completeStep';
// import FiveMMHome from './pages/home/5mmhome/5mmhome';
import SetReminder from './pages/home/setreminder/setreminder';
import QuestionBiweekly from './pages/home/questionbiweekly/questionbiweekly';
import Photos from './pages/photos/photos/photos';
import Homeprogress from './pages/homeprogress/progress/progress';
import TouchTestReport from '../src/pages/onboarding/5mmtestreport/5mmtestreport'
import TouchReportDetail from '../src/pages/onboarding/5mmreportdetail/5mmreportdetail'
// import Instructions from './pages/homeInstructions/Instructions/instructions';
import ThreeMMGuide from './pages/homeInstructions/3mmguide/3mmguide';
import FiveMMGuide from './pages/homeInstructions/5mmguide/5mmguide';
import FreeWaySpaceGuide from './pages/homeInstructions/freewayspace/freewayspaceguide';
import TouchIntroduction from './pages/homeInstructions/touchtestintroduction/touchtestintroduction';
import Profile from './pages/homeprofile/profile/profile';
import Manualentry from './pages/homeprofile/manualentry/manualentry';
import AdminLogin from './pages/admin/login/adminlogin';
import UserLogin from './pages/admin/login/userLogin';
import ForgotPassword from './pages/admin/forgotpassword/forgotpassword';
import UserForgotPassword from './pages/admin/forgotpassword/userforgotpassword';
import userSetPassword from './pages/admin/userSetPassword/userSetPassword';
import ResetPassword from './pages/admin/resetpassword/resetpassword';
import AdminDashboard from './pages/admin/dashboard/admindashboard';
import UserDetails from './pages/admin/userdetails/userdetails';
import AdminProfile from './pages/admin/adminprofile/adminprofile';
import AdminChangePassword from './pages/admin/adminchangepwd/adminchangepwd';
import Signofoveruse from '../src/pages/onboarding/signofoveruse/signofoveruse';
import Criteriachange from '../src/pages/onboarding/criteriachange/criteriachange';
import SimpleStepsAdd from '../src/pages/onboarding/5simplestepsAdd/5simplestepsAdd';
import Feedback from './pages/home/feedback/feedback';
import viewQuestionnaireBiweekly from "./pages/home/view-Questionnaire-Biweekly/viewQuestionnaireBiweekly";
import { messaging } from "./init-fcm";
import * as firebase from "firebase/app";
import FreewaySpaceView from './pages/freewaySpaceView/freewaySpaceView';
import ViewReport from './pages/ViewReport/ViewReport';
import generateVerificationCode from './pages/generateVerificationCode/generateVerificationCode';


class App extends Component {
  routes = [
    {
      path: '/',
      component: Login,
      exact: true
    },
  
    {
      path: '/welcome',
      component: Welcome,
      exact: true
    },
    {
      path: '/agreement',
      component: Agreement,
      exact: true
    },
    {
      path: '/questionnaire',
      component: Questionnaire,
      exact: true
    },
    {
      path: '/questionnairestep2',
      component: QuestionnaireStep2,
      exact: true
    },

    {
      path: '/addphotoguide',
      component: AddPhotoGuide,
      exact: true
    },
    {
      path: '/addphotoinfo',
      component: AddPhotoInfo,
      exact: true
    },
    {
      path: '/frontface',
      component: FrontFace,
      exact: true
    },
    {
      path: '/smilingface',
      component: SmilingFace,
      exact: true
    },
    {
      path: '/leftface',
      component: LeftFace,
      exact: true
    },
    {
      path: '/rightface',
      component: RightFace,
      exact: true
    },
    {
      path: '/leftbody',
      component: LeftBody,
      exact: true
    },
    {
      path: '/rightbody',
      component: RightBody,
      exact: true
    },
    {
      path: '/reviewphoto',
      component: ReviewPhoto,
      exact: true
    },
    {
      path: '/freewayspace',
      component: FreewaySpace,
      exact: true
    },
    {
      path: '/5mmfittingstep1',
      component: FivemmFittingStep1,
      exact: true
    },
    {
      path: '/5mmfittingstep2',
      component: FivemmFittingStep2,
      exact: true
    },
    {
      path: '/5mmfittingstep3',
      component: FivemmFittingStep3,
      exact: true
    },
    {
      path: '/touchtest',
      component: TouchTest,
      exact: true
    },
    {
      path: '/5mmtouchtest',
      component: FiveMMTouchTest,
      exact: true
    },
    {
      path: '/5mmtestreport',
      component: TestReport5mm,
      exact: true
    },
    {
      path: '/3mmfittingstep1',
      component: ThreemmFittingStep1,
      exact: true
    },
    {
      path: '/3mmfittingstep2',
      component: ThreemmFittingStep2,
      exact: true
    },
    {
      path: '/3mmfittingstep3',
      component: ThreemmFittingStep3,
      exact: true
    },
    {
      path: '/3mmtouchtest',
      component: ThreeMMTouchTest,
      exact: true
    },
    {
      path: '/3mmtestreport',
      component: TestReport3mm,
      exact: true
    },
    {
      path: '/manualtouchtest5mm',
      component: ManualTouchTest5mm,
      exact: true
    },
    {
      path: '/thankyou',
      component: ThankYou,
      exact: true
    },
    {
      path: '/5simplesteps',
      component: SimpleSteps,
      exact: true
    },
    {
      path: '/setpreferences',
      component: SetPreferences,
      exact: true
    },
    {
      path: '/savepreferences',
      component: SavePreferences,
      exact: true
    },
    {
      path: '/ViewQuestionnaire',
      component: SavePreferences,
      exact: true
    },
    {
      path: '/ViewPreferences',
      component: SavePreferences,
      exact: true
    },
    {
      path: '/congratulations',
      component: Congratulations,
      exact: true
    },
    // {
    //   path: '/3mmhome',
    //   component: FiveMMHome,
    //   exact: true
    // },
    {
      path: '/5mmtestreport',
      component: TouchTestReport,
      exact: true
    },
    {
      path: '/setreminder/:_id',
      component: SetReminder,
      exact: true
    },
    {
      path: '/setreminder',
      component: SetReminder,
      exact: true
    },
    {
      path: '/questionbiweekly',
      component: QuestionBiweekly,
      exact: true
    },

    {
      path: '/viewQuestionnaireBiweekly',
      component: viewQuestionnaireBiweekly,
      exact: true
    },
    {
      path: '/photos',
      component: Photos,
      exact: true
    },
    {
      path: '/photos/:_id',
      component: Photos,
      exact: true
    },
    {
      path: '/progress/:_id',
      component: Homeprogress,
      exact: true
    },
    {
      path: '/userprogress/:token',
      component: Homeprogress,
      exact: true
    },
    {
      path: '/progress',
      component: Homeprogress,
      exact: true
    },
    // {
    //   path: '/instructions',
    //   component: Instructions,
    //   exact: true
    // },
    {
      path: '/3mmguide',
      component: ThreeMMGuide,
      exact: true
    },
    {
      path: '/5mmguide',
      component: FiveMMGuide,
      exact: true
    },
    {
      path: '/freewayspaceguide',
      component: FreeWaySpaceGuide,
      exact: true
    },
    {
      path: '/touchtestintroduction',
      component: TouchIntroduction,
      exact: true
    },
    {
      path: '/profile',
      component: Profile,
      exact: true
    },
    {
      path: '/manualentry',
      component: Manualentry,
      exact: true
    },
    {
      path: '/admin',
      component: AdminLogin,
      exact: true
    },
    {
      path: '/user',
      component: UserLogin,
      exact: true
    },
    {
      path: '/forgotpassword',
      component: ForgotPassword,
      exact: true
    },
    {
      path: '/userforgotpassword',
      component: UserForgotPassword,
      exact: true
    },
    {
      path: '/userSetPassword',
      component: userSetPassword,
      exact: true
    },
    {
      path: '/resetpassword/:token',
      component: ResetPassword,
      exact: true
    },
    {
      path: '/admindashboard',
      component: AdminDashboard,
      exact: true
    },
    {
      path: '/userdetails',
      component: UserDetails,
      exact: true
    },
    {
      path: '/adminprofile',
      component: AdminProfile,
      exact: true
    },
    {
      path: '/adminchangepwd',
      component: AdminChangePassword,
      exact: true
    },
    {
      path: '/signofoveruse',
      component: Signofoveruse,
      exact: true
    },
    {
      path: '/criteriachange',
      component: Criteriachange,
      exact: true
    },
    {
      path: '/reportdetail',
      component: TouchReportDetail,
      exact: true
    },
    {
      path: '/simpleStepsAdd',
      component: SimpleStepsAdd,
      exact: true
    },
    {
      path: '/feedback',
      component: Feedback,
      exact: true
    },
    {
      path: '/completestep',
      component: CompleteStep,
      exact: true
    },
    {
      path: '/freewayspaceview',
      component: FreewaySpaceView,
      exact: true
    },
    {
      path: '/viewreport',
      component: ViewReport,
      exact: true
    },
    {
      path: '/generateCode',
      component: generateVerificationCode,
      exact: true
    },
    {
      path: '/startingPoint',
      component: startingPoint,
      exact: true
    },
  ];


  componentDidMount() {

    if (firebase.messaging.isSupported()) {
      messaging.onMessage((payload) => {
        console.log('Message received. ', payload)
        if (!("Notification" in window)) {
          alert("This browser does not support desktop notification");
        }
        else if (Notification.permission === "granted") {
          console.log(payload.notification);

          var notification = new Notification(payload.notification.title, {
            body: payload.notification.body
          });
        }
      });
    }
  }

  render() {
    return (<BrowserRouter>
      <Switch>
        {
          this.routes.map((route, index) => {
            return <Route key={index} exact={route.exact} path={route.path} component={route.component} />
          })
        }
      </Switch>
    </BrowserRouter>);
  }
}
;

export default App;
