import { weeksToDays } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import "./progressweekly.scss";


function ProgressWeekly(props) {
  let { title, data, weeeks, defaultIndexMonth, selMonth, selMultiMonth } = props;
  const [selected, setselected] = useState(weeeks.length)
  const messagesEndRef = useRef(null)
  const scrollToBottom = () => {
    if (selected === 0 && messagesEndRef.current) {
      messagesEndRef.current.scrollLeft = messagesEndRef.current.scrollWidth;
    } 
  };

  useEffect(scrollToBottom, [props.clickIndex]);
  return (
    <div className="main-ProgressData">
      <h5 className="title">{title}</h5>
      {props.selectedTime == 0 && (
        <div className="weekly-List" ref={messagesEndRef}>
          {weeeks.map((item, i) => (
            <div className="list-Item" >
              <div
                className={
                  props.clickIndex === item
                    ? "bg-Color progresslightBg"
                    : "bg-Color progessdefault"
                }
                onClick={() => {
                  setselected(i);
                  props.onWeeksData(weeeks[i])
                }}
              >
                <span className="weeks-No"> {i + 1}</span>
              </div>
            </div>
          ))}
        </div>
      )
      }
      {
        (props.selectedTime == 1 || props.selectedTime == 3) && (
          <div className="weekly-List">
            {props.data.map((index) => (
              <div className="list-Item">
                {/* className={this.props.clickIndex === i + 1 ? 'bg-Color progresslightBg' : 'bg-Color progessdefault' */}
                <div
                  className={
                    selMonth === index
                      ? "bg-Color monthsBox progresslightBg"
                      : "bg-Color monthsBox progessdefault"
                  }
                  onClick={() => props.onCickMonthData(index)}
                >
                  <span className="weeks-No">{index}</span>
                </div>
              </div>
            ))}

            {/* <div className='list-Item'>
                          <div className='bg-Color monthsBox'>
                              <span className='weeks-No'>{this.props.data[0]}</span>
                          </div>
                      </div>
                      <div className='list-Item'>
                          <div className='bg-Color monthsBox'>
                              <span className='weeks-No'>{this.props.data[1]}</span>
                          </div>
                      </div> */}
          </div>
        )
      }
      {
        props.selectedTime == 2 && (
          <div className="weekly-List">
            {props.data.map((index) => (
              <div className="list-Item">
                <div
                  className={
                    selMultiMonth === index
                      ? "bg-Color monthlyBox progresslightBg"
                      : "bg-Color monthlyBox progessdefault"
                  }
                  onClick={() => props.onClickTwoMonthData(index)}
                >
                  <span className="weeks-No">{index}</span>
                </div>
              </div>
            ))}
            {/* <div className='list-Item'>
                          <div className='bg-Color monthlyBox'>
                              <span className='weeks-No'>{this.props.data[0]}</span>
                          </div>
                      </div> */}
          </div>
        )
      }
    </div >
  );
}
export default ProgressWeekly;
