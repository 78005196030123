import * as firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
    // Project Settings => Add Firebase to your web app
    apiKey: "AIzaSyBmPEqoCio7qtIq7pS6ElOXpxv3ZC60uDM",
    authDomain: "oralift-a0607.firebaseapp.com",
    databaseURL: "https://oralift-a0607.firebaseio.com",
    projectId: "oralift-a0607",
    storageBucket: "oralift-a0607.appspot.com",
    messagingSenderId: "1070395794645",
    appId: "1:1070395794645:web:ba61c543a9ec4bfa5cae5c"
});
console.log(firebase.messaging.isSupported());
if (!firebase.messaging.isSupported()) {
    var messaging = '';
} else {
    var messaging = initializedFirebaseApp.messaging();

    messaging.usePublicVapidKey(
        // Project Settings => Cloud Messaging => Web Push certificates
        "BHYnit7qSg6l7uCLF-akVZAFGMzr5SGS9_SDd768fdKKswjZzb63w-7S7aOtizBfjFFbPkXHG3G0qHUPhuvFiNw"
    );
}
export { messaging };