import React from 'react';
import { setLocalActiveClass, setFilledFlags } from "../../../utils/helper";
import './agreement.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu';
import AdminSideMenu from '../../../components/adminsidebar/adminsidebar';
import Header from '../../../components/header/header';
import Button from '../../../components/Button/button'
import AgreementImg from '../../../assets/images/document.svg';
import CheckBox from '../../../components/checkbox/checkbox';
import { id } from 'date-fns/esm/locale';
import { getFormDataObject, getCookie, checkForHistoryPushParam } from '../../../utils/helper';
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import HomeSideMenu from '../../../components/homesidebar/homesidebar';
import HomeHeader from '../../../components/homeheader/homeheader';
import AdminHeader from '../../../components/adminheader/adminheader';
import WebService from "../../../utils/Webservice";
import CONSTANT from "../../../utils/constants";
class Agreement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            bTermsAndCondition: false,
            bPhotoPermission: false,
            bAdvisorAccess: false,
            isLoading: false,
            isToast: false,
            isDisabled: false,
            isToastShow: false,
            _id: (this.props.history.location.state.isViewFrom === 'adminAgreement') ? localStorage.getItem("userId") : localStorage.getItem("_id"),
            isViewFrom: this.props.history.location.state.isViewFrom
        }
        setLocalActiveClass("Agreement")
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getUserAgreementWebService()
    }

    handleChange(e) {
        let { bTermsAndCondition, bAdvisorAccess, isDisabled } = this.state
        let obj = {}
        obj[e.target.name] = e.target.checked
        this.setState(obj)
    }

    onGoClick() {
        this.userAgreementWebService()
        setFilledFlags(CONSTANT.KEYS.KeyAgreementFlag, true)
        this.props.history.push('/questionnaire', { toaster: true })
    }

    //************************USER_AGREEMENT API Call****************************************
    async userAgreementWebService() {
        try {
            this.setState({ isLoading: true });
            let obj = {
                _id: this.state._id,
                bTermsAndCondition: this.state.bTermsAndCondition,
                bPhotoPermission: this.state.bPhotoPermission,
                bAdvisorAccess: this.state.bAdvisorAccess
            };
            let data = getFormDataObject(obj);
            let response = await WebService.userAgreement(data);
            this.setState({ isLoading: false });

            if (response.status) {
                this.handleSuccessResponse(response);
            } else {
                console.log("response" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }
    handleSuccessResponse(response) {

        let { bTermsAndCondition, bPhotoPermission, bAdvisorAccess } = response.data
        let agreement = bTermsAndCondition || bPhotoPermission || bAdvisorAccess
        this.setState({
            bTermsAndCondition: bTermsAndCondition,
            bPhotoPermission: bPhotoPermission,
            bAdvisorAccess: bAdvisorAccess,
            isDisabled: bTermsAndCondition && bPhotoPermission && bAdvisorAccess,
        }, () => {
            let isAgreement = false
            if (bTermsAndCondition != undefined && bPhotoPermission != undefined && bAdvisorAccess != undefined) {
                isAgreement = bTermsAndCondition && bPhotoPermission && bAdvisorAccess
            }
            localStorage.setItem('agreement', JSON.stringify(isAgreement))
        })
    }
    //************************GET_USER_AGREEMENT API Call****************************************
    async getUserAgreementWebService() {
        try {
            let obj = {
                _id: this.state._id,
            };
            let response = await WebService.getUserAgreement(obj);
            this.setState({ isLoading: false });

            if (response.status) {
                console.log(JSON.stringify(response));
                this.handleSuccessResponse(response);
            } else {
                console.log("response" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        let { isChecked, value, show, toastMessage } = this.props
        let { bTermsAndCondition, bPhotoPermission, bAdvisorAccess, isDisabled, isViewFrom } = this.state

        return (
            <div className='main-AgreementPage'>
                {isViewFrom === 'adminAgreement' && <AdminHeader  {...this.props} ></AdminHeader>}
                {this.state.isViewFrom === "home" ? <HomeSideMenu {...this.props} showMorePopup={true}></HomeSideMenu> :
                    this.state.isViewFrom === "adminAgreement" ? <AdminSideMenu {...this.props} showMorePopup={true}></AdminSideMenu> : <SideMenu {...this.props}></SideMenu>}
                {(isViewFrom === "homeViewQuestionnaire" || isViewFrom == 'homeSetPreferences' || isViewFrom == 'home') && <HomeHeader  {...this.props} ></HomeHeader>}
                <div className='agreementPage'>
                    <Scrollbars>
                        <div className='main-Container'>
                            <div className='img-Container'>
                                <img src={AgreementImg} className="logo" alt="logo" />
                            </div>
                            {this.state.isViewFrom === "home" ? '' : <h4 className='paddingB15'>Lets Begin,</h4>}

                            <h1 className='paddingB15'>Agreement</h1>
                            <div className='content-Section'>
                                <h5 className='paddingB15'>Taking photo is an essential part of this treatment. If you do not want to take your photographs, it will not be possible to judge your results. You will find it difficult to monitor your progress and will not be able to upgrade to the advisor service. You cannot start taking photographs at a later stage because changes can start to happen very quickly.</h5>
                            </div>
                            <div className='checkbox-Section' >

                                <CheckBox isChecked={bTermsAndCondition} disabled={isDisabled} name="bTermsAndCondition" value="bTermsAndCondition" onChange={this.handleChange} title='I understand and accept all terms and conditions' Id="checkbox13"></CheckBox>
                                <CheckBox isChecked={bPhotoPermission} disabled={isDisabled} name="bPhotoPermission" value="bPhotoPermission" onChange={this.handleChange} title='I understand Oralift will store my photos securely.' Id="checkbox14"></CheckBox>
                                <CheckBox isChecked={bAdvisorAccess} disabled={isDisabled} name="bAdvisorAccess" value="checkbox3" onChange={this.handleChange} title='I understand an advisor will be able to access my information if I have choosen kit with the personal advisor.' Id="checkbox15">></CheckBox>

                            </div>
                            {this.state.isViewFrom === "home" || this.state.isViewFrom === "adminAgreement" ? '' : <Button title='Let&apos;s Go' disabled={!bTermsAndCondition || !bPhotoPermission || !bAdvisorAccess} onClick={() => this.onGoClick()}>
                            </Button>}
                        </div>
                    </Scrollbars>
                </div>
            </div>

        )
    }
}




export default Agreement;

