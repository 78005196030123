import React from 'react';
import './notification.scss';
import { Scrollbars } from 'react-custom-scrollbars';

class Notification extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }

    }



    render() {
        return (
            <div className={this.props.className}>
                <div className="main-Notification">
                    <div className='notification-Header'>
                        <span className="icon-close cursor" onClick={() => this.props.onClose()} ></span>
                        <h2 className='header'>Notification</h2>
                    </div>
                    <Scrollbars className="notificationScroll">
                        <div className='notification-Details'>
                            <div className='inner-Details'>
                                <span className="warning-Icon icon-css"></span>
                                <h5>You haven’t added any photographs for the last two months.To understand why it is important to add your photographs Tap Here</h5>
                            </div>
                        </div>
                        <div className='notification-Details'>
                            <div className='inner-Details'>
                                <span className="warning-Icon icon-css"></span>
                                <h5>Since signs of overuse have been recorded by you on Sep 29th, we would recommend that you cut down your wear time by half</h5>
                            </div>
                        </div>

                    </Scrollbars>
                </div>

            </div>
        );
    }
}
export default Notification;
