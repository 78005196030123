import React from 'react';
import { setLocalActiveClass, getStoredData } from "../../../utils/helper";
import HomeSideMenu from '../../../components/homesidebar/homesidebar';
import './feedback.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu';
import Header from '../../../components/header/header';
import Button from '../../../components/Button/button';
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import ReportImg from '../../../assets/images/warning.svg';
import WebService from "../../../utils/Webservice";

class Feedback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toaster: false,
            message: "",
            isShow: false,
            sFeedback: '',
            disableBtn: true, // Initially, disable the button
            sFirstName: getStoredData('sUserFirstName'),
            sLastName: getStoredData('sUserLastName'),
            isViewFrom: this.props.history.location.state.isViewFrom
        };
        setLocalActiveClass("feedback");
    }

    handleChange(e) {
        const feedbackText = e.target.value;
        this.setState({ sFeedback: feedbackText, disableBtn: feedbackText.length === 0 });
    }

    onSubmitClick() {
        this.setFeedback();
        this.setState({ sFeedback: '', disableBtn: true }); // Clear the sFeedback and disable the button again
    }

    onKeyDown(e) {
        if (e.keyCode === 13) {
            this.setFeedback();
            this.setState({ sFeedback: '', disableBtn: true }); // Clear the sFeedback and disable the button again
        }
    }

    async setFeedback() {
        const { sFirstName, sLastName, sFeedback } = this.state;
        try {
            const obj = {
                sFirstName: sFirstName,
                sLastName: sLastName,
                sBody: sFeedback
            };
            let response = await WebService.userFeedback(obj);
            this.setState({ isLoading: false });

            if (response.status) {
                this.setState({ toaster: true, message: response.MESSAGES, isShow: true });
            } else {
                this.setState({ toaster: true, message: response.MESSAGES, isShow: true });
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { message, isShow, toaster, sFirstName, sLastName } = this.state;
        return (
            <div className='feedbackMain'>
                <Header></Header>
                <HomeSideMenu {...this.props} showMorePopup={true}></HomeSideMenu>
                <div className='inner-TestReport5mm'>
                    {toaster && <ToastComponent message={message} show={isShow} onClose={() => this.setState({ toaster: false })} delay={3000} autohide />}
                    <Scrollbars className='testreport-Scroll'>
                        <div className='main-Container'>
                            <h1 className='paddingB15'>Feedback</h1>
                            <div className='content-Section'>
                                <textarea autoFocus={true} onChange={this.handleChange.bind(this)} className="form-control" placeholder="Please Enter your Feedback" rows="6" value={this.state.sFeedback}></textarea>
                            </div>
                            <Button disabled={this.state.disableBtn} title="submit" onClick={() => this.onSubmitClick()}></Button>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        );
    }
}

export default Feedback;
