import React from "react";
import { setLocalActiveClass, setFilledFlags } from "../../../utils/helper";
import "./welcome.scss";
import SideMenu from "../../../components/sidebar/sidemenu";
import VideoPlayer from "../../../components/videoplayer/videoplayer";
import Button from "../../../components/Button/button";
import { Scrollbars } from "react-custom-scrollbars";
import Header from "../../../components/header/header";
import WebService from "../../../utils/Webservice"
import CONSTANT from "../../../utils/constants";



class Welcome extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sUserFirstName: '',
            sUserLastName: '',
            _id: localStorage.getItem("_id")
        };
        setLocalActiveClass("Welcome");
    }
    onStartedClick() {
        this.props.history.push("/agreement", { isViewFrom: "onboarding" })
        localStorage.setItem('isViewFrom', 'onboarding')

    }
    componentDidMount() {


        this.getUserInfo()


    }
    async getUserTouchCount() {
        console.log("call get touch");

        try {
            const data = new FormData()
            data.append('iUserId', this.state._id)
            let response = await WebService.getTouchCount(data);

            this.setState({ isLoading: false });
            if (response.status) {
                // this.handleSuccessResponseForPhoto(response);
                console.log(response.data.count3mm)
                console.log(response.data.count5mm)
                if (response.data.count5mm > 0) {
                    setFilledFlags(CONSTANT.KEYS.Key5mmTest, true)
                } else {
                    setFilledFlags(CONSTANT.KEYS.Key5mmTest, false)
                }
                if (response.data.count3mm > 0) {
                    setFilledFlags(CONSTANT.KEYS.Key3mmTest, true)
                } else {
                    setFilledFlags(CONSTANT.KEYS.Key3mmTest, false)
                }

            } else {
                console.log("response" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }
    async getUserPhoto() {
        console.log("call");

        try {
            const data = new FormData()
            data.append('iUserId', this.state._id)
            let response = await WebService.getPhotos(data);

            this.setState({ isLoading: false });
            if (response.status) {
                this.handleSuccessResponseForPhoto(response);
            } else {
                console.log("response" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleSuccessResponseForPhoto(response) {
        let responseData = response.data.userPhotoData
        console.log("==>" + responseData.length)
        if (responseData.length > 0) {

            if ((responseData[0].sNonSmilePhoto && responseData[0].sNonSmilePhoto !== '')
                && (responseData[0].sSmilePhoto && responseData[0].sSmilePhoto !== '') &&
                (responseData[0].sLeftPhoto && responseData[0].sLeftPhoto !== '') &&
                (responseData[0].sRightPhoto && responseData[0].sRightPhoto !== '')) {
                setFilledFlags(CONSTANT.KEYS.KeyPhotoAdded, true)

            } else {
                setFilledFlags(CONSTANT.KEYS.KeyPhotoAdded, false)
            }
        } else {
            setFilledFlags(CONSTANT.KEYS.KeyPhotoAdded, false)


        }

        console.log("isAgreement=>" + localStorage.getItem(CONSTANT.KEYS.KeyAgreementFlag))
        console.log("isQuestionnary=>" + localStorage.getItem(CONSTANT.KEYS.KeyQuestionaryFlag))

        console.log("isPhoto=>" + localStorage.getItem(CONSTANT.KEYS.KeyPhotoAdded))




    }


    //************************GET_USER_Info API Call****************************************
    async getUserInfo() {
        try {
            let obj = {
                _id: this.state._id,
            };
            let response = await WebService.getUserAgreement(obj);
            this.setState({ isLoading: false });

            if (response.status) {
                //   console.log(JSON.stringify(response));
                this.handleSuccessResponse(response);
            } else {
                console.log("response" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }
    handleSuccessResponse(response) {
        console.log(response.data)
        let { sUserFirstName, sUserLastName, bAdvisorAccess,
            bPhotoPermission,
            bTermsAndCondition, nAntiAgeingFlag, sAntiAgeingTreatement, aWishlist } = response.data





        this.setState({
            sUserFirstName: sUserFirstName,
            sUserLastName: sUserLastName,
        })
    }

    render() {

        let { sUserFirstName, sUserLastName } = this.state
        return (
            <div className="main-Welcomepage">
                <Header></Header>
                <SideMenu {...this.props}></SideMenu>
                <div className="inner-Welcomepage">
                    <Scrollbars>
                        <div className="main-Container">
                            <h4 className="paddingB10">Welcome</h4>
                            <h1 className="paddingB15">{sUserFirstName} {sUserLastName}</h1>
                            <VideoPlayer url={"https://player.vimeo.com/video/383054554"} controls={true}>
                                {/* <ReactPlayer url={"https://vimeo.com/369339577/94787c944c"} controls={true}></ReactPlayer> */}
                            </VideoPlayer>
                            <div className="content-Section">
                                <h5 className="paddingB15">
                                    We need to capture some information and details from you to get
                started with you
                                    <span className="highlighted"> Oralift Programme.</span>
                                </h5>
                                <h5 className="paddingB15">
                                    We will need to to answer some simple questions and take your
                                    photographs.Then we will guide you through how easily your
                                    Oralift device can be fitted.
                                  </h5>
                                <h5 className="paddingB15">
                                    We will complete a Touch Test that will set a baseline for your
                                    Oralift Programme.
                                </h5>
                                <h5 className="paddingB15">
                                    The whole process will take approximately 45 mins.
                                    <span className="highlighted">
                                        You will only need to do this once.
                                    </span>
                                </h5>
                            </div>
                            <Button
                                title="get started"
                                onClick={() => this.onStartedClick()}
                            ></Button>

                            {/* <div className="upgrade-Section">
                                <h5 className="paddingB15">
                                    If you purchased already and want to upgrade
              </h5>
                                <h5 className="paddingB15">
                                    <span className="highlighted textCapital cursor">
                                        Upgrade <span className="icon-right icon-css"></span>
                                    </span>
                                </h5>
                            </div> */}
                        </div>
                    </Scrollbars>
                </div>
            </div>
        );
    }
}

export default Welcome;
