import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../Button/button';
import './modal-photos.scss';
import PropTypes from 'prop-types';

class PopupPhotosModal extends React.Component {
    constructor(props, context) {
        super(props, context);
    }


    render() {
        let { } = this.props
        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.show}
                className="customModal"

            >
                <Modal.Body>
                    <h4 className='paddingB15'>Skip Photos</h4>
                    <h5 className='paddingB15'>If you skip photographs now, you cannot go back later and take them</h5>
                    <h5 className='paddingB15'>Without photographs, it may not be possible to assess the full extent of the changes. </h5>
                    <h5 className='paddingB15'>Photographs are securely stored and never used without your consent</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onSkip}>yes, skip photos</Button>
                    <Button onClick={this.props.onHide}>cancel and take photos</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}



export default PopupPhotosModal;

