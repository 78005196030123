import "intl";
import "intl/locale-data/jsonp/en";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import "bootstrap/dist/css/bootstrap.min.css";
import { unregister as unregisterServiceWorker } from './serviceWorker';
// serviceWorker.unregister();
unregisterServiceWorker();
//const App = () => <Loading />;


if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("./firebase-messaging-sw.js")
        .then(function (registration) {
            console.log("Registration successful, scope is:");
        })
        .catch(function (err) {
            console.log("Service worker registration failed, error:");
        });
}
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA


