import React from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import { Row, Col } from 'react-bootstrap';
import './5mmfittingstep3.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu'
import Header from '../../../components/header/header';
import FreespaceImg from '../../../assets/images/teethFreespace.svg';
import ReactPlayer from "../../../components/videoplayer/videoplayer";
import Button from "../../../components/Button/button";
import HomeSideMenu from '../../../components/homesidebar/homesidebar';

class FivemmFittingStep3 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isViewFrom: this.props.history.location.state.isViewFrom,
        }
        // setLocalActiveClass("5mmFittingStep1")
        // { this.state.isViewFrom === '3mm Touch Test' ? setLocalActiveClass("3mmFittingStep1") : setLocalActiveClass("5mmFittingStep1") }
        { this.state.isViewFrom === '3mm Touch Test' ? setLocalActiveClass("3mmFittingStep1") : (this.state.isViewFrom === 'home3mm' || this.state.isViewFrom === 'home5mm' ? setLocalActiveClass("HomeInstructions") : setLocalActiveClass("5mmFittingStep1")) }

    }
    onNextStepClick() {
        { (this.state.isViewFrom === '3mm Touch Test') ? this.props.history.push(('/3mmtouchtest'), { isViewFrom: this.state.isViewFrom }) : (this.state.isViewFrom === 'home3mm' || this.state.isViewFrom === 'home5mm') ? this.props.history.push("/instructions", { isViewFrom: this.state.isViewFrom }) : this.props.history.push('/touchtest', { isViewFrom: this.state.isViewFrom }) }
        // this.props.history.push('/touchtest')
    }

    render() {
        let btnTitle = this.state.isViewFrom === 'home3mm' || this.state.isViewFrom === 'home5mm' ? 'go to instruction' : "next step"
        let headerData = this.state.isViewFrom === '3mm Touch Test' || this.state.isViewFrom === 'home3mm' ? '3mm Fitting - Fitting Guide' : '5mm Fitting - Fitting Guide'
        return (
            <div className='main-5mmFitting2'>
                <Header></Header>
                {this.state.isViewFrom === 'home3mm' || this.state.isViewFrom === 'home5mm' ? <HomeSideMenu {...this.props} showMorePopup={true}></HomeSideMenu> : <SideMenu {...this.props}></SideMenu>}
                {/* <SideMenu {...this.props}></SideMenu> */}
                <div className='inner-5mmFitting'>
                    <Scrollbars>
                        <div className='main-Container'>
                            <div className='img-Container'>
                                <img src={FreespaceImg} className="logo" alt="logo" />
                            </div>
                            <h2 className='paddingB15'>{headerData}</h2>
                            <div className='content-Section'>
                                <h5 className='paddingB15'>Fitting your Oralift device is easy. Please follow the steps in the instruction video below: You may prefer to watch all three videos before starting the procedure. You can watch these as many times as you want until you are happy to proceed.</h5>
                            </div>
                            {/* <VideoPlayer></VideoPlayer> */}
                            <ReactPlayer url={this.state.isViewFrom !== 'home5mm' ? "https://vimeo.com/426036134/8f3efaf82d" : "https://vimeo.com/426037097/738b1de40a"} controls={true}></ReactPlayer>
                            <div className='content-Section paddingB40'>
                                <h3 className='paddingB15'>Step3: Fitting</h3>
                                <h5 className='paddingB15'>Make sure you do not bite too hard otherwise you may distort the device.</h5>
                            </div>
                            <Button
                                title={btnTitle}
                                onClick={() => this.onNextStepClick()}
                            ></Button>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        )
    }
}

export default FivemmFittingStep3;

