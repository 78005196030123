import React from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import './completeStep.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu'
import Header from '../../../components/header/header';
import ThankYouImg from '../../../assets/images/congrats.svg';

class Congratulations extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
        setLocalActiveClass("Congratulations")
    }
    onGoClick() {
        this.props.history.push('3mmhome')
    }

    render() {
        return (
            <div className='main-Congratulations'>
                <Header></Header>
                <SideMenu {...this.props}></SideMenu>
                <div className='inner-Congratulations'>
                    <Scrollbars>
                        <div className='step-Container'>
                            <div className="complete-step">
                                <div className='img-Container'>
                                    <img src={ThankYouImg} className="logo" alt="logo" />
                                </div>
                                <h4 className='paddingB15 highlighted'>Congratulations</h4>
                                <div className='content-Section'>
                                    <h5>You have successfully completed 5mm Touch Test</h5>
                                </div>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </div >

        )
    }
}




export default Congratulations;

