import React from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import './5simplesteps.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu'
import { Row, Col } from 'react-bootstrap';
import Header from '../../../components/header/header';
import Button from '../../../components/Button/button'
import ThankYouImg from '../../../assets/images/stopwatch.svg';



class SimpleSteps extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: new Date()
        }
        setLocalActiveClass("3mmTouchTest");
    }

    onSetClick() {
        this.props.history.push('/setpreferences', { isViewFrom: "onboarding" })
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });
    }
    endDateChange(date) {
        this.setState({
            endDate: date
        });
    }
    render() {

        return (
            <div className='main-SimpleSteps'>
                <Header></Header>
                <SideMenu {...this.props}></SideMenu>
                <div className='inner-SimpleSteps'>
                    <Scrollbars className='simplesteps-Scroll'>
                        <div className='main-Container'>
                            <div className='img-Container'>
                                <img src={ThankYouImg} className="logo" alt="logo" />
                            </div>
                            <h2 className='paddingB15'>Continuing Your Programme- 5 Simple Steps</h2>
                            <div className='content-Section'>
                                <div className='borderBottom'>
                                    <h4 className='paddingB15'><span className='highlighted'>1</span> Start with 3mm device and set your start time</h4>
                                    <h5 className='paddingB15'>You started the on-boarding process with 5mm device, followed by 3mm devicem but you always start your programme by wearing the 3mm device.</h5>
                                    <h5 className='paddingB15'>When you wear the 5mm device first, the muscles of the face are starting to adjust to this and then when you wear the 3mm device, the brain finds this so much easier because the muscles have already started to adapt to the 5mm device.</h5>
                                    <h5 className='paddingB15'>If you had no pain with either device, your starting point after two days of rest will be 15 minutes with 3mm device morning and evening. If you suffered any pain with either device, you should start with the time you were able to wear the 3mm device without any pain.</h5>
                                </div>
                                <div className='borderBottom'>
                                    <h4 className='paddingB15'><span className='highlighted'>2</span> Double up</h4>
                                    <h5 className='paddingB15'>After two days rest, you can start doubling up the time until you reach a maximum of 60 minutes am and pm</h5>
                                </div>
                                <div className='borderBottom'>
                                    <h4 className='paddingB15'><span className='highlighted'>3</span> Introduce 5mm device</h4>
                                    <h5 className='paddingB15'>After two months of wearing the 3mm device, you can start wearing the 5mm device. You need to introduce it gradually e.g., wear the 5mm device for 5 minutes and the 3mm for 55 minutes until you are only using the 5mm device.</h5>
                                    <h5 className='paddingB15'>The criteria to decide this are:
                                    <br />
                                        a) If you have reached your maximum time (usually 60 minutes) with no signs of overuse. N.B: the maximum does not have to be 60 minutes if your lifestyle allows you to wear the device for say 45 mins max. Oralift users have reported excellent results with much shorter wear times.
     <br />
                                        b) The maximum time should have been reached for at least two or three wear periods.
     <br />
                                        c) With your 3mm device, if you do not feel any stretch on your face, you should change to the 5mm". </h5>
                                    <h5 className='paddingB15'>If at any point, you get any signs of overuse, you should half the amount you are wearing the device or return to the programme which was not giving you any pain.</h5>
                                </div>
                                <div className='borderBottom'>
                                    <h4 className='paddingB15'><span className='highlighted'>4</span> Introduce 5mm device</h4>
                                    <h5 className='paddingB15'>After 4 months from the start of your programme, you should have a 4 month rest.</h5>
                                </div>
                                <div className='borderBottom'>
                                    <h4 className='paddingB15'><span className='highlighted'>5</span> Programme for life</h4>
                                    <h5 className='paddingB15'>Thereafter, the programme should be 2 months wear, and 4 months rest. In other words, you only wear the device for 2 months, twice a year. You do not need to start again gradually just start from the point you left off.</h5>
                                </div>
                            </div>

                            <Button title='set my preferences' onClick={() => this.onSetClick()}></Button>
                        </div>
                    </Scrollbars>
                </div>
            </div >

        )
    }
}




export default SimpleSteps;

