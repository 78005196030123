import React from 'react';
import { setLocalActiveClass, getStoredData, setStoredData } from "../../../utils/helper";
import HomeSideMenu from '../../../components/homesidebar/homesidebar'
import './5mmtestreport.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu'
import { Row, Col } from 'react-bootstrap';
import Header from '../../../components/header/header';
import Button from '../../../components/Button/button'
import ReportImg from '../../../assets/images/overuse.svg';
import CheckBox from '../../../components/checkbox/checkbox';
import WebService from "../../../utils/Webservice"
import moment from 'moment';
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import { el } from 'date-fns/esm/locale';


const options = ['No', 'Yes']
const options2 = ['Muscles trembling', 'Pain on facial muscles', 'Starting a headache', 'Skin looking worse', 'Other']
const options1 = ['Muscles trembling', 'Pain on facial muscles', 'Starting a headache']
let overUseArrays = [
    {
        isChecked: false,
        title: "Muscles trembling"
    }, {
        isChecked: false,
        title: "Pain on facial muscles"

    }, {
        isChecked: false,
        title: "Starting a headache"
    }, {
        isChecked: false,
        title: "Skin looking worse"
    }, {
        isChecked: false,
        title: "Other"
    }]
class TestReport5mm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isPain: 'Yes',
            painReport: 'Muscles trembling',
            isReportValue: '',
            comments: '',
            _id: getStoredData("_id"),
            question1: 'Did you stop the test because of pain or discomfort?',
            question2: 'Please tell us what kind of pain or discomfort you felt.',
            additionalComments: 'Additional Comments',
            touchTestId: getStoredData('touchTestId'),
            isDataFrom: this.props.history.location.state.isViewFrom,
            toaster: false,
            message: "",
            disableBtn: true,
            nPrefered: moment(new Date(), 'ddd DD-MMM-YYYY,A').format('A') === 'AM' ? 0 : 1,
            isShow: false,
            timer: this.props.history.location.state.timer,
            totalNoOfTouch: this.props.history.location.state.totalNoOfTouch,
            isDevicesType: this.props.history.location.state.isDevicesType,
            isOnBoardingComplete: getStoredData('isOnBoardingComplete'),
            checkBox1: false,
            checkBox2: false,
            checkBox3: false,
            checkBox4: false,
            checkBox5: false,
            optionsChecked: [],
            overUseArrays: overUseArrays
        }
        { this.state.isDataFrom === "5mm Touch Test" ? setLocalActiveClass("5mmTouchTest") : (this.state.isDataFrom === 'home') ? setLocalActiveClass("Home") : setLocalActiveClass("3mmTouchTest") }
    }
    componentDidMount() {
        this.state.overUseArrays.map((item) => {
            item['isChecked'] = false
        })
        this.setState({ overUseArrays })
    }

    handlSelect(eventKey) {
        this.setState({ painReport: eventKey })
    }
    onItemHandlSelect(e, option, eventKey) {
        this.setState({
            painReport: e.target.text,
            selOption: option,
            value: eventKey,
        }, () => console.log(this.state.painReport))
    }
    onSubmitClick() {
        setStoredData("defaultProgressTime", '')
        setStoredData("defaultTouchCount", '')
        setStoredData("defaultSetProgress", '')
        setStoredData("defaultTime", '')
        let { isDataFrom, timer } = this.state
        if (isDataFrom === "5mm Touch Test") {
            this.addTouchReportWebServices()
            this.props.history.push('/3mmfittingstep1', { isViewFrom: "3mm Touch Test" })
        } else {
            if (isDataFrom === 'home') {
                if (timer === undefined) {
                    this.addTouchReportWebServices()
                }
                else {
                    this.addTouchtestAPI()
                }
            } else {
                this.addTouchReportWebServices()
                this.props.history.push('/thankyou')
            }
        }
    }
    handleChange(event) {
        this.setState({ comments: event.target.value })
    }
    handleChangeCheckBox(event, index) {

        let { overUseArrays } = this.state
        overUseArrays[index].isChecked = event.target.checked
        event.target.checked === true ? this.setState({ disableBtn: false }) : (overUseArrays.filter(props => props.isChecked).length === 0) ? this.setState({ disableBtn: true }) : this.setState({ disableBtn: false })
        this.setState({ overUseArrays })
    }
    onRetakeClick() {
        { this.state.isDataFrom === "5mm Touch Test" ? this.props.history.push('/5mmtouchtest', { isViewFrom: "5mm Touch Test" }) : (this.state.isDataFrom === 'home' ? this.props.history.push("/3mmhome", { isViewFrom: this.state.isDataFrom }) : this.props.history.push('/3mmtouchtest', { isViewFrom: "3mm Touch Test" })) }
    }
    handlEvent(eventKey, event) {
        this.setState({ isPain: eventKey })
    }
    onItemHandleEvent(e, option, eventKey) {
        this.setState({
            isPain: e.target.text,
            selOption: option,
            value: eventKey,
        })
    }

    async addTouchReportWebServices() {
        let { overUseArrays } = this.state
        for (let i = 0; i < overUseArrays.length; i++) {
            const element = overUseArrays[i];
            if (element.isChecked) {
                this.state.optionsChecked.push(element.title)
            }
        }
        let report = []
        let data = {}
        data[this.state.question2] = this.state.optionsChecked
        report.push(data)

        const commentsReport = {}
        commentsReport[this.state.additionalComments] = this.state.comments;
        report.push(commentsReport)

        try {
            let obj = {
                touchTestId: getStoredData("touchTestId"),
                report: report
            };
            let response = await WebService.UserTouchReport(obj);
            this.setState({ isLoading: false });
            if (response.status) {
                this.setState({ toaster: true, message: response.MESSAGES, isShow: true })
                console.log("Success::" + response.status);
                this.handleSuccessResponse(response);
            } else {
                this.setState({ toaster: true, message: response.MESSAGES, isShow: true })
                console.log("Error::" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }
    handleSuccessResponse(response) {
        setStoredData("touchTestId", '')
        setStoredData('isShowPopup', true)
        setTimeout(() => {
            this.props.history.push('/3mmhome', { isViewFrom: "home", isDevicesType: this.state.isDevicesType })
        }, 3000);
    }
    async addTouchtestAPI() {
        let { nPrefered, timer, _id, totalNoOfTouch, isDevicesType } = this.state
        try {
            let obj = {
                iUserId: _id,
                nTouchCount: totalNoOfTouch,
                sTotalTime: timer,
                nDeviceType: isDevicesType,
                nPrefered: nPrefered,
                dCreatedAt: new Date()
            };
            let response = await WebService.UserTouchTest(obj);
            this.setState({ isLoading: false });

            if (response.status) {
                setStoredData("touchTestId", response.data.touchTestId)
                this.addTouchReportWebServices()
            } else {
                this.setState({ toaster: true, message: response.MESSAGES, isShow: true })
                console.log("response" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        let { isPain, isDataFrom, message, isShow, toaster, isOnBoardingComplete, checkBox1, checkBox2, checkBox3, checkBox4, checkBox5 } = this.state
        return (
            <div className='main-TestReport5mm'>
                <Header></Header>
                {isDataFrom === 'home' ? <HomeSideMenu {...this.props}></HomeSideMenu> : <SideMenu {...this.props}></SideMenu>}
                <div className='inner-TestReport5mm'>
                    <Scrollbars className='testreport-Scroll'>
                        <div className='main-Container'>
                            {toaster && <ToastComponent message={message} show={isShow} onClose={() => this.setState({ toaster: false })} delay={3000} autohide >}></ToastComponent>}
                            <div className='img-Container'>
                                <img src={ReportImg} className="logo" alt="logo" />
                            </div>
                            <h2 className='paddingB32'>Signs of overuse</h2>
                            <form className='question-Form'>
                                <Row>
                                    <Col lg={12} md={12} sm={12} className="checkbox-Section">
                                        <label className='cust-Label'>{this.state.question2}</label>
                                        {
                                            overUseArrays.map((item, i) => (
                                                <CheckBox value={item.title} title={item.title} isChecked={item.isChecked} onChange={(e) => this.handleChangeCheckBox(e, i)} Id={i}></CheckBox>
                                            ))
                                        }
                                    </Col>
                                    <Col lg={12} md={12} sm={12} className={isPain === 'No' ? 'test' : "textarea-Section"}>
                                        <label className='cust-Label'>{this.state.additionalComments}</label>
                                        <textarea resize="none" className="textarea" onChange={this.handleChange.bind(this)} placeholder='Write your message here…'>
                                        </textarea>
                                    </Col>
                                </Row>
                            </form>
                            <Button title='Submit' disabled={this.state.disableBtn} onClick={() => this.onSubmitClick()}></Button>
                        </div>
                    </Scrollbars>
                </div>
            </div >
        )
    }
}




export default TestReport5mm;

