// import React from 'react';
// import { DropdownButton, Dropdown } from 'react-bootstrap';
// import './dropdown.scss';
// // import Constants from '../../util/constants';

// class DropdownMenu extends React.Component {
//     constructor(props) {

//         super(props)
//         this.state = {
//             title: this.props.title
//         }

//     }

//     onItemSelect(e, option) {
//         this.setState({
//             title: e.target.text,
//             selOption: option
//         })
//     }

//     render() {
//         let { title } = this.props
//         return (
//             <div className="dropdowncomponent">
//                 <DropdownButton id="dropdown-item-button" title={title} className='dropdownButtom' >
//                     <Dropdown.Item eventKey="No" onSelect={(eventKey, e) => this.onItemSelect(e, option)}>No</Dropdown.Item>
//                     <Dropdown.Item eventKey="Yes" onSelect={(eventKey, e) => this.onItemSelect(e, option)}>Yes</Dropdown.Item>
//                 </DropdownButton>
//             </div >

//         )
//     }
// }


// DropdownMenu.defaultProps = {
//     options: [],
//     placeholder: ''
// }

// export default DropdownMenu;


import React from 'react';
import { DropdownButton, Dropdown, Link, Item } from 'react-bootstrap';
import './dropdown.scss';

class DropdownMenu extends React.Component {
    constructor(props) {

        super(props)
        this.state = {
            title: this.props.title,
            value: this.props.value

        }
    }

    onItemSelect(e, option, eventKey) {
        this.setState({
            title: e.target.text,
            selOption: option,
            value: eventKey
        })
    }

    render() {
        let { title } = this.state
        let { options } = this.props

        return (
            <div className="dropdowncomponent">
                <DropdownButton onSelect={this.props.onSelect} disabled={this.props.disabled} title={this.props.title} value={this.props.value} className='dropdownButtom'>
                    {
                        options.map((option, i) => {
                            return <Dropdown.Item key={i} eventKey={i} onSelect={(eventKey, e) => { this.props.onItemSelect(e, option, eventKey) }} >{option}</Dropdown.Item>
                        })
                    }
                </DropdownButton>
            </div >

        )
    }
}


DropdownMenu.defaultProps = {
    options: [],
    placeholder: '',
    //  value: ""
}

export default DropdownMenu;

