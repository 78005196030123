import React, { Component } from "react";
import {} from "react-bootstrap";
import "./Highchart.scss";
import logo from "../../assets/images/logo.svg";
import Loader from "react-spinner-loader";
const Highcharts = require("react-highcharts");
var ReactHighstock = require("react-highcharts/ReactHighstock.src");

class Highchart extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: true,
      type: "line",
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphDate !== this.props.graphDate ||
      prevProps.graphTouchTestDate !== this.props.graphTouchTestDate ||
      prevProps.amData !== this.props.amData ||
      prevProps.pmData !== this.props.pmData
    ) {
      this.setState({ isLoading: false });
    }
  }

  processChartData(data, graphDate) {
    console.log("data of graph : ", data);
    return data.map((res) => ({
      ...res,
      x: graphDate.indexOf(res._id), // Assuming res._id is the date string
      y: Number(res.y),
    }));
  }
  
  render() {
    let { amData, pmData, graphDate, graphTouchTestDate, graphTouchTestData } =
      this.props;

    // Process the data
    const processedAmData = amData
      ? this.processChartData(amData, graphDate)
      : [];
    const processedPmData = pmData
      ? this.processChartData(pmData, graphDate)
      : [];

    console.log("graph Data: ", {
      graphDate,
      processedAmData,
      processedPmData,
    });

    // Determine the data series and categories
    let dataSeries = [];
    let graphDateData = [];
    let title = "";

    if (graphTouchTestData === undefined) {
      title = "In Minutes";
      graphDateData = graphDate;
      dataSeries = [
        {
          name: "AM",
          data: processedAmData,
        },
        {
          name: "PM",
          data: processedPmData,
        },
      ];
    } else {
      title = "No. of Touches";
      graphDateData = graphTouchTestDate;
      dataSeries = [
        {
          name: "TouchTest",
          data: graphTouchTestData,
        },
      ];
    }

    console.log("Graph Date Data: ", graphDateData);
    console.log("dataSeries: ", dataSeries);

    const chartConfig = {
      chart: {
        renderTo: "highchartcontainer",
        type: "column",
        height: 400,
        reflow: true,
        panning: true,
      },
      title: {
        text: null,
      },
      scrollbar: {
        enabled: true,
        liveRedraw: false,
      },
      navigator: {
        enabled: false,
      },
      subtitle: {
        text: null,
      },
      rangeSelector: {
        enabled: false,
      },
      xAxis: {
        type: "category",
        lineColor: "#e6e6e6",
        tickLength: 1,
        tickmarkPlacement: "on",
        categories: graphDateData,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: title,
        },
        stackLabels: {
          enabled: true,
          style: { fontWeight: "bold" },
        },
        lineColor: "#e6e6e6",
        lineWidth: 1,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            formatter: function () {

              // for hide the 0 datalabels

              if (this.y === 0) {
                return ""; // Hide data label if the value is 0
              }

              if (this.point.isOveruse) {
                return (
                  (this.point.isPhotos
                    ? '<span className="icon-camera icon-css"></span>'
                    : "") +
                  '<span className="icon-warning icon-css"></span>' +
                  `<br><span>${this.y}</span>`
                );
              } else {
                return (
                  (this.point.isPhotos
                    ? '<span className="icon-camera icon-css"></span>'
                    : "") + `<br><span>${this.y}</span>`
                );
              }
            },
          },
        },
      },
      colors: ["#A77CA8", "#590459"],
      tooltip: {
        enabled: false,
      },
      series: dataSeries,
    };

    return (
      <div id="newhighchartcontainer">
        <Loader
          id="cover-spin"
          className="isLoading"
          show={this.state.isLoading}
          type="box"
          message="Please wait while data is loading..."
        />
        <Highcharts config={chartConfig}></Highcharts>
      </div>
    );
  }
}

export default Highchart;
