import React from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import './freewayspace.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu'
import Header from '../../../components/header/header';
import FreespaceImg from '../../../assets/images/teethFreespace.svg';
import ReactPlayer from "../../../components/videoplayer/videoplayer";
import HomeSideMenu from '../../../components/homesidebar/homesidebar';
import Button from '../../../components/Button/button'

class FreewaySpace extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isViewFrom: this.props.history.location.state.isViewFrom
        }
        { this.state.isViewFrom === 'homeFreewayspace' ? setLocalActiveClass("HomeInstructions") : setLocalActiveClass("FreeWay") }

    }

    onNextClick() {
        this.props.history.push('/5mmfittingstep1', { isViewFrom: '5mm Touch Test' })
    }
    onGoClick() {

        this.props.history.push("/instructions", { isViewFrom: this.state.isViewFrom })

    }

    render() {
        return (
            <div className='main-FreeWay'>
                <Header></Header>
                {this.state.isViewFrom === 'homeFreewayspace' ? <HomeSideMenu {...this.props} ></HomeSideMenu> : <SideMenu {...this.props}></SideMenu>}

                {/* <SideMenu {...this.props}></SideMenu> */}
                <div className='inner-FreeWay'>
                    <Scrollbars>
                        <div className='main-Container'>
                            <div className='img-Container'>
                                <img src={FreespaceImg} className="logo" alt="logo" />
                            </div>
                            <h2 className='paddingB15'>Freeway Space</h2>
                            <div className='content-Section'>
                                <h5 className='paddingB15'>One of the key elements to the success of Oralift is understanding the Freeway Space. The video below will help you understand this and you will need your ruler to measure how wide you can open your mouth.</h5>
                            </div>
                            {/* <VideoPlayer></VideoPlayer> */}
                            <ReactPlayer url={"https://vimeo.com/356896413/3b863cdf08"} controls={true}></ReactPlayer>
                            {this.state.isViewFrom === 'homeFreewayspace' ? <Button title='go to instruction'
                                onClick={() => this.onGoClick()}
                            ></Button> :
                                <span className='paddingB15 floatR' ><span onClick={() => this.onNextClick()} className='highlighted textCapital nextLink'>next<span className='icon-right icon-css'></span></span></span>

                            }
                        </div>
                    </Scrollbars>
                </div>
            </div>

        )
    }
}




export default FreewaySpace;

