import React from 'react';
import { Toast } from 'react-bootstrap';
import './toastcomponent.scss';

class ToastComponent extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { isToast, toastMessage, errorToast, show, isWarning, delay, onClose, toastTitle, autohide, message, iconClass } = this.props;

    return (
      <div className='toast-Section'>
        {/* {isToast && */}
        <Toast onClose={this.props.onClose} show={show} delay={delay} autohide={autohide}>
          <div className='toast-z'>
            <span className={isWarning ? "icon-css icon-warning" : isWarning === '' ? '' : "icon-css icon-checked"} onClick={this.props.onClick}></span>
            <h5 className={isWarning || isWarning === '' ? "errtextFail" : 'errtextSuccess'}>{message}</h5>
          </div>
        </Toast>
        {/* } */}
      </div>

    )
  }
}

ToastComponent.defaultProps = {
  isWarning: false,
  message: '',
  // isToast: false,
  show: false,
}

export default ToastComponent