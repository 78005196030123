import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import WebService from "../../utils/Webservice"
import { getStoredData, setStoredData } from "../../utils/helper";
import "./adminsidebar.scss";
import logo from "../../assets/images/logo.svg";
import DropdownMenu from '../dropdown/dropdown';

class AdminSideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMorepopup: this.props.showMorePopup || false,
      // _id: localStorage.getItem("userId")
      _id: getStoredData("userId")
    }
  }
  componentDidMount() {
    this.getUserWebservice()

  }
  async getUserWebservice() {
    try {
      let obj = {
        _id: this.state._id,
      };
      let response = await WebService.getUserDetails(obj);
      this.setState({ isLoading: false });
      if (response.status) {
        // console.log("Success::" + response.status);
        this.handleSuccessResponse(response);
      } else {
        // this.handleErrorResponse(response);
        // console.log("Error::" + response.status);
      }
    } catch (error) {
      // console.log(error);
    }
  }
  handleSuccessResponse(response) {
    let { sUserFirstName, sUserLastName, sPhoneNumber, sEmailId, sProfilePhoto, nVerificationCode } = response.data

    this.setState({
      sFullName: sUserFirstName === undefined ? "john doe" : sUserFirstName + " " + sUserLastName,
      sEmail: sEmailId === undefined ? "johndoe@gmail.com" : sEmailId,
      sPhoneNumber: sPhoneNumber === undefined ? "+401-44-668-0938" : sPhoneNumber,
      adminProfile: sProfilePhoto === undefined ? '' : sProfilePhoto,
      nVerificationCode: nVerificationCode === undefined ? '' : nVerificationCode
    })

  }
  toggleActive() {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  };
  toggleClass(element, className) {
    if (!element || !className) {
      return;
    }

    var classString = element.className,
      nameIndex = classString.indexOf(className);
    if (nameIndex == -1) {
      classString += " " + className;
    } else {
      classString =
        classString.substr(0, nameIndex) +
        classString.substr(nameIndex + className.length);
    }
    element.className = classString;
  }

  onSideItemClick(page) {

    localStorage.setItem("menuActiveitem", page);
    localStorage.setItem("MainActiveClass", "");
    this.setState({
      activeClass: page,
      MainActiveClass: "",
      hover: false,
    });

    switch (page) {
      case "AdminPhoto":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/photos", { isViewFrom: "adminPhoto" });
        break;
      case "Progress":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/progress", { isViewFrom: "adminDashboard" });
        break;

      case "HomeProfile":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/profile", { isViewFrom: "adminProfile" });
        break;
      case "ViewQuestionnaire":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/ViewQuestionnaire", { isViewFrom: "adminQuestionnaire" });
        break;

      case "ViewPreferences":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/ViewPreferences", { isViewFrom: "adminPreferences" });
        break;
      case "Agreement":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/agreement", { isViewFrom: "adminAgreement" });
        break;
      case "viewQuestionnaireBiweekly":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/viewQuestionnaireBiweekly", { isViewFrom: "adminQuestionnaire-Biweekly" });
        break;
      case "freewaySpaceView":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/freewayspaceview", { isViewFrom: "adminFreewaySpace" });
        break;
      case "ViewReport":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/viewreport", { isViewFrom: "adminViewReport" });
        break;
      case "StartingPoint":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/startingPoint", { isViewFrom: "adminStartingPoint" });
        break;

    }

  }


  render() {
    let { } = this.props;
    let { sFullName, sEmail, sPhoneNumber, adminProfile } = this.state
    let activeClass = localStorage.getItem("menuActiveitem");

    return (
      <div className="home-AdminsideMenu">
        <div className="sideMenuOptions">

          <div className='grid-Details'>
            <div className='img-Profile'>
              <img src={adminProfile === '' ? require('../../assets/images/profile.png') : adminProfile}></img>
            </div>
            <div className='user-Details'>
              <h2>{this.state.sFullName}</h2>
              <p className='innerTitle lightG'>{this.state.sEmail}</p>
              <p className='innerTitle lightG'>{this.state.sPhoneNumber}</p>
              <p className='innerTitle lightG'>{this.state.nVerificationCode}</p>
            </div>

            <span
              onClick={() => this.toggleClass(document.body, "toggleOn")}
              className="icon-close"
            ></span>
          </div>
          <Scrollbars className="menuScroll">
            <ul className="innerMenu">
              <li className={activeClass == "Progress" && "active"}>
                <a onClick={() => this.onSideItemClick("Progress")}>
                  Progress
                </a>
              </li>
              <li className={activeClass == "AdminPhoto" && "active"}>
                <a onClick={() => this.onSideItemClick("AdminPhoto")}>
                  Photos
                </a>
              </li>
              <li className={activeClass == "HomeProfile" && "active"}>
                <a onClick={() => {
                  this.setState({ showMorepopup: !this.state.showMorepopup })
                  !window.location.href.includes('profile') && this.props.history.push('/profile', { isViewFrom: "adminProfile" })
                }}>
                  Profile

                  < span className={activeClass == "HomeProfile" ? (this.state.showMorepopup ? 'icon-down  icon-css icon-up ' : 'icon-down icon-css icon-right') : 'icon-right icon-css'} ></span>
                </a>
                {this.state.showMorepopup &&
                  <div className="moreOptions">
                    <ul className='moreUl'>
                      <li className={activeClass === 'ViewQuestionnaire' ? "moreLi active" : "moreLi"}><a onClick={() => { this.onSideItemClick("ViewQuestionnaire") }} > Questionnaire</a></li>
                      <li className={activeClass === 'StartingPoint' ? "moreLi active" : "moreLi"}><a onClick={() => { this.onSideItemClick("StartingPoint") }}>Your starting point</a></li>
                      <li className={activeClass === 'Agreement' ? "moreLi active" : "moreLi"}><a onClick={() => { this.onSideItemClick("Agreement") }}>Agreement</a></li>
                      <li className={activeClass === 'viewQuestionnaireBiweekly' ? "moreLi active" : "moreLi"}><a onClick={() => { this.onSideItemClick("viewQuestionnaireBiweekly") }}>Questionnaire - Biweekly</a></li>
                      <li className={activeClass === 'adminFreewaySpace' ? "moreLi active" : "moreLi"}><a onClick={() => { this.onSideItemClick("freewaySpaceView") }}>Freeway - Space</a></li>
                      <li className={activeClass === 'ViewReport' ? "moreLi active" : "moreLi"}><a onClick={() => { this.onSideItemClick("ViewReport") }}>Signs of overuse</a></li>
                    </ul>
                  </div>
                }
              </li>
            </ul>
          </Scrollbars>
        </div>
      </div >
    );
  }
}
export default AdminSideMenu;
