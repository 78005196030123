import React from 'react';
import { setLocalActiveClass, setFilledFlags } from "../../../utils/helper";
import './setpreferences.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu'
import HomeSideMenu from '../../../components/homesidebar/homesidebar'

import { Row, Col } from 'react-bootstrap';
import Header from '../../../components/header/header';
import Button from '../../../components/Button/button'
import SettingImg from '../../../assets/images/settings.svg';
import DropdownMenu from '../../../components/dropdown/dropdown';
import WebService from "../../../utils/Webservice"
import CONSTANTS from '../../../utils/constants';

const optionsAM = ['01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00']
const optionsPM = ['12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '24:00']
const optionsSession = ['With my AM Session', 'With my PM Session']


class SetPreferences extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isViewFrom: this.props.history.location.state.isViewFrom,
            titleAm: '',
            titlePm: '',
            titleSession: '',
            value: '',
            _id: (this.props.history.location.state.isViewFrom === 'adminPreferences' || this.props.history.location.state.isViewFrom === 'adminQuestionnaire') ? localStorage.getItem("userId") : localStorage.getItem("_id"),
            disableBtnAm: false,
            disableBtnPm: false,
            disableBtnTouchPreference: false
        }

        this.props.history.location.state.isViewFrom === 'homeSetPreferences' ? setLocalActiveClass("ViewPreferences")
            : setLocalActiveClass("SetPreferences")
        // setLocalActiveClass("SetPreferences")
        console.log(localStorage.getItem("userId"), "userId");

    }

    componentDidMount() {
        this.getUserPreferencesWebservice()
    }
    handleSelectEventAm(eventKey) {
        this.setState({ sEthnicity: eventKey })
    }

    onItemSelectValueAm(e, option, eventKey) {
        (e.target.text.length > 0 ? this.setState({ disableBtnAm: false }) : this.setState({ disableBtnAm: true }))

        this.setState({
            titleAm: e.target.text,
            selOption: option,
            value: eventKey,
        })
    }
    handleSelectEventPm(eventKey) {
        this.setState({ sEthnicity: eventKey })
    }

    onItemSelectValuePm(e, option, eventKey) {
        (e.target.text.length > 0 ? this.setState({ disableBtnPm: false }) : this.setState({ disableBtnPm: true }))

        this.setState({
            titlePm: e.target.text,
            selOption: option,
            value: eventKey,
        })
    }

    handleSelectEventSession(eventKey) {
        this.setState({ sEthnicity: eventKey })
    }

    onItemSelectValueSession(e, option, eventKey) {
        (e.target.text.length > 0 ? this.setState({ disableBtnTouchPreference: false }) : this.setState({ disableBtnTouchPreference: true }))

        this.setState({
            titleSession: e.target.text,
            selOption: option,
            value: eventKey,
        })
    }
    onPreferencesClick() {
        this.saveUserPreferenceWebservice()
    }
    async getUserPreferencesWebservice() {
        try {
            let obj = {
                _id: this.state._id,
            };
            let response = await WebService.getUserDetails(obj);
            this.setState({ isLoading: false });
            if (response.status) {
                console.log(response.data);
                this.handleSuccessResponse(response);
            } else {
                this.handleErrorResponse(response);
                console.log("Error::" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }


    handleSuccessResponse(response) {
        let { amPreference, pmPreference, nPrefered } = response.data

        amPreference !== undefined ? this.setState({ disableBtnAm: false }) : this.setState({ disableBtnAm: true })
        amPreference !== undefined ? this.setState({ disableBtnPm: false }) : this.setState({ disableBtnPm: true })
        amPreference !== undefined ? this.setState({ disableBtnTouchPreference: false }) : this.setState({ disableBtnTouchPreference: true })

        console.log(amPreference === null, "amPreference")
        console.log(pmPreference, "pmPreference")
        console.log(nPrefered, "nPrefered")

        this.setState({
            titleAm: optionsAM[optionsAM.indexOf(amPreference)],
            titlePm: optionsPM[optionsPM.indexOf(pmPreference)],
            titleSession: optionsSession[nPrefered]
        })
    }
    async saveUserPreferenceWebservice() {
        console.log(this.state._id)
        let { titlePm, titleAm, titleSession
        } = this.state
        this.setState({ isLoading: true });
        try {
            let obj = {
                _id: this.state._id,
                amPreference: titleAm,
                pmPreference: titlePm,
                nPrefered: optionsSession.indexOf(titleSession)
            };

            let response = await WebService.saveUserPreferences(obj);
            this.setState({ isLoading: false });

            if (response.status) {
                console.log("response" + response.status);
                setFilledFlags(CONSTANTS.KEYS.KeyPreferencesFilled, true)
                this.props.history.push('/savepreferences', { isViewFrom: this.state.isViewFrom })

            } else {
                console.log("response" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }
    onRetakeClick() {
        this.props.history.push('/5mmtouchtest')
    }
    render() {

        let { titleAm, titlePm, titleSession, value } = this.state


        let amTitle = titleAm == null || undefined ? "Select" : this.state.titleAm
        let pmTitle = titlePm == null || undefined ? "Select" : this.state.titlePm
        let sessionTitle = titleSession == null || undefined ? "Select" : this.state.titleSession

        let disableBtn = (this.state.disableBtnAm || this.state.disableBtnPm || this.state.disableBtnTouchPreference) ? true : false
        console.log(disableBtn);

        return (
            <div className='main-SetPreferences'>
                <Header></Header>
                {this.state.isViewFrom === "homeViewQuestionnaire" || this.state.isViewFrom === "homeSetPreferences" ? <HomeSideMenu {...this.props} showMorePopup={true}></HomeSideMenu> : <SideMenu {...this.props}></SideMenu>}
                <div className='inner-SetPreferences'>
                    <Scrollbars className='set-Scroll'>
                        <div className='main-Container'>
                            <div className='img-Container'>
                                <img src={SettingImg} className="logo" alt="logo" />
                            </div>
                            <h2 className='paddingB15'>Set Your Preferences
                            <div className='tool-Tips'>
                                    {/* <div class="tooltip--bottom" data-tooltip="For every wear session first 5 minutes can be used as a touch test, it will continue as a wear session but feature to record touches will be disabled after 5 minutes."><span className='icon-info'></span></div> */}
                                    <a className="tip icon-info cursor"><span>Your programme is tailored to help you get the best possible results and you can choose what time you would like to wear your device, and at which session you would like to do your Touch Test. You can always change these preferences at a later date if you find they do no suit your lifestyle. You may like to wear your device when you are driving, at your computer, watching tv or at the gym. The choice is yours! If you wear it while at the gym, you may even find your performance improves. We will send you reminders at the times you have chosen.</span></a>
                                </div>
                            </h2>


                            <div className='only-Content' >
                                <p className='contents'>
                                    When you experience pain, discomfort or trembling of the muscles, this is an indication that your muscles are tensed and are having difficulty adapting your lower jaw to a new position. The time when you had to stop your test is used to set your programme. In your case, you’ll have to proceed more slowly than a person whose muscles are able to adapt very quickly. Not everyone can run a mile if they have never run before. Some people will be exhausted after only 100 yards! Oralift is a lifetime programme and you should not try to proceed too quickly. This does not mean that you will not get good results. In fact, the opposite may be the case. Tense muscles are usually the indication of stress, which can lead to clenching and grinding of teeth, which can cause premature ageing.
                                </p>
                            </div>
                            <div className='question-Form'>
                                <Row>
                                    <Col lg={12} md={12} sm={12} className='main-Title'>
                                        <h5>What time of day do you prefer to wear your Oralift device</h5>
                                    </Col>
                                    <Col xl={3} lg={4} md={4} sm={6} className='paddingB15'>
                                        <label className='cust-Label'>AM</label>
                                        <DropdownMenu onSelect={this.handleSelectEventAm.bind(this)} onItemSelect={this.onItemSelectValueAm.bind(this)} title={amTitle} options={optionsAM}></DropdownMenu>
                                    </Col>
                                    <Col xl={3} lg={4} md={4} sm={6} className='paddingB15'>
                                        <label className='cust-Label'>PM</label>
                                        <DropdownMenu onSelect={this.handleSelectEventPm.bind(this)} onItemSelect={this.onItemSelectValuePm.bind(this)} title={pmTitle} options={optionsPM}></DropdownMenu>
                                    </Col>
                                </Row>
                            </div>
                            <div className='content-Section'>
                                <h5 className='paddingB15'>
                                    <span className='dots-Css'></span>You should be relaxed when you do your touch test
                            </h5>
                                <h5 className='paddingB15'>
                                    <span className='dots-Css'></span>As you only need to complete one touch test on the day you wear Oralift, would you rather do it as a part of your AM or PM session
                            </h5>
                            </div>
                            <div className='question-Form paddingB0'>
                                <Row>
                                    <Col xl={6} lg={8} md={8} sm={12}>
                                        <label className='cust-Label'>Touch Test Preference</label>
                                        <DropdownMenu onSelect={this.handleSelectEventSession.bind(this)} onItemSelect={this.onItemSelectValueSession.bind(this)} title={sessionTitle} options={optionsSession}></DropdownMenu>

                                    </Col>
                                </Row>
                            </div>
                            <Button title='save my preferences' disabled={disableBtn} onClick={() => this.onPreferencesClick()}></Button>
                        </div>
                    </Scrollbars>
                </div>
            </div >

        )
    }
}




export default SetPreferences;

