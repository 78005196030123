import React from 'react';
import './weeklyphotos.scss';
import { Row, Col, Container } from 'react-bootstrap';
import CheckedImg from '../../assets/images/checked.svg';
import UnCheckedImg from '../../assets/images/unchecked.svg';
import loaderImg from '../../assets/images/loaderImg.svg';

import { el } from 'date-fns/esm/locale';

const moment = require('moment');
class WeeklyPhotos extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedImg: '',
            firstImg: '',
            secImg: '',
            firstPhotoSelected: '',
            secondPhotoSelected: '',
            photosResponse: '',
            selectedImages: [], isChecked: this.props.isChecked,
            isCheckedDisabled: false,
            imageStatus: false,
            imgStatusSmile: false,
            imgStatusLeft: false,
            imgStatusRight: false,
            imgStatusLeftBody: false,
            imgStatusRightBody: false,
        }
    }

    onCheckedClick(index, selectedImg) {
        let { selectedImages } = this.state
        let selection = index + "_" + selectedImg
        this.props.disableCompareButton(true)

        if (this.disabledSelection(index, selectedImg)) {
            return
        }
        if (selectedImages.includes(selection)) {
            let indexOf = selectedImages.indexOf(selection)
            selectedImages.splice(indexOf, 1)
        } else {
            if (selectedImages.length != 2) {
                selectedImages.push(selection)
            }
        }
        this.setState({
            selectedImages: selectedImages,
        })
        if (selectedImages.length === 2) {
            this.props.setData(this.state.selectedImages)
            this.props.disableCompareButton(false)
        }

    }

    disabledSelection(index, name) {
        let { selectedImages } = this.state
        if (selectedImages.length === 0) {
            return false
        }
        if (selectedImages.length === 2) {
            selectedImages.every((value) => {
                let temp = value.split("_");

                return (temp[0] === index || temp[1] === name)
            })
        } else {
            let temp = selectedImages[0].split("_");
            if ((temp[0] === index && temp[1] === name) || (temp[1] === name)) {
                return false
            } else {
                return true
            }
        }

    }
    displayImage(image) {
        switch (image) {
            case 'Front':
                if (!this.state.imageStatus) {
                    return (
                        <img className="loaderImg" src={loaderImg} />
                    )
                }
                break;
            case 'Smile':
                if (!this.state.imgStatusSmile) {
                    return (
                        <img className="loaderImg" src={loaderImg} />
                    )
                }
                break;
            case 'Left':
                if (!this.state.imgStatusLeft) {
                    return (
                        <img className="loaderImg" src={loaderImg} />
                    )
                }
                break;
            case 'Right':
                if (!this.state.imgStatusRight) {
                    return (
                        <img className="loaderImg" src={loaderImg} />
                    )
                }
                break;
            case 'LeftBody':
                if (!this.state.imgStatusLeftBody) {
                    return (
                        <img className="loaderImg" src={loaderImg} />
                    )
                }
                break;
            case 'RightBody':
                if (!this.state.imgStatusRightBody) {
                    return (
                        <img className="loaderImg" src={loaderImg} />
                    )
                }
                break;
            default:
                break;
        }


    }
    componentDidUpdate() {
        if (this.props.defaultStateVal) {
            this.state.selectedImages = []
            this.props.defaultState(false)
        }
    }

    render() {
        let { isChecked, selectedImg, isDisabled, defaultStateVal, title, photosResponse } = this.props
        return (
            <div className='date-wise-Photos'>
                {
                    photosResponse.map((item, i) => (
                        <div>
                            <div className='date-Section'>
                                <h5 className='paddingB10'>
                                    {/* {moment(new Date(photosResponse[i].dCreatedAt).toString()).format("dddd MMMM DD")} */}
                                    {moment(new Date(photosResponse[i].dCreatedAt).toString()).format("dddd MMMM DD")}
                                </h5>
                            </div>
                            <div className='main-photo-Section'>
                                <Container>
                                    <Row>
                                        <Col xl={2} lg={4} md={4} sm={6} xs={6} className="padding0">
                                            <div className='main-Img-Section' onClick={() => isChecked && this.onCheckedClick(i, "Front", "Front", photosResponse[i].sNonSmilePhoto)}>
                                                {photosResponse[i].sNonSmilePhoto === undefined || isChecked && <img src={this.state.selectedImages.includes(i + "_Front") ? CheckedImg : UnCheckedImg} className="select-Photo" alt="logo" />}
                                                <div className='preview-Section'>
                                                    {(photosResponse[i].sNonSmilePhoto == undefined || photosResponse[i].sNonSmilePhoto === "") ? <img resizemode="stretch" onLoad={() => this.setState({ imageStatus: true })} src={require("../../assets/images/Front Face.png")} /> : <img resizeMode='stretch' src={photosResponse[i].sNonSmilePhoto} onLoad={() => this.setState({ imageStatus: true })} />}
                                                    <div className='link-Section'>
                                                        <span className='innerTitle'>Front</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={2} lg={4} md={4} sm={6} xs={6} className="padding0">
                                            <div className='main-Img-Section' onClick={() => isChecked && this.onCheckedClick(i, "Smile", "Smile", photosResponse[i].sSmilePhoto)}>
                                                {photosResponse[i].sSmilePhoto === undefined || isChecked && <img src={this.state.selectedImages.includes(i + "_Smile") ? CheckedImg : UnCheckedImg} className="select-Photo" alt="logo" />}
                                                <div className='preview-Section' >
                                                    {this.displayImage("Smile")}
                                                    {photosResponse[i].sSmilePhoto == undefined ? <img resizemode="stretch" onLoad={() => this.setState({ imgStatusSmile: true })} src={require("../../assets/images/Smile Face.png")} /> : <img resizeMode='stretch' src={photosResponse[i].sSmilePhoto} onLoad={() => this.setState({ imgStatusSmile: true })} />}
                                                    <div className='link-Section'>
                                                        <span className='innerTitle'>Smile</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={2} lg={4} md={4} sm={6} xs={6} className="padding0">
                                            <div className='main-Img-Section' onClick={() => isChecked && this.onCheckedClick(i, "Left", "Left", photosResponse[i].sLeftPhoto)}>
                                                {photosResponse[i].sLeftPhoto === undefined || isChecked && <img src={this.state.selectedImages.includes(i + "_Left") ? CheckedImg : UnCheckedImg} className="select-Photo" alt="logo" />}
                                                <div className='preview-Section'>
                                                    {this.displayImage("Left")}
                                                    {photosResponse[i].sLeftPhoto == undefined || "" ? <img resizemode="stretch" onLoad={() => this.setState({ imgStatusLeft: true })} src={require("../../assets/images/Left Face.png")} /> : <img resizeMode='stretch' src={photosResponse[i].sLeftPhoto} onLoad={() => this.setState({ imgStatusLeft: true })} />}
                                                    <div className='link-Section'>
                                                        <span className='innerTitle'>Left</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={2} lg={4} md={4} sm={6} xs={6} className="padding0">
                                            <div className='main-Img-Section' onClick={() => isChecked && this.onCheckedClick(i, "Right", "Right", photosResponse[i].sRightPhoto)}>
                                                {photosResponse[i].sRightPhoto === undefined || isChecked && <img src={this.state.selectedImages.includes(i + "_Right") ? CheckedImg : UnCheckedImg} className="select-Photo" alt="logo" />}
                                                <div className='preview-Section'>
                                                    {this.displayImage("Right")}
                                                    {photosResponse[i].sRightPhoto == undefined || "" ? <img resizemode="stretch" onLoad={() => this.setState({ imgStatusRight: true })} src={require("../../assets/images/Right Face.png")} /> : <img resizeMode='stretch' src={photosResponse[i].sRightPhoto} onLoad={() => this.setState({ imgStatusRight: true })} />}
                                                    <div className='link-Section'>
                                                        <span className='innerTitle'>Right</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={2} lg={4} md={4} sm={6} xs={6} className="padding0">
                                            <div className='main-Img-Section' onClick={() => isChecked && this.onCheckedClick(i, "FullLeft", "FullLeft", photosResponse[i].sFullLeftPhoto)}>
                                                {photosResponse[i].sFullLeftPhoto === undefined || isChecked && <img src={this.state.selectedImages.includes(i + "_FullLeft") ? CheckedImg : UnCheckedImg} className="select-Photo" alt="logo" />}
                                                <div className='preview-Section'>
                                                    {this.displayImage("LeftBody")}
                                                    {photosResponse[i].sFullLeftPhoto == undefined ? <img resizemode="stretch" onLoad={() => this.setState({ imgStatusLeftBody: true })} src={require("../../assets/images/Full Left.png")} /> : <img resizeMode='stretch' src={photosResponse[i].sFullLeftPhoto} onLoad={() => this.setState({ imgStatusLeftBody: true })} />}
                                                    <div className='link-Section'>
                                                        <span className='innerTitle'>Full Left</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={2} lg={4} md={4} sm={6} xs={6} className="padding0">
                                            <div className='main-Img-Section' onClick={() => isChecked && this.onCheckedClick(i, "FullRight", "FullRight", photosResponse[i].sFullRightPhoto)}>
                                                {photosResponse[i].sFullRightPhoto === undefined || isChecked && <img src={this.state.selectedImages.includes(i + "_FullRight") ? CheckedImg : UnCheckedImg} className="select-Photo" alt="logo" />}
                                                <div className='preview-Section'>
                                                    {this.displayImage("RightBody")}
                                                    {
                                                        photosResponse[i].sFullRightPhoto === undefined || photosResponse[i].sFullRightPhoto == '' ? <img resizemode="stretch" src={require("../../assets/images/Full Right.png")} onLoad={() => this.setState({ imgStatusRightBody: true })} /> :
                                                            <img resizeMode='stretch' src={photosResponse[i].sFullRightPhoto} onLoad={() => this.setState({ imgStatusRightBody: true })} />}
                                                    <div className='link-Section'>
                                                        <span className='innerTitle'>Full Right</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    ))}
            </div>
        )
    }
}




export default WeeklyPhotos;

