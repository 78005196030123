import React from 'react';
import './timer.scss';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import moment from 'moment';
import Button from '../../components/Button/button';
import Toastcomponent from "../../components/toastcomponent/toastcomponent";
import { twoDigitNumber, setStoredData, getStoredData } from '../../utils/helper';
import { de } from 'date-fns/esm/locale';
import TextField from "../../components/textfield/textfield";

class TimerBar extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            count: getStoredData('defaultTouchCount') === 'undefined' || getStoredData('defaultTouchCount') === null || getStoredData('defaultTouchCount') === '' ? 0 : Number(getStoredData('defaultTouchCount')),
            time: getStoredData('defaultTime') === 'undefined' || getStoredData('defaultTime') === null || getStoredData('defaultTime') === '' ? "00:00" : getStoredData('defaultTime'),
            on: false,
            title: "start session",
            showToast: false,
            percentage: getStoredData('defaultSetProgress') === 'undefined' || getStoredData('defaultSetProgress') === '' || getStoredData('defaultSetProgress') === null ? '' : getStoredData('defaultSetProgress'),
            setTime: getStoredData('defaultProgressTime') === 'undefined' || getStoredData('defaultProgressTime') === '' || getStoredData('defaultProgressTime') === null ? 0.0 : getStoredData('defaultProgressTime'),
            touchDisable: false,
            linkDisable: false,
            nMinutes: '',
            isViewFrom: '',
            disableEnableBtn: false
        }
        // console.log(getStoredData('defaultTime'));

    }
    onTimerStart() {
        let secPer = 0
        let minPer = 0
        let secPerHome = 0
        let defaultTimerData
        if (this.state.setTime !== 0 || this.state.setTime === '') {
            defaultTimerData = this.state.setTime.split('.')
            if (defaultTimerData[0] > 0) {
                minPer = Number(defaultTimerData[0])
            } else {
                secPer = 0
            }
            if (defaultTimerData[1] > 0) {
                secPer = Number(defaultTimerData[1])
            } else {
                minPer = 0
            }
        }
        if (this.state.percentage !== '' || this.state.percentage !== 'undefined') {
            secPerHome = Number(this.state.percentage)
        }
        let sec = 0;
        let min = 0;
        let minutes
        if (this.state.title == 'Start' || this.state.title == 'start session') {
            let newTitle = this.state.title === 'Start' ? 'Stop' : 'stop session'
            this.setState({ linkDisable: true, title: newTitle, disableEnableBtn: true, isViewFrom: this.props.isViewFrom, touchDisable: !this.state.touchDisable, nMinutes: this.props.nMinutes }, () => {
                this.props.clickLink(this.state.linkDisable, this.state.title)
                minutes = (this.state.isViewFrom === 'home' ? this.state.nMinutes : 300)
                let totalTime = minutes.split(':')
                this.setState({ timerMin: totalTime[0], timerSec: totalTime[1] })
            })
            this.timerInterval = setInterval(() => {
                sec += 1;
                secPer += 1;
                secPerHome += 1
                if (sec == 60) {
                    sec = 0;
                    min += 1;
                    if (min == 60) {
                        clearInterval(this.timerInterval);
                    }
                }
                if (secPer === 60) {
                    secPer = 0;
                    minPer += 1;
                    if (minPer == 60) {
                        clearInterval(this.timerInterval);
                    }
                }


                if (secPerHome === 300) {
                    this.setState({ touchDisable: false })
                }
                this.setState({
                    time: twoDigitNumber(minPer) + ":" + twoDigitNumber(secPer),
                }, () => {
                    let timeData = twoDigitNumber(minPer) + '.' + twoDigitNumber(secPer)
                    this.props.redireactToReport(timeData, this.state.count)
                    this.setState({ setTime: twoDigitNumber(minPer) + '.' + twoDigitNumber(secPer), percentage: secPerHome })
                })


                if (minPer == this.state.timerMin && secPer == this.state.timerSec) {
                    clearInterval(this.timerInterval);
                    this.setState({ title: 'start session', time: "00:00", linkDisable: false, percentage: '', disableEnableBtn: false, touchDisable: true, icon: 'icon-right', setTime: twoDigitNumber(minPer) + '.' + twoDigitNumber(secPer) }, () => {
                        this.props.clickLink(this.state.linkDisable, this.state.title)
                        let timer = this.state.setTime
                        let touchCount = this.state.count
                        this.props.onSubmit(timer, touchCount, this.state.title, '')
                        this.setState({ time: "00:00", setTime: 0.0, percentage: '', count: 0 })
                    })
                }

            }, 1000);
        } else if (this.state.title == 'Stop') {
            this.setState({
                linkDisable: false,
                touchDisable: false,
                title: 'Next',
                icon: 'icon-right',
                show: true,
            }, () => { this.props.clickLink(this.state.linkDisable, this.state.title) })
            clearInterval(this.timerInterval);
        } else if (this.state.title === 'stop session') {
            this.setState({
                linkDisable: false,
                touchDisable: false,
                title: "start session",
                disableEnableBtn: false
            }, () => {
                this.props.clickLink(this.state.linkDisable, this.state.title)
                let timer = this.state.setTime
                let touchCount = this.state.count
                this.props.onSubmit(timer, touchCount, this.state.title, '')
                this.setState({ time: "00:00", setTime: 0.0, percentage: '', count: 0 })
            })
            clearInterval(this.timerInterval);
        }
        else {
            let timer = this.state.setTime
            let touchCount = this.state.count
            this.props.onSubmit(timer, touchCount, this.state.title, '')
        }
    }
    componentDidMount() {
        setStoredData("defaultProgressTime", '')
        setStoredData("defaultTouchCount", '')
        setStoredData("defaultSetProgress", '')
        setStoredData("defaultTime", '')
    }
    componentWillUnmount() {
        setStoredData("defaultProgressTime", this.state.setTime)
        setStoredData("defaultTouchCount", this.state.count)
        setStoredData("defaultSetProgress", this.state.percentage)
        setStoredData("defaultTime", this.state.time)
    }
    showToast() {
        this.setState({
            show: true
        })
        setTimeout(() => {
            this.setState({
                show: false
            })
        }, 300000)
    }

    onTouch() {
        this.setState({
            count: this.state.count + 1,
        })
    }

    render() {
        let {
            nMinutes, color, isViewFrom, disabled
        } = this.props
        // console.log(this.state.time, this.state.setTime, nMinutes.length);

        return (
            <div className='timer-Bar' >
                <div className="timerRadius">
                    {/* <div className="text-wrap">
                        <div class="badge">
                            <h1>
                                <span class="char1">T</span>
                                <span class="char2">o</span>
                                <span class="char3">u</span>
                                <span class="char4">c</span>
                                <span class="char5">h</span>
                                <span class="char6">T</span>
                                <span class="char7">e</span>
                                <span class="char8">s</span>
                                <span class="char9">t</span>

                            </h1>
                        </div>
                    </div> */}
                    <CircularProgressbar
                        minValue={0}
                        maxValue={3600}
                        value={this.state.percentage}
                        text={
                            `${this.state.percentage}%`
                        }
                        styles={
                            {
                                // Rotation of path and trail, in number of turns (0-1)
                                rotation: 0.25,

                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                strokeLinecap: 'butt',

                                // Text size
                                textSize: '16px',

                                // How long animation takes to go from one percentage to another, in seconds
                                pathTransitionDuration: 0.5,

                                // Can specify path transition in more detail, or remove it entirely
                                // pathTransition: 'none',

                                // Colors
                                // pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
                                textColor: '#f88',
                                trailColor: '#d6d6d6',
                                backgroundColor: '#3e98c7',
                                trail: {
                                    stroke: 'transparent',
                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                    strokeLinecap: 'butt',
                                    strokeWidth: "8",
                                },
                                path: {
                                    stroke: '#D84D95',
                                    strokeLinecap: 'butt',
                                    strokeWidth: "8",
                                },

                            }
                        }
                    />

                    <img src={require('../../assets/images/seconds.png')} className='sec-Img' />
                    <a className={Number(color) === 1 ? 'click-Button colorBgP' : 'click-Button colorBgS'} onClick={() => { this.state.touchDisable && this.onTouch() }} >
                        <div className='text-Section' >
                            <div className="text-wrap">
                                <div class="badge">
                                    <p>
                                        <span class="char1">T</span>
                                        <span class="char2">o</span>
                                        <span class="char3">u</span>
                                        <span class="char4">c</span>
                                        <span class="char5">h</span>
                                        <span class="char6">T</span>
                                        <span class="char7">e</span>
                                        <span class="char8">s</span>
                                        <span class="char9">t</span>

                                    </p>
                                </div>
                            </div>
                            <h2 className='main-title' > </h2>
                            <span className='content-Icon icon-css' > </span>
                            <h2 className='inner-title' > to record touches </h2>
                            <span className='time-Bar'> {this.state.time} </span>
                        </div>
                    </a>
                </div>
                <div className='touch-Detail alignC' >
                    <span className='no-Touches' > {this.state.count} </span>
                    <h2 className='no-text' > Touches </h2>
                </div >
                {isViewFrom === 'home' ?
                    <div className='alignC timerBtn'>
                        <Button title="Set Next Reminder" disabled={this.state.disableEnableBtn} onClick={() => this.props.onTimerStart()} ></Button>
                        <Button title={this.state.title} disabled={this.state.time === '00:00' && nMinutes === '00:00' || nMinutes.length === 0 ? true : false} onClick={() => this.onTimerStart()} > </Button>
                    </div> : <div className='alignC'>
                        <Button title={this.state.title} onClick={() => this.onTimerStart()} > </Button>
                    </div>
                }
            </div >
        )
    }
}


export default TimerBar;