import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../Button/button';
import './modalStep.scss';
import PropTypes from 'prop-types';

class ModalStep extends React.Component {
    constructor(props, context) {
        super(props, context);
    }


    render() {
        let { } = this.props
        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.show}
                className="stepcustomModal"

            >
                <Modal.Body>
                    <h5>You may not see improvements in all the areas you have selected, but studying your photographs may surprise you, and you should see improvements. Please study the section of Understanding Your Photos.</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide}>OK</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}



export default ModalStep;

