import React from 'react';
import { setLocalActiveClass, setFilledFlags, getStoredData, setStoredData } from "../../../utils/helper";
import './5mmtouchtest.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu'
import Header from '../../../components/header/header';
import Button from '../../../components/Button/button';
import TimerBar from '../../../components/timer/timer';
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import WebService from "../../../utils/Webservice"
import moment from 'moment';
import { getFormDataObject } from "../../../utils/helper"
import { withRouter } from 'react-router-dom'
import CONSTANTS from '../../../utils/constants';

class FiveMMTouchTest extends React.Component {
    constructor(props) {
        super(props)

        //  console.log(window.location.href)
        this.state = {
            _id: getStoredData("_id"),
            linkDisable: true,
            toaster: false,
            message: "",
            isShow: false,
            isViewFrom: this.props.history.location.state.isViewFrom,
            nDeviceType: this.props.history.location.state.isViewFrom === '3mm Touch Test' ? 0 : 1,
            nPrefered: moment(new Date(), 'ddd DD-MMM-YYYY,A').format('A') === 'AM' ? 0 : 1
        }
        { this.state.isViewFrom === "5mm Touch Test" ? setLocalActiveClass("5mmTouchTest") : setLocalActiveClass("3mmTouchTest") }
    }
    onGoClick() {
        this.props.history.push('#')
    }
    onManualClick() {
        {
            this.state.isViewFrom === '5mm Touch Test' ?
                this.props.history.push(('/manualtouchtest5mm'), { isViewFrom: "5mm Touch Test" }) :
                this.props.history.push(('/manualtouchtest5mm'), { isViewFrom: "3mm Touch Test" })
        }
    }
    showToast() {
        this.setState({
            show: true
        })
        setTimeout(() => {
            this.setState({
                show: false
            })
        }, 3000)
    }

    clickLink(linkDisable) {
        {
            linkDisable === true ? this.setState({ linkDisable: false }) :
                this.setState({ linkDisable: true })
        }
    }
    async  onSubmit(timer, touchCount, title) {

        let { nDeviceType, nPrefered } = this.state
        // nPrefered = moment(new Date(), 'ddd DD-MMM-YYYY,A').format('A')
        this.setState({ isLoading: true, linkDisable: true });
        try {
            let obj = {
                iUserId: this.state._id,
                nTouchCount: touchCount,
                sTotalTime: timer,
                nDeviceType: nDeviceType,
                nPrefered: nPrefered,
                dCreatedAt: new Date()
            };
            let response = await WebService.UserTouchTest(obj);
            this.setState({ isLoading: false });

            if (response.status) {
                nDeviceType == 1 ? setFilledFlags(CONSTANTS.KEYS.Key5mmTest, true) : setFilledFlags(CONSTANTS.KEYS.Key3mmTest, true)
                this.handleSuccessResponse(response, timer);
            } else {
                this.setState({ toaster: true, message: response.MESSAGES, isShow: true })
                console.log("response" + response.status);
            }
        } catch (error) {
            console.log(error);
        }

    }
    handleSuccessResponse(response, timer) {
        this.setState({ toaster: true, message: response.MESSAGES, isShow: true })
        setStoredData("touchTestId", response.data.touchTestId)


        if (timer < 5.0) {
            // {
            //     this.state.isViewFrom === '3mm Touch Test' ?
            //         this.props.history.push(('/5mmtestreport'), { isViewFrom: "3mm Touch Test" }) :
            //         this.props.history.push(('/5mmtestreport'), { isViewFrom: "5mm Touch Test" })
            // }
            {
                this.state.isViewFrom === '3mm Touch Test' ?
                    this.props.history.push(('/3mmtestreport'), { isViewFrom: "3mm Touch Test" }) :
                    this.props.history.push(('/5mmtestreport'), { isViewFrom: this.state.isViewFrom })
            }
        } else {
            // {
            //     this.state.isViewFrom === '3mm Touch Test' ?
            //         this.props.history.push(('/thankyou'), { isViewFrom: "3mm Touch Test" }) :
            //         this.props.history.push(('/3mmtouchtest'), { isViewFrom: "3mm Touch Test" })
            // }
            {
                this.state.isViewFrom === '3mm Touch Test' ?
                    this.props.history.push(('/thankyou'), { isViewFrom: "3mm Touch Test" }) :
                    this.props.history.push(('/3mmfittingstep1'), { isViewFrom: "3mm Touch Test" })
            }
        }
    }

    render() {

        let { time, location } = this.props
        let { isViewFrom } = location.state;
        let { message, toaster, isShow } = this.state
        // console.log(this.state.isViewFrom, "IsView FROM");

        return (
            <div className='main-5TouchTest'>
                {toaster && <ToastComponent message={message} show={isShow} onClose={() => this.setState({ toaster: false })} delay={3000} autohide >}></ToastComponent>}
                {/* {console.log(isViewFrom)} */}
                <Header></Header>
                <SideMenu {...this.props}></SideMenu>
                <div className='inner-5TouchTest'>
                    <Scrollbars>
                        <div className='main-Container '>
                            <h2 className='paddingB15 alignC paddingB title-Text'>{isViewFrom}
                                <div className='tool-Tips'>
                                    {/* <div class="tooltip--bottom" data-tooltip="For every wear session first 5 minutes can be used as a touch test, it will continue as a wear session but feature to record touches will be disabled after 5 minutes."><span className='icon-info'></span></div> */}
                                    <a className="tip icon-info cursor">
                                        <span className="info-detail">The timer has been set for 5 minutes, but you should stop if you get any signs of overuse.
                                        <p className="alignC margin0"><a href="" className="aLink">What are signs of overuse?</a></p>
                                            <p className="alignC"><a href="" className="aLink">Your Programme in 5 simple steps.</a></p>
                                        </span>
                                    </a>
                                </div>
                            </h2>
                            <div className='timer-Progessbar'>
                                {/* {...this.props} */}
                                <TimerBar title={"Start"} color={this.state.nDeviceType} clickLink={this.clickLink.bind(this)} onSubmit={this.onSubmit.bind(this)} {...this.props} ></TimerBar>
                            </div>
                            {/* <div className='alignC'>
                                <Button title='start' onClick={() => this.onGoClick()} ><span className='icon-play icon-css'></span></Button>
                            </div> */}
                            <div className='bottom-Section alignC'>
                                <span className={this.state.linkDisable ? "aLink" : 'disableLink'} onClick={() => { this.state.linkDisable && this.onManualClick() }}>I would like to complete my touch test with pen and paper</span>
                                <span className="bottom-Text" >Both option digital and manual are available</span>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </div>

        )
    }
}

export default FiveMMTouchTest;

