import React from 'react';
import './header.scss';
// import userProfile from "../../../assets/images/profile.png"
import userProfile from "../../assets/images/profile.png"

class Header extends React.Component {
    constructor(props) {

        super(props)
        this.state = {

        }
    }


    toggleClass(element, className) {
        if (!element || !className) {
            return;
        }

        var classString = element.className, nameIndex = classString.indexOf(className);
        if (nameIndex == -1) {
            classString += ' ' + className;
        }
        else {
            classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
        }
        element.className = classString;
    }


    render() {
        let { title } = this.props
        return (
            <div className='headerResponsive'>
                <span onClick={() => this.toggleClass(document.body, 'toggleOn')} className='icon-menu'>
                </span>
            </div>
        )
    }
}




export default Header;

