import React from 'react';
import './adminheader.scss';
import { getStoredData, setStoredData } from "../../utils/helper";
import { Badge } from 'react-bootstrap';
import AdminNotification from '../adminnotification/adminnotification';
import DropdownMenu from '../../components/dropdown/dropdown';
import logo from "../../assets/images/logo.svg";
import WebService from "../../utils/Webservice"

const options = ['My Profile', 'Change Password ', 'Logout',]
class AdminHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            notificationModal: false,
            adminFirstName: getStoredData("adminFirstName"),
            adminLastName: getStoredData("adminLastName"),
            adminProfile: getStoredData("adminProfile"),
            notificationCount: 0,
            responseData: [], notificationDataList: []
        }
    }
    componentDidMount() {
        this.getNotificationList()
    }
    async getNotificationList() {
        try {
            let response = await WebService.notificationList();
            this.setState({ isLoading: false });
            if (response.status) {
                this.handleSuccessResponse(response.data)
                // console.log("SUCCESS::" + response.status);
            } else {
                console.log("Error::" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }
    handleSuccessResponse(response) {
        this.setState({ notificationCount: response.notificationCount, responseData: response.notificationList })
    }
    toggleClass(element, className) {
        if (!element || !className) {
            return;
        }
        var classString = element.className, nameIndex = classString.indexOf(className);
        if (nameIndex == -1) {
            classString += ' ' + className;
        }
        else {
            classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
        }
        element.className = classString;
    }
    handleSelect(eventKey) {
        this.setState({ nMaritalStatus: eventKey })
    }
    onItemSelect(e, option, eventKey) {
        this.setState({
            title: e.target.text,
            selOption: option,
            value: eventKey,
        })
        switch (eventKey) {
            case "0":
                this.props.history.push("/adminprofile");
                break;
            case "1":
                this.props.history.push("/adminchangepwd");
                break;
            case '2':
                this.adminLogoutWebServices()
                localStorage.clear()
                break;

        }

    }
    onItemSelectValue(e, option, eventKey) {
        this.setState({
            sEthnicityTitle: e.target.text,
            selOption: option,
            value: eventKey,
        })
    }

    onNotificationClick() {
        let { hideParentModels } = this.props
        let notificationDataList = this.state.responseData
        this.setState({
            notificationModal: true,
            notificationDataList
        })
    }
    onNotificationClose() {
        this.setState({
            notificationModal: false
        })
    }
    async adminLogoutWebServices() {
        try {
            let obj = {
                _id: getStoredData('adminId'),
                sDeviceTokenWeb: '',
            };
            let response = await WebService.adminLogout(obj);
            this.setState({ isLoading: false });
            if (response.status) {
                this.props.history.push("/admin");
                //console.log("SUCCESS::" + response.status);
            } else {
                console.log("Error::" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }
    notificationClickRedirect(type, userId) {
        setStoredData("userId", userId)
        switch (type) {
            case 0:
                this.props.history.push('/profile', { isViewFrom: "adminProfile" })
                break;
            case 1:
                this.props.history.push("/photos", { isViewFrom: "adminPhoto" });
                break;
            case 2:
                this.props.history.push("/progress", { isViewFrom: "adminDashboard" });
                break;
            case 3:
                this.props.history.push("/viewQuestionnaireBiweekly", { isViewFrom: "adminQuestionnaire-Biweekly" });
                break;
            default:
                break;
        }
    }
    render() {
        let { adminFirstName, adminLastName, title, adminProfile } = this.state
        let adminName = adminFirstName + " " + adminLastName
        let adminDisplayName = title === null || title === undefined ? (adminName) : title
        let { notificationModal, notificationCount, responseData } = this.state

        return (
            <div className='Adminheader'>
                <span onClick={() => this.toggleClass(document.body, 'toggleOn')} className={window.location.href.includes('admindashboard') || window.location.href.includes('generateCode') ? '' : 'icon-menu menuCss'}>
                </span>
                <div className="img-Logo">
                    <a href="/admindashboard" className='img-Section'>
                        <img src={logo} className="logo" alt="logo" />
                    </a>

                    <a href="/admindashboard" className={window.location.href.includes('admindashboard') ? 'page-Title' : ' paddingR'}>Dashboard</a>
                    <a href="/generateCode" className={window.location.href.includes('generateCode') ? 'page-Title' : ' paddingR'}>Generate Code</a>
                </div>
                <div className='main-header'>
                    <div className='notifications'>
                        <span className='icon-bell icon-css' onClick={this.onNotificationClick.bind(this)}><Badge className='badge-Count'>{notificationCount}</Badge></span>
                    </div>
                    <div className='profile-section'>
                        <div className='profile-Img'>
                            <img src={adminProfile === 'undefined' ? require('../../assets/images/profile.png') : adminProfile} ></img>
                        </div>
                        <div className='profile-Setting'>
                            <DropdownMenu onSelect={this.handleSelect.bind(this)} onItemSelect={this.onItemSelect.bind(this)} title={adminDisplayName} options={options}></DropdownMenu>
                        </div>
                    </div>
                </div>
                <AdminNotification
                    responseData={this.state.notificationDataList}
                    openPage={this.notificationClickRedirect.bind(this)}
                    className={notificationModal ? "notification show" : "notification"}
                    onClose={() => this.onNotificationClose()}
                />
            </div>
        )
    }
}




export default AdminHeader;

