import React from 'react';
import { setLocalActiveClass, setFilledFlags } from "../../../utils/helper";
import './criteriachange.scss';
import { Scrollbars } from "react-custom-scrollbars";
import Teethfree from '../../../assets/images/teethFreespace.svg';
import HomeSideMenu from '../../../components/homesidebar/homesidebar';
import HomeHeader from '../../../components/homeheader/homeheader';




class Criteriachange extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        let { isViewFrom } = this.state

        return (
            <div className='criteriachange'>
                <HomeSideMenu {...this.props}></HomeSideMenu>
                <HomeHeader {...this.props}></HomeHeader>

                <div className='agreementPage'>
                    <Scrollbars>
                        <div className='main-Container'>
                            <div className='img-Container'>
                                <img src={Teethfree} className="logo" alt="logo" />
                            </div>
                            <h1 className='paddingB15'>Criteria for Changing to 5mm Device</h1>
                            <div className='content-Section'>
                                <h5 className='paddingB15'>After two months of wearing the 3mm device, you can start wearing the 5mm device. You need to introduce it gradually e.g., wear the 5mm device for 5 minutes and the 3mm for 55 minutes until you are only using the 5mm device.</h5>
                                <h5 className='paddingB15'>If at any point, you get any signs of overuse, you should half the amount you are wearing the device or return to the programme which was not giving you any pain.</h5>
                            </div>


                        </div>
                    </Scrollbars>
                </div>
            </div>

        )
    }
}

export default Criteriachange;

