import React from 'react';
import './videoplayer.scss';
//import { Player } from 'video-react';
import ReactPlayer from 'react-player'
import 'video-react/dist/video-react.css';
import VideoTag from '../../assets/images/sample.mp4';

class VideoPlayer extends React.Component {
    constructor(props) {

        super(props)
        this.state = {

        }
    }




    render() {
        let { title, url, controls, width, height, myvideo, playing } = this.props
        return (
            <div className='video-Section'>
                {/* <Player className='player-Custom'>
                    <source src={VideoTag}></source>
                </Player> */}
                <ReactPlayer className='player-Custom' playing={playing} controls={controls} url={url} width='100%' height={height} >
                    {/* <source src={VideoTag}></source> */}
                </ReactPlayer>
            </div>

        )
    }
}




export default VideoPlayer;

