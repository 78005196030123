import React, { useState } from 'react';
import { setLocalActiveClass, getStoredData, setStoredData } from "../../utils/helper";
import './freewaySpaceView.scss';
import { Scrollbars } from "react-custom-scrollbars";
import HomeSideMenu from '../../components/homesidebar/homesidebar'
import HomeHeader from '../../components/homeheader/homeheader';
import { Row, Col } from 'react-bootstrap';
import TextField from '../../components/textfield/textfield';
import Button from "../../components/Button/button";
import CONSTANTS from '../../utils/constants';
import ToastComponent from "../../components/toastcomponent/toastcomponent";
import { getFormDataObject } from '../../utils/helper';
import WebService from "../../utils/Webservice"
import AdminSideMenu from '../../components/adminsidebar/adminsidebar';
import AdminHeader from '../../components/adminheader/adminheader';
class FreewaySpaceView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            profession: '',
            _id: (this.props.history.location.state.isViewFrom === 'adminFreewaySpace') ? getStoredData("userId") : getStoredData("_id"),
            isViewFrom: this.props.history.location.state.isViewFrom,
            imagePreviewUrl: '',
            isEdit: 'false',
            email: {
                value: "",
                isValid: true,
                message: ""
            },
            sFullName: {
                value: "",
                isValid: true,
                message: ""
            },
            sPhoneNumber: {
                value: "",
                isValid: true,
                message: ""
            },
            errorMessage: '',
            isToastShow: false,
            showPass: false,
            // fileStatus: getStoredData("sProfilePhoto") === 'undefined' ? false : true,
            sucessData: false,
            message: '',
            user: []
        }
        this.state.isViewFrom === 'homeFreewaySpace' ? setLocalActiveClass("freewaySpaceView") : setLocalActiveClass("adminFreewaySpace")
    }

    componentDidMount() {
        this.getUserWebservice()
    }

    async getUserWebservice() {
        try {
            let obj = {
                _id: this.state._id,
            };
            let response = await WebService.getUserDetails(obj);
            this.setState({ isLoading: false });
            if (response.status) {
                console.log("Success::" + response.status);
                this.setState({ user: response.data })
                // this.handleSuccessResponse(response);
            } else {
                this.handleErrorResponse(response);
                console.log("Error::" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        let { profession, treatment, isViewFrom, user, adminEmail, adminPhoneNumber, imagePreviewUrl, fileToaster, sucessData, adminFirstName, adminLastName, errorMessage, isToastShow, file, toaster, message, isShow, isWarning } = this.state
        // console.log(user.sJaw_line, "user.sJaw_line");

        return (
            <div className='main-Profile'>
                {isViewFrom === 'adminFreewaySpace' && <AdminHeader  {...this.props} ></AdminHeader>}
                {isViewFrom === 'homeFreewaySpace' ? <HomeSideMenu {...this.props} showMorePopup={true}></HomeSideMenu> : <AdminSideMenu {...this.props} showMorePopup={true}></AdminSideMenu>}
                {isViewFrom != 'adminFreewaySpace' && <HomeHeader  {...this.props} ></HomeHeader>}


                <div className='inner-Profile'>
                    <Scrollbars className='home-Scroll'>

                        <div className='main-Container'>

                            <h2 className='paddingB15'>Freeway Space</h2>

                            <div className='form'>
                                <TextField
                                    title="What you think your freeway space is?"
                                    name="sFullName"
                                    placeholder={user.sFreeway_space !== undefined ? user.sFreeway_space : "-"}
                                    readOnly={'readOnly'}
                                ></TextField>
                                <TextField
                                    title="Jaw Opening"
                                    name="email"
                                    readOnly={'readOnly'}
                                    placeholder={user.sJaw_line !== undefined ? user.sJaw_line : '-'}
                                ></TextField>

                            </div>

                        </div>
                    </Scrollbars>
                </div>
            </div>
        )
    }
}




export default FreewaySpaceView;

