import React from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import { Row, Col } from 'react-bootstrap';
import './5mmfittingstep1.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu'
import Header from '../../../components/header/header';
import FreespaceImg from '../../../assets/images/teethFreespace.svg';
import ReactPlayer from "../../../components/videoplayer/videoplayer";
import Button from "../../../components/Button/button";
import HomeSideMenu from '../../../components/homesidebar/homesidebar';

class FivemmFittingStep1 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isViewFrom: this.props.history.location.state.isViewFrom
        }
        console.log(this.state.isViewFrom, "isView From");

        { this.state.isViewFrom === '3mm Touch Test' ? setLocalActiveClass("3mmFittingStep1") : (this.state.isViewFrom === 'home3mm' || this.state.isViewFrom === 'home5mm' ? setLocalActiveClass("HomeInstructions") : setLocalActiveClass("5mmFittingStep1")) }
        // setLocalActiveClass("5mmFittingStep1")
    }
    onNextStepClick() {
        { this.state.isViewFrom === '3mm Touch Test' || this.state.isViewFrom === 'home3mm' ? this.props.history.push('/3mmfittingstep2', { isViewFrom: this.state.isViewFrom }) : this.props.history.push('/5mmfittingstep2', { isViewFrom: this.state.isViewFrom }) }
        // this.props.history.push('/5mmfittingstep2', { isViewFrom: this.state.isViewFrom })
    }
    onSkipVideoClick() {
        { (this.state.isViewFrom === '3mm Touch Test') ? this.props.history.push(('/3mmtouchtest'), { isViewFrom: this.state.isViewFrom }) : this.props.history.push('/touchtest') }
    }

    render() {
        let headerData = this.state.isViewFrom === '3mm Touch Test' || this.state.isViewFrom === 'home3mm' ? '3mm Fitting - Fitting Guide' : '5mm Fitting - Fitting Guide'

        return (
            <div className='main-5mmFitting'>
                <Header></Header>
                {this.state.isViewFrom === 'home3mm' || this.state.isViewFrom === 'home5mm' ? <HomeSideMenu {...this.props} ></HomeSideMenu> : <SideMenu {...this.props}></SideMenu>}
                {/* <SideMenu {...this.props}></SideMenu> */}
                <div className='inner-5mmFitting'>
                    <Scrollbars className='mm-Fitting-Scroll'>
                        <div className='main-Container'>
                            <div className='img-Container'>
                                <img src={FreespaceImg} className="logo" alt="logo" />
                            </div>
                            {}
                            <h2 className='paddingB15'>{headerData}</h2>
                            <div className='content-Section'>
                                {this.state.isViewFrom === '5mm Touch Test' ?
                                    <h5 className='paddingB15'>Fitting your Oralift device is easy. Please follow the steps in the instruction video below: You may prefer to watch all videos before starting the procedure. You can watch these as many times as you want until you are happy to proceed.</h5> :
                                    (this.state.isViewFrom === 'home3mm' || this.state.isViewFrom === 'home5mm') ? <h5 className='paddingB15'>To fit you 3mm device, you should follow the same procedure as you did with 5mm device and if you wish, you can watch the series of fitting videos again.</h5>
                                        :

                                        <h5 className='paddingB15'>To fit you 3mm device, you should follow the same procedure as you did with 5mm device and if you wish, you can watch the series of fitting videos again.
                                     <br />
                                            <br />
                                            Alternatively click Skip Videos on the bottom right corner to go straight to the Touch Test. </h5>
                                }
                            </div>
                            {/* <VideoPlayer></VideoPlayer> */}
                            <ReactPlayer url={"https://vimeo.com/362811323/fa53c2c51b"} controls={true}></ReactPlayer>
                            <Row className='custom-Ul'>
                                <Col lg={12} sm={12}>
                                    <h3 className='paddingB15'>Step1: What you will need</h3>
                                </Col>
                                <Col lg={6} sm={6} xs={6}>
                                    <h5 className='paddingB15'>
                                        <span className='dots-Css'></span>A kettle
                            </h5>
                                </Col>
                                <Col lg={6} sm={6} xs={6}>
                                    <h5 className='paddingB15'>
                                        <span className='dots-Css'></span>Heat resistant bowl
                            </h5>
                                </Col>
                                <Col lg={6} sm={6} xs={6}>
                                    <h5 className='paddingB15'>
                                        <span className='dots-Css'></span>Teaspoon
                            </h5>
                                </Col>
                                <Col lg={6} sm={6} xs={6}>
                                    <h5 className='paddingB15'>
                                        <span className='dots-Css'></span>Vaseline
                            </h5>
                                </Col>
                            </Row>
                            <Button
                                title="next step"
                                onClick={() => this.onNextStepClick()}
                            ></Button>
                            {this.state.isViewFrom === '5mm Touch Test' || this.state.isViewFrom === 'home3mm' || this.state.isViewFrom === 'home5mm' ? "" :
                                <span className='marginT10 floatR' >
                                    <span onClick={this.onSkipVideoClick.bind(this)} className='highlighted textCapital nextLink'>skip videos<span className='icon-right icon-css'></span>
                                    </span>
                                </span>
                            }
                        </div>
                    </Scrollbars>
                </div>
            </div>

        )
    }
}




export default FivemmFittingStep1;

