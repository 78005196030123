import React from 'react';
import { setLocalActiveClass, getStoredData } from "../../utils/helper";
import './startingPoint.scss'
import { Scrollbars } from "react-custom-scrollbars";

import Button from '../../components/Button/button'
import thumbsUpImg from '../../assets/images/thumbsUp.svg';
import WebService from "../../utils/Webservice"
import AdminHeader from '../../components/adminheader/adminheader';
import HomeSideMenu from '../../components/homesidebar/homesidebar';
import AdminSideMenu from '../../components/adminsidebar/adminsidebar';
import HomeHeader from '../../components/homeheader/homeheader';
import moment from 'moment';

class StartingPoint extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isViewFrom: this.props.history.location.state.isViewFrom,
            _id: (this.props.history.location.state.isViewFrom === 'adminStartingPoint') ? getStoredData("userId") : getStoredData("_id"),
            date: new Date(),
            touchtestAMTime: '',
            touchtestPMTime: '',
            touchtest3mm: '',
            touchtest5mm: ''
        }
        setLocalActiveClass("StartingPoint")
        this.state.isViewFrom === 'homeStartingPoint' ? setLocalActiveClass("StartingPoint") : setLocalActiveClass("StartingPoint")
    }
    // onGoClick() {
    //     this.props.history.push('3mmhome', { isViewFrom: 'home' })
    // }
    componentDidMount() {
        this.getUserWebservice()

    }
    async getUserWebservice() {
        try {
            let obj = {
                _id: this.state._id,
            };
            let response = await WebService.getUserById(obj);
            this.setState({ isLoading: false });
            if (response.status) {
                console.log("Success::" + response.status);
                // console.log(response.data.dUpdatedAt);
                this.setState({
                    date: moment(response.data.dUpdatedAt).add(2, 'days'),
                    // touchtestAMTime: response.data.touchtestAMTime.replace(".", ":"),
                    // touchtestPMTime: response.data.touchtestPMTime.replace(".", ":"),
                    touchtest3mm: response.data.touchtest3mm,
                    touchtest5mm: response.data.touchtest5mm
                })
            } else {
                console.log("Error::" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }

    setTime = (touchtestTime) => {
        if (touchtestTime !== '') {
            if (String(touchtestTime).includes('.')) {
                let minTime = String(touchtestTime).split(".")
                touchtestTime = String(touchtestTime).replace(".", ":")
                if (Number(minTime[0]) > 0) {
                    return touchtestTime + " minutes"
                } else {
                    return touchtestTime + " seconds"
                }
            } else {
                return touchtestTime + " minutes"
            }

        }
    }
    render() {
        let { isViewFrom, date, touchtest3mm } = this.state
        return (
            <div className='main-Congratulations'>
                {/* {isViewFrom === 'adminStartingPoint' && <AdminHeader  {...this.props} ></AdminHeader>} */}
                {isViewFrom === 'homeStartingPoint' ? <HomeSideMenu {...this.props} showMorePopup={true}></HomeSideMenu> : <AdminSideMenu {...this.props} showMorePopup={true}></AdminSideMenu>}
                {/* {isViewFrom !== 'adminStartingPoint' && <HomeHeader  {...this.props} ></HomeHeader>} */}
                <div className='inner-Congratulations'>
                    <Scrollbars>
                        <div className='main-Container'>
                            <div className='img-Container'>
                                <img src={thumbsUpImg} className="logo" alt="logo" />
                            </div>
                            <h4 className='paddingB15'>Your Starting Point </h4>
                            <h4 className='paddingB15'>Both Touch Tests </h4>
                            <div className='content-Section'>
                                <h5 className='paddingB24'>You do not have to wear your device for the next two days. You will set your reminder at the end of this Onboarding session.</h5>
                                <h5 className='paddingB24'>Please also remember to read the section about Signs of Overuse, which should be reported at any time throughout your treatment. This will help to tailor your treatment in future.</h5>
                                <h5 className='paddingB24'>In 3 days wear your device for {this.setTime(this.state.touchtest3mm)} in the morning and {this.setTime(this.state.touchtest3mm)} in the evening.</h5>
                            </div>
                            {/* <Button title='go to dashboard' onClick={() => this.onGoClick()}></Button> */}
                        </div>
                    </Scrollbars>
                </div>
            </div>

        )
    }
}




export default StartingPoint;

