import React from 'react';
import { setLocalActiveClass, getStoredData, setStoredData } from "../../../utils/helper";
import './questionbiweekly.scss';
import { Scrollbars } from "react-custom-scrollbars";
import { Row, Col } from 'react-bootstrap';
import HomeSideMenu from '../../../components/homesidebar/homesidebar'
import HomeHeader from '../../../components/homeheader/homeheader';
import Button from '../../../components/Button/button'
import QuestionImg from '../../../assets/images/question.svg';
import TextField from '../../../components/textfield/textfield';
import DropdownMenu from '../../../components/dropdown/dropdown';
import WebService from "../../../utils/Webservice"
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
const options = ['Yes', 'No']
const optionEthnicity = ['Asian Indian', 'Asian Indian']
class QuestionBiweekly extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            iUserId: getStoredData("_id"),
            question1: 'Do you clench your teeth in day time?',
            question2: 'Do your teeth even touch in daytime?',
            question3: 'Do you clench your teeth at night?',
            question4: 'Are you more aware of your freeway space?',
            queOne: '', queTwo: '', queTheree: '', queFour: '',
            disableQueOne: false,
            disableQueTwo: false,
            disableQueTheree: false,
            disableQueFour: false,
            toaster: false,
            message: "",
            isShow: false,

        }
        setLocalActiveClass("Home")
    }
    handleSelectQueOne(eventKey) {
        this.setState({ sEthnicity: eventKey })
    }

    onItemSelectQueOne(e, option, eventKey) {
        (e.target.text.length > 0 ? this.setState({ disableQueOne: true }) : this.setState({ disableQueOne: false }))

        this.setState({
            queOne: e.target.text,
            selOption: option,
            value: eventKey,
        })
    }

    handleSelectQueTwo(eventKey) {
        this.setState({ sEthnicity: eventKey })
    }

    onItemSelectQueTwo(e, option, eventKey) {
        (e.target.text.length > 0 ? this.setState({ disableQueTwo: true }) : this.setState({ disableQueTwo: false }))

        this.setState({
            queTwo: e.target.text,
            selOption: option,
            value: eventKey,
        })
    }

    handleSelectQueThree(eventKey) {
        this.setState({ sEthnicity: eventKey })
    }

    onItemSelectQueThree(e, option, eventKey) {
        (e.target.text.length > 0 ? this.setState({ disableQueTheree: true }) : this.setState({ disableQueTheree: false }))

        this.setState({
            queTheree: e.target.text,
            selOption: option,
            value: eventKey,
        })
    }

    handleSelectQueFour(eventKey) {
        this.setState({ sEthnicity: eventKey })
    }

    onItemSelectQueFour(e, option, eventKey) {
        (e.target.text.length > 0 ? this.setState({ disableQueFour: true }) : this.setState({ disableQueFour: false }))

        this.setState({
            queFour: e.target.text,
            selOption: option,
            value: eventKey,
        })
    }

    onGoClick() {
        this.questionnaireWebServices()
        // this.props.history.push('/')
    }
    async questionnaireWebServices() {
        let question = []
        let data = {}
        data[this.state.question1] = this.state.queOne
        question.push(data)
        //console.log(question);

        const queTwoData = {}
        queTwoData[this.state.question2] = this.state.queTwo;
        question.push(queTwoData)

        const queThreeData = {}
        queThreeData[this.state.question3] = this.state.queTheree;
        question.push(queThreeData)

        const queFourData = {}
        queFourData[this.state.question4] = this.state.queFour;
        question.push(queThreeData)

        // console.log(question);

        try {
            let obj = {
                iUserId: this.state.iUserId,
                aQuestionBiweekly: question
            };
            let response = await WebService.questionnaireByWeekly(obj);
            this.setState({ isLoading: false });

            if (response.status) {
                console.log("response" + response.status);
                this.setState({ toaster: true, message: response.MESSAGES, isShow: true })

                this.handleSuccessResponse(response.data);
            } else {
                this.setState({ toaster: true, message: response.MESSAGES, isShow: true })
                console.log("response" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }
    handleSuccessResponse(response) {
        setStoredData("isQuestionnaireDue", response.isQuestionnaireDue)
        setTimeout(() => {
            this.props.history.push('/3mmhome', { isViewFrom: 'home' })

            //     if (this.state.isOnBoardingComplete) {
            //         this.props.history.push('/3mmhome', { isViewFrom: 'home' })
            //         localStorage.setItem('isViewFrom', 'home')
            //     }
            //     else {
            //         this.props.history.push('/welcome')
            //     }
        }, 3000)

    }

    render() {
        let { queOne, queTwo, queTheree, queFour, toaster, message, isShow, } = this.state

        let queOneTitle = queOne === null || queOne === '' ? "Select" : this.state.queOne
        let queTwoTitle = queTwo === null || queTwo === '' ? "Select" : this.state.queTwo
        let queThereeTitle = queTheree === null || queTheree === '' ? "Select" : this.state.queTheree
        let queFourTitle = queFour === null || queFour === '' ? "Select" : this.state.queFour

        let disable = (this.state.disableQueOne && this.state.disableQueTwo && this.state.disableQueTheree && this.state.disableQueFour) ? false : true
        return (
            <div className='main-QuestionBiweekly'>
                <HomeSideMenu {...this.props}></HomeSideMenu>
                <HomeHeader></HomeHeader>
                <div className='inner-QuestionBiweekly'>
                    <Scrollbars>
                        {toaster && <ToastComponent message={message} show={isShow} onClose={() => this.setState({ toaster: false })} delay={3000} autohide >
                        </ToastComponent>}


                        <div className='main-Container'>


                            <div className='img-Container'>
                                <img src={QuestionImg} className="logo" alt="logo" />
                            </div>
                            <h2 className='paddingB15'>Questionnaire - Biweekly</h2>
                            <form className='question-Form'>
                                <Row>
                                    <Col lg={6} sm={6} className='paddingB15'>
                                        <label className='cust-Label'>Do you clench your teeth in day time?</label>
                                        <DropdownMenu title={queOneTitle} onSelect={this.handleSelectQueOne.bind(this)} onItemSelect={this.onItemSelectQueOne.bind(this)} options={options}></DropdownMenu>
                                    </Col>
                                    <Col lg={6} sm={6} className='paddingB15'>
                                        <label className='cust-Label'>Do your teeth even touch in daytime?</label>
                                        <DropdownMenu title={queTwoTitle} onSelect={this.handleSelectQueTwo.bind(this)} onItemSelect={this.onItemSelectQueTwo.bind(this)} options={options}></DropdownMenu>
                                    </Col>
                                    <Col lg={6} sm={6}>
                                        <label className='cust-Label'>Do you clench your teeth at night?</label>
                                        <DropdownMenu title={queThereeTitle} onSelect={this.handleSelectQueThree.bind(this)} onItemSelect={this.onItemSelectQueThree.bind(this)} options={options}></DropdownMenu>
                                    </Col>
                                    <Col lg={6} sm={6}>
                                        <label className='cust-Label'>Are you more aware of your freeway space?</label>
                                        <DropdownMenu title={queFourTitle} onSelect={this.handleSelectQueFour.bind(this)} onItemSelect={this.onItemSelectQueFour.bind(this)} options={options}></DropdownMenu>
                                    </Col>
                                </Row>
                            </form>
                            <Button disabled={disable} title='Submit' onClick={() => this.onGoClick()}></Button>
                        </div>
                    </Scrollbars>
                </div>
            </div>

        )
    }
}




export default QuestionBiweekly;

