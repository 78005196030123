import React, { useState } from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import './touchtestintroduction.scss';
import { Scrollbars } from "react-custom-scrollbars";
import HomeSideMenu from '../../../components/homesidebar/homesidebar'
import HomeHeader from '../../../components/homeheader/homeheader';
// import Highchart from '../../../cossmponents/Highchart/Highchart'
const optionsDevice = ['3 mm ', '5 mm']
const optionsTimer = ['15 min', '20 min']

class TouchIntroduction extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showToast: true,
            setModalShow: false,
            modalShow: false,

        }
        setLocalActiveClass("HomeProgress")
    }
    onGoClick() {
        this.props.history.push('/setreminder')
    }
    onManualClick() {
        this.props.history.push('/questionbiweekly')
    }
    showToast() {
        this.setState({
            show: true
        })
        setTimeout(() => {
            this.setState({
                show: false
            })
        }, 3000)
    }
    setModalShow = (show) => {
        this.setState(
            {
                modalShow: show
            }
        )
    }
    render() {
        const { modalShow, setModalShow } = this.state
        return (
            <div className='main-TouchIntroduction'>
                <HomeSideMenu {...this.props}></HomeSideMenu>
                <HomeHeader></HomeHeader>
                <div className='inner-TouchIntroduction'>
                    <Scrollbars className='home-Scroll'>
                        <div className='main-Container'>
                            <div>Touch Test</div>
                            {/* <Highchart></Highchart> */}
                        </div>
                    </Scrollbars>
                </div>
            </div>
        )
    }
}




export default TouchIntroduction;

