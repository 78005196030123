import axios from 'axios';
import CONSTANT from "./constants";

export default class API {
    static async userAgreement(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.USER_AGREEMENT, params)
        return response.data
    }
    static async getUserAgreement(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.GET_USER_AGREEMENT, params)
        return response.data
    }
    static async aboutUser(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.ABOUT_YOU, params)
        return response.data
    }
    static async getUserDetails(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.GET_USER_DETAIL, params)
        return response.data
    }
    static async saveUserPreferences(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.POST_SAVE_USER_PREFERENCE, params)
        return response.data
    }
    static async getTouchCount(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.POST_TOUCH_COUNT, params)
        return response.data
    }
    static async whatYouWantUser(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.WHAT_YOU_WANT, params)
        return response.data
    }
    static async onBoardingCompleted(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.ONBOARDING_COMPLETED, params)
        return response.data
    }

    static async uploadSmilePhoto(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.SMILE_PHOTO, params)
        return response.data
    }
    static async uploadFrontPhoto(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.FRONT_PHOTO, params)
        return response.data
    }
    static async uploadLeftPhoto(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.LEFT_PHOTO, params)
        return response.data
    }
    static async uploadRightPhoto(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.RIGHT_PHOTO, params)
        return response.data
    }
    static async uploadBodyLeftPhoto(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.BODY_LEFT_PHOTO, params)
        return response.data
    }
    static async uploadBodyRightPhoto(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.BODY_RIGHT_PHOTO, params)
        return response.data
    }
    static async getPhotos(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.GET_PHOTOS, params)
        return response.data
    }
    static async userVerification(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.USER_VERIFICATION, params)
        return response.data
    }
    static async userVerification(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.USER_VERIFICATION, params)
        return response.data
    }
    static async UserTouchTest(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.POST_TOUCH_TEST, params)
        return response.data
    }
    static async UserTouchReport(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.POST_TOUCH_REPORT, params)
        return response.data
    }
    static async getUserPhotos(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.GET_PHOTOS, params)
        return response.data
    }
    static async saveUser(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.POST_SAVE_USER, params)
        return response.data
    }
    static async getUserDataFromAccessCode(accessCode) {
        let response = await axios.get(CONSTANT.ORALIFT_DB_API_URL + accessCode)
        return response.data
    }
    static async getProgressData(params, token) {
        console.log('toeknnnnnnnnnnnnnnggg', token, params);
        // const options = {
        // method: 'POST',
        // headers: { 'content-type': 'application/x-www-form-urlencoded', Authorization: `Bearer ${token}`, auth:`Bearer ${token}` },
        // data: JSON.stringify(params),
        // url: CONSTANT.API_BASE_URL + CONSTANT.API.USER_PROGRESS,
        // };
        // axios(options);
        let headers = token ? {
            auth: `Bearer ${token}`
        } : {};
        console.log('headers', headers);
        try {
            let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.USER_PROGRESS, params, { headers });
            console.log('response', response);
            return response.data
        } catch (error) {
            console.log('error vxxvxcvxcvxcv', error)

        }
    }
    static async getProgressDataCalender(params, startDate, endDate) {
        let headers = params.token ? {
            auth: `Bearer ${params.iUserId}`
        } : {}
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.USER_PROGRESS_CALENDER, { ...params, startDate, endDate }, { headers })
        return response.data
    }
    static async getTouchTestData(params, token) {
        let headers = token ? {
            auth: `Bearer ${token}`
        } : {}
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.TOUCHTEST_PROGRESS_DATA, params, { headers })
        return response.data
    }
    static async getTouchTestProgress(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.TOUCH_TEST_PROGRESS, params)
        return response.data
    }
    static async setReminder(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.SET_REMINDER, params)
        return response.data
    }
    static async adminLogin(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.ADMIN_LOGIN, params)
        return response.data
    }
    static async userLogin(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.USER_LOGIN, params)
        return response.data
    }
    static async notificationList(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.NOTIFICATION_LIST, params)
        return response.data
    }
    static async adminLogout(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.ADMIN_LOGOUT, params)
        return response.data
    }
    static async adminProfileUpdate(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.ADMIN_PROFILE_UPDATE, params)
        return response.data
    }
    static async userProfileUpdate(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.USER_PROFILE_UPDATE, params)
        return response.data
    }
    static async adminDashboard(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.ADMIN_DASHBOARD, params)
        return response.data
    }
    static async adminChangePassword(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.ADMIN_CHANGE_PASSWORD, params)
        return response.data
    }
    static async adminForgotPassword(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.ADMIN_FORGOT_PASSWORD, params)
        return response.data
    }
    static async userForgotPassword(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.USER_FORGOT_PASSWORD, params)
        return response.data
    }
    static async userSetPassword(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.USER_SET_FORGOT_PASSWORD, params)
        return response.data
    }
    static async questionnaireByWeekly(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.SET_QUESTIONNAIRE, params)
        return response.data
    }
    static async userFeedback(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.USER_FEEDBACK, params)
        return response.data
    }

    static async resetPasswordLink(params) {
        let response = await axios.put(CONSTANT.API_BASE_URL + CONSTANT.API.RESET_PASSWORD_LINK, params)
        return response.data
    }
    static async adminResetPassword(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.ADMIN_RESET_PASSWORD, params)
        return response.data
    }
    static async getQuestionnaire(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.GET_QUESTIONNAIRE, params)
        return response.data
    }
    static async getReportOverUse(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.GET_REPORT_OVERUSE, params)
        return response.data
    }
    static async generateCode(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.GENERATE_CODE, params)
        return response.data
    }
    static async getUserById(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.USER_BY_ID, params)
        return response.data
    }
    static async storedVerificationCode(params) {
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.STORED_V_CODE, params)
    }

    // version 3.0 ---------------------
    static async sessionData(params, token) {
        let headers = token ? {
            auth: `Bearer ${token}`
        } : {}
        let response = await axios.post(CONSTANT.API_BASE_URL + CONSTANT.API.SESSION_DATA, params, { headers })
        return response.data
    }
}

export const requestConfig = axios.interceptors.request.use(
    config => {
        config.headers.authorization = localStorage.getItem(CONSTANT.KEYS.keyToken);
        return config;
    },
    error => Promise.reject(error)
);
