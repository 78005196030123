import React from 'react';
import { setLocalActiveClass, setFilledFlags, getStoredData } from "../../../utils/helper";
import './addphotoguide.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu'
import VideoPlayer from '../../../components/videoplayer/videoplayer';
import Button from '../../../components/Button/button'
import Header from '../../../components/header/header';
import Addphoto from '../../../assets/images/addPhoto.svg';
import { Row, Col } from 'react-bootstrap';
import WebService from "../../../utils/Webservice"
import CONSTANT from "../../../utils/constants";
import HomeSideMenu from '../../../components/homesidebar/homesidebar';

class AddPhotoGuide extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            _id: getStoredData("_id"),
            isViewFrom: this.props.history.location.state.isViewFrom
        }
        // setLocalActiveClass("AddPhoto")
        { this.state.isViewFrom === 'homeAddphotoguide' ? setLocalActiveClass("HomeInstructions") : setLocalActiveClass("AddPhoto") }

    }
    onStartedClick() {
        { this.state.isViewFrom === 'homeAddphotoguide' ? this.props.history.push("/instructions", { isViewFrom: this.state.isViewFrom }) : this.props.history.push('/addphotoinfo') }

        //this.props.history.push('/addphotoinfo')
    }
    onSkipClick() {
        setFilledFlags(CONSTANT.KEYS.KeyPhotoAdded, true)
        this.props.history.push('/freewayspace', { isViewFrom: this.state.isViewFrom })
    }
    componentDidMount() {
        // this.getUserTouchCount()
    }


    render() {
        return (
            <div className='main-PhotoGuide'>
                <Header></Header>
                {this.state.isViewFrom === 'homeAddphotoguide' ? <HomeSideMenu {...this.props}></HomeSideMenu> : <SideMenu {...this.props}></SideMenu>}
                {/* <SideMenu {...this.props}></SideMenu> */}

                <div className='inner-PhotoGuide'>
                    <Scrollbars className='photo-Scroll'>
                        <div className='main-Container'>

                            <div className='img-Container'>
                                <img src={Addphoto} className="logo" alt="logo" />
                            </div>
                            <h2 className='paddingB15'>The first step is to take your photos</h2>
                            <div className='content-Section'>
                                <h5 className='paddingB15'>With Oralift, changes start very quickly and sometimes they can be seen after wearing the appliance for just 10 minutes. It is therefore essential to take your photos at this stage.</h5>
                            </div>
                            <h2 className='paddingB15'>Watch our guide to taking photos</h2>
                            <VideoPlayer width={940} url={"https://vimeo.com/356895064/8fc0f1cf4e"} controls={true}>
                            </VideoPlayer>
                            {/* <ReactPlayer url={"https://vimeo.com/356895064/8fc0f1cf4e"} controls={true}></ReactPlayer> */}
                            <Row className='custom-Ul'>
                                <Col lg={12} sm={12}>
                                    <h2 className='paddingB15'>Key points:</h2>
                                </Col>
                                <Col lg={6} sm={6} >
                                    <h5 className='paddingB15'>
                                        <span className='dots-Css'></span>In similar lighting
                            </h5>
                                </Col>
                                <Col lg={6} sm={6} >
                                    <h5 className='paddingB15'>
                                        <span className='dots-Css'></span>Make up free
                            </h5>
                                </Col>
                                <Col lg={6} sm={6} >
                                    <h5 className='paddingB15'>
                                        <span className='dots-Css'></span>From the same angle
                            </h5>
                                </Col>
                                <Col lg={6} sm={6} >
                                    <h5 className='paddingB15'>
                                        <span className='dots-Css'></span>Free from shadows
                            </h5>
                                </Col>
                                <Col lg={6} sm={6} >
                                    <h5 className='paddingB15'>
                                        <span className='dots-Css'></span>Taken on the same device
                            </h5>
                                </Col>
                                <Col lg={6} sm={6} >
                                    <h5 className='paddingB15'>
                                        <span className='dots-Css'></span>Hair tied up
                            </h5>
                                </Col>
                                <Col lg={6} sm={6} >
                                    <h5 className='paddingB15'>
                                        <span className='dots-Css'></span>Taken in portrait mode on your smartphone
                            </h5>
                                </Col>
                            </Row>
                            <Button title={this.state.isViewFrom === 'homeAddphotoguide' ? 'go to instruction' : 'upload photos'} onClick={() => this.onStartedClick()} ></Button>
                            {this.state.isViewFrom === "homeAddphotoguide" ? '' :
                                <div className='upgrade-Section'>
                                    <h5 className='paddingB15'>Taking photo is an essential part of this treatment. If you do not want to take your photographs, it will not be possible to judge your results. You will find it difficult to monitor your progress and will not be able to upgrade to the advisor service. You cannot start taking photographs at a later stage because changes can start to happen very quickly.</h5>
                                    <h5 className='paddingB15' ><span onClick={() => this.onSkipClick()} className='highlighted textCapital cursor'>Skip Photos <span className='icon-right icon-css'></span></span></h5>
                                </div>
                            }
                        </div>
                    </Scrollbars>
                </div>

            </div>
        )
    }
}




export default AddPhotoGuide;

