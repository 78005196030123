import React from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import './congratulations.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu'
import Header from '../../../components/header/header';
import Button from '../../../components/Button/button'
import ThankYouImg from '../../../assets/images/success.svg';
import WebService from "../../../utils/Webservice"


class Congratulations extends React.Component {
    constructor(props) {
        // window.addEventListener("popstate", e => {
        //     // Nope, go back to your page
        //     this.props.history.go(0);
        // });
        super(props)
        this.state = {}
        setLocalActiveClass("Congratulations")
    }
    onGoClick() {
        this.props.history.push('3mmhome', { isViewFrom: 'home' })
    }
    componentDidMount() {
        //call webservice
        this.onBoardingCompleteWebService()

    }


    async onBoardingCompleteWebService() {

        let obj = {
            iUserId: localStorage.getItem("_id"),

        };
        let response = await WebService.onBoardingCompleted(obj);
        this.setState({ isLoading: false });

        if (response.status) {
            console.log("response" + response.status);
            console.log(response.data);

            localStorage.setItem("isOveruse", response.data.isOveruse)
            localStorage.setItem("isPhotographsDue", response.data.isPhotographsDue)
            localStorage.setItem("isQuestionnaireDue", response.data.isQuestionnaireDue)
            // localStorage.setItem("sUserFirstName", response.data.sUserFirstName)
            // localStorage.setItem("sUserLastName", response.data.sUserLastName)

        } else {
            console.log("response" + response.status);
            // this.handleErrorResponse(response);
        }
    } catch(error) {
        console.log(error);
    }


    render() {
        return (
            <div className='main-Congratulations'>
                <Header></Header>
                <SideMenu {...this.props}></SideMenu>
                <div className='inner-Congratulations'>
                    <Scrollbars>
                        <div className='main-Container'>
                            <div className='img-Container'>
                                <img src={ThankYouImg} className="logo" alt="logo" />
                            </div>
                            <h4 className='paddingB15'>Congratulations</h4>
                            <h2 className='paddingB15'>Jonathan Doe</h2>
                            <div className='content-Section'>
                                <h5 className='paddingB24'>Your programme has been set and you do not have to do anything for two days.</h5>
                                <h5 className='paddingB24'>You will receive a reminder half an hour before you are due to wear your applicance.</h5>
                                <h5 className='paddingB24'>Please read the section on Signs of Overuse and if necessary report this when you next get you reminder.</h5>
                                <h5 className='paddingB24'>our programme will be adjusted accordingly</h5>
                            </div>
                            <Button title='go to dashboard' onClick={() => this.onGoClick()}></Button>
                        </div>
                    </Scrollbars>
                </div>
            </div>

        )
    }
}




export default Congratulations;

