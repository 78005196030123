import React from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import './manualtouchtest5mm.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu'
import Header from '../../../components/header/header';
import Button from '../../../components/Button/button'
import TouchTestImg from '../../../assets/images/manualTouch.svg';
import TextField from '../../../components/textfield/textfield';
import { Row, Col } from 'react-bootstrap';
import WebService from "../../../utils/Webservice"
import InputMask from 'react-input-mask';
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import CONSTANTS from '../../../utils/constants';
import { el } from 'date-fns/esm/locale';
import { async } from 'q';

class ManualTouchTest5mm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            _id: localStorage.getItem("_id"),
            sTotalTime: '',
            nTouchCount: '',
            errorText: false,
            errorTextMin: false,
            errorTextMinSec: false,
            errorTextSec: false, disableTime: false,
            disableTouch: false,
            toaster: false,
            disableBtn: true,
            message: "",
            isShow: false,
            isViewFrom: this.props.history.location.state.isViewFrom,
            nDeviceType: this.props.history.location.state.isViewFrom === '3mm Touch Test' ? 0 : 1
        }
        // setLocalActiveClass("5mmTouchTest")
        { this.state.isViewFrom === '3mm Touch Test' ? setLocalActiveClass("3mmTouchTest") : setLocalActiveClass("5mmTouchTest") }

    }
    onHandleTimeChange(event) {
        let sTime = event.target.value.split(':')
        let min = Number(sTime[0])
        let sec = sTime[1]

        { min > 5 ? this.setState({ errorTextMin: true }) : this.setState({ errorTextMin: false }) }
        { sec > 59 ? this.setState({ errorTextSec: true }) : this.setState({ errorTextSec: false }) }
        { min === 5 && sec > 0 ? this.setState({ errorTextMinSec: true }) : this.setState({ errorTextMinSec: false }) }
        { min === 0 && (sec <= 0 || sec === undefined || sec === '') ? this.setState({ errorText: true }) : this.setState({ errorText: false }) }


        // { min === 0 && sec === undefined ? this.setState({ errorText: true }) : this.setState({ errorText: false }) }

        // debugger


        this.setState({ sTotalTime: event.target.value }, () => {
            console.log(this.state.sTotalTime.length > 0 && !this.state.errorTextMin && !this.state.errorTextSec && !this.state.errorText, "!this.state.errorTextSec");
            { (this.state.sTotalTime.length > 0 && !this.state.errorTextMin && !this.state.errorTextSec && !this.state.errorText && !this.state.errorTextMinSec) ? this.setState({ disableTime: true }) : this.setState({ disableTime: false }) }
        })
    }
    onHandleTouchesChange(event) {

        this.setState({ nTouchCount: event.target.value }, () => {
            { (this.state.nTouchCount > 0) ? this.setState({ disableTouch: true }) : this.setState({ disableTouch: false }) }
        })

    }
    onGoClick() {
        this.onSaveBtnClick()
    }
    async onSaveBtnClick() {

        let { nDeviceType, nTouchCount, sTotalTime } = this.state
        let sTime = this.state.sTotalTime.replace(":", ".")
        sTime = sTime.replace("_", "0")

        this.setState({ isLoading: true });
        try {
            let obj = {
                iUserId: this.state._id,
                nTouchCount: Number(nTouchCount),
                sTotalTime: Number(sTime),
                nDeviceType: nDeviceType,
                nPrefered: 0,
                dCreatedAt: new Date()
            };
            let response = await WebService.UserTouchTest(obj);
            this.setState({ isLoading: false });

            if (response.status) {
                this.setState({ toaster: true, message: response.MESSAGES, isShow: true })

                localStorage.setItem("touchTestId", response.data.touchTestId)
                {
                    this.state.isViewFrom === '3mm Touch Test' ?
                        this.props.history.push(('/5mmtestreport'), { isViewFrom: "3mm Touch Test" }) :
                        this.props.history.push(('/5mmtestreport'), { isViewFrom: "5mm Touch Test" })
                }
                // {
                //     this.state.isViewFrom === '5mm Touch Test' ?
                //         this.props.history.push('/3mmfittingstep1', { isViewFrom: "3mm Touch Test" })
                //         : this.props.history.push('/thankyou')
                // }

            } else {
                this.setState({ toaster: true, message: response.MESSAGES, isShow: true })
                console.log("response" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }
    render() {
        let { pattern } = this.props
        let { disableTime, disableTouch, message, toaster, isShow } = this.state
        console.log((disableTouch + "=>disableTouch"));
        console.log((disableTime + "=>disableTime"));


        console.log((disableTouch === true && disableTime === true));

        let disableBtn = (disableTouch === true && disableTime === true) ? false : true
        return (
            <div className='main-ManualTest'>
                <Header></Header>
                <SideMenu {...this.props}></SideMenu>
                <div className='inner-ManualTest'>
                    <Scrollbars>
                        {/* {toaster && <ToastComponent message={message} show={isShow} onClose={() => this.setState({ toaster: false })} delay={3000} autohide >}></ToastComponent>} */}
                        <div className='main-Container'>
                            <div className='img-Container'>
                                <img src={TouchTestImg} className="logo" alt="logo" />
                            </div>
                            <h1 className='paddingB15'>Manual Touch Test</h1>
                            <form className='question-Form'>
                                <Row>
                                    <Col lg={6} md={6} sm={6} className=''>
                                        <label className="cust-Label">Time (minutes)</label>
                                        <InputMask {...this.props} placeholder={'00:00'} onChange={this.onHandleTimeChange.bind(this)} mask="99:99" type="tel" className="customMask" maskChar={null} />
                                        {(this.state.errorText || this.state.errorTextMin || this.state.errorTextSec || this.state.errorTextMinSec) === true ? <span className='error-msg'>Touch test should not be more than 5 minutes</span> : ''}
                                    </Col>
                                    <Col lg={6} md={6} sm={6} className=''>
                                        <label className="cust-Label">Touches</label>
                                        <InputMask {...this.props} placeholder={'000'} onChange={this.onHandleTouchesChange.bind(this)} mask="999" type="tel" className="customMask" maskChar={null} />
                                    </Col>
                                </Row>
                            </form>
                            <Button title='Save' disabled={disableBtn} onClick={() => this.onGoClick()}></Button>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        )
    }
}




export default ManualTouchTest5mm;

