import React, { useState } from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import './userdetails.scss';
import { Scrollbars } from "react-custom-scrollbars";
import AdminSideMenu from '../../../components/adminsidebar/adminsidebar';
import AdminHeader from '../../../components/adminheader/adminheader';
import Highchart from '../../../components/Highchart/Highchart';
import DropdownMenu from '../../../components/dropdown/dropdown';
import ProgressWeekly from '../../../components/progressweekly/progressweekly';
import { Row, Col } from 'react-bootstrap'
const optionsProgress = ['Weekly', 'Monthly', '2 Monthly']
const machine = ['3 mm', '5 mm']

class UserDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showToast: true,
            setModalShow: false,
            modalShow: false,
            isWeeks: '',
            weeks: [
                '1', '2', '3'
            ],
            months: [
                'Jan', 'Feb', 'Mar'
            ],
            monthly: [
                'Jan-Feb', 'Feb-Mar', 'Mar-Apr'
            ]

        }
        setLocalActiveClass("HomeProgress")
    }
    onGoClick() {
        this.props.history.push('/setreminder')
    }
    onReadMore3mmClick() {
        // //this.props.history.push('/3mmguide')
        // this.setState({ isManualEntry: true })
    }

    onManualClick() {
        this.props.history.push('/questionbiweekly')
    }
    showToast() {
        this.setState({
            show: true
        })
        setTimeout(() => {
            this.setState({
                show: false
            })
        }, 3000)
    }
    setModalShow = (show) => {
        this.setState(
            {
                modalShow: show
            }
        )
    }
    handleSelect(eventKey, event) {
        console.log("SELECT OPTION::" + eventKey);
        this.setState({ isWeeks: eventKey })
    }
    render() {
        const { modalShow, setModalShow } = this.state
        return (
            <div className='main-AdminProgress'>
                <AdminHeader {...this.props}></AdminHeader>
                <AdminSideMenu {...this.props}></AdminSideMenu>
                <div className='inner-HomeProgress'>
                    <Scrollbars className='home-Scroll'>
                        <div className='main-Container'>
                            <div className='dropdown-Section'>
                                <DropdownMenu title="Weekly" onSelect={this.handleSelect.bind(this)} options={optionsProgress}></DropdownMenu>
                            </div>
                            <div className='weekly-Data home-Scroll'>
                                <ProgressWeekly title={this.state.isWeeks == 0 ? "Weeks" : "Months"} weeeks={this.state.weeks} selectedTime={this.state.isWeeks} data={this.state.isWeeks == 0 ? this.state.weeks : (this.state.isWeeks == 1 ? this.state.months : this.state.monthly)} selectedTime={this.state.isWeeks}></ProgressWeekly>

                            </div>
                            <div className='main-HighChart-Section'>
                                <div className='width100'>
                                    <h5 className='title'>Device Wear Graph</h5>
                                    <div className='dropdown-Section'>
                                        <div>
                                            <a className="choices active" onClick={() => this.onReadMore3mmClick()}>AM</a>
                                            <a className="choices mLeft10" onClick={() => this.onReadMore3mmClick()}>PM</a>
                                        </div>
                                        <div className='iconupsec'>
                                            <label className='label'>
                                                <span className='icon-camera icon-css'></span>
                                                Photos Uploaded
                                            </label>
                                            <label className='label mLeft20'>
                                                <span className='icon-warning icon-css'></span>
                                                Overuse Reported
                                        </label>
                                        </div>
                                        <DropdownMenu title="3 mm" onSelect={this.handleSelect.bind(this)} options={machine}></DropdownMenu>
                                    </div>
                                    <Highchart></Highchart>
                                    <div className='iconsec'>
                                        <label className='label'>
                                            <span className='icon-camera icon-css'></span>
                                            Photos Uploaded
                                            </label>
                                        <label className='label mLeft20'>
                                            <span className='icon-warning icon-css'></span>
                                            Overuse Reported
                                        </label>
                                    </div>
                                </div>
                                <div className='width100'>
                                    <h5 className='title'>Touch Test Graph</h5>
                                    <div className='dropdown-Section right'>
                                        <DropdownMenu title="3 mm" onSelect={this.handleSelect.bind(this)} options={machine}></DropdownMenu>
                                    </div>
                                    <Highchart></Highchart>
                                </div>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </div >
        )
    }
}




export default UserDetails;

