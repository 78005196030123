import React from "react";
import {
  setLocalActiveClass,
  setFilledFlags,
  setStoredData,
} from "../../../utils/helper";
import "./login.scss";
import SideMenu from "../../../components/sidebar/sidemenu";
import { withRouter } from "react-router-dom";
import VideoPlayer from "../../../components/videoplayer/videoplayer";
import Button from "../../../components/Button/button";
import { Scrollbars } from "react-custom-scrollbars";
import Header from "../../../components/header/header";
import WebService from "../../../utils/Webservice";
import logo from "../../../assets/images/logo.svg";
import TextField from "../../../components/textfield/textfield";
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import CONSTANT from "../../../utils/constants";
import InputMask from "react-input-mask";
//import { Overlay } from "react-bootstrap";
import Overlay from "../../../components/overlay/overlay";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verificationCode: "",
      errormessage: "",
      toaster: false,
      message: "",
      isShow: false,
      btnDisable: true,
      errorText: false,
      isLoading: false,
      showOverlay: false, // New state to control the overlay display
    };
    setLocalActiveClass("Login");
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    setFilledFlags(CONSTANT.KEYS.Key3mmTest, false);
    setFilledFlags(CONSTANT.KEYS.Key5mmTest, false);
    setFilledFlags(CONSTANT.KEYS.KeyPhotoAdded, false);
    setFilledFlags(CONSTANT.KEYS.KeyPreferencesFilled, false);
  }

  onGoClick() {
    this.userVerificationCode();
  }

  onKeyDown(e) {
    if (e.keyCode === 13) this.userVerificationCode();
  }

  handleChange(event) {
    const value = event.target.value;
    const isCodeValid = value.length === 6;
    this.setState({
      verificationCode: value,
      btnDisable: !isCodeValid,
      errorText: "",
    });
  }

  async userVerificationCode() {
    this.setState({ isLoading: true });
    try {
      let obj = {
        nVerificationCode: this.state.verificationCode,
      };
      let response = await WebService.userVerification(obj);
      console.log("user login response::-", response);
      if (!response.data.isOnBoardingComplete) {
        this.setState({ showOverlay: true, isLoading: false });
        setTimeout(() => {
          this.setState({ showOverlay: false });
        }, 5000);

        return;
      }

      if (response.status) {
        localStorage.setItem("userData", JSON.stringify(response));
        localStorage.setItem("timezone", response.data.tz || "Europe/London");
        this.setState({
          isLoading: false,
          toaster: true,
          message: response.msg,
          isShow: true,
          errorText: false,
        });
        this.handleSuccessResponse(response);
      } else {
        let responseNewUser = await WebService.getUserDataFromAccessCode(
          this.state.verificationCode
        );
        if (responseNewUser.status === true) {
          let name = responseNewUser.data.display_name;
          let fName = name.split(" ");
          let obj = {
            sUserFirstName: fName[0] || "",
            sUserLastName: fName[1] || "",
            nVerificationCode: this.state.verificationCode,
            sEmailId: responseNewUser.data.email,
            sPhoneNumber: responseNewUser.data.phone,
          };
          let responseUserData = await WebService.saveUser(obj);
          if (responseUserData.status) {
            this.setState({
              toaster: true,
              message: responseUserData.MESSAGES,
              isShow: true,
              isWarning: false,
            });
            this.handleSuccessResponse(responseUserData);
          }
        } else {
          this.setState({ errorText: true });
          console.log("Error::" + response.status);
        }
      }
      this.setState({ isLoading: false });
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false, errorText: true });
    }
  }

  handleSuccessResponse(response) {
    let _id = response.data._id;
    setStoredData("_id", _id);
    let isOnBoardingComplete = response.data.isOnBoardingComplete;

    response.data.isOveruse === undefined
      ? setStoredData("isOveruse", false)
      : setStoredData("isOveruse", response.data.isOveruse);
    setStoredData("isPhotographsDue", response.data.isPhotographsDue);
    setStoredData(
      "isTwoMonthsCompleted",
      JSON.stringify(response.data.isTwoMonthsCompleted)
    );
    setStoredData("isTouchTestDue", response.data.isTouchTestDue);
    setStoredData("isQuestionnaireDue", response.data.isQuestionnaireDue);
    setStoredData("sUserFirstName", response.data.sUserFirstName);
    setStoredData("sUserLastName", response.data.sUserLastName);
    setStoredData("touchtest3mm", response.data.touchtest3mm);
    setStoredData("touchtest5mm", response.data.touchtest5mm);
    setStoredData("isOnBoardingComplete", response.data.isOnBoardingComplete);
    setStoredData("sProfilePhoto", response.data.sProfilePhoto);

    this.setState({ _id: _id, isOnBoardingComplete: isOnBoardingComplete });
    this.initialiseUserSettings(response.data);
  }

  initialiseUserSettings(response) {
    let {
      bAdvisorAccess,
      bPhotoPermission,
      bTermsAndCondition,
      nAntiAgeingFlag,
      sAntiAgeingTreatement,
      aWishlist,
    } = response;
    if (bAdvisorAccess && bPhotoPermission && bTermsAndCondition) {
      setFilledFlags(CONSTANT.KEYS.KeyAgreementFlag, true);
    } else {
      setFilledFlags(CONSTANT.KEYS.KeyAgreementFlag, false);
    }
    if (
      nAntiAgeingFlag === 1 &&
      sAntiAgeingTreatement.length > 0 &&
      aWishlist.length > 0
    ) {
      setFilledFlags(CONSTANT.KEYS.KeyQuestionaryFlag, true);
    } else if (nAntiAgeingFlag === 0 && aWishlist.length > 0) {
      setFilledFlags(CONSTANT.KEYS.KeyQuestionaryFlag, true);
    } else {
      setFilledFlags(CONSTANT.KEYS.KeyQuestionaryFlag, false);
    }
    this.getUserPhoto();
    this.getUserTouchCount();
  }

  async getUserTouchCount() {
    try {
      const data = new FormData();
      data.append("iUserId", this.state._id);
      let response = await WebService.getTouchCount(data);

      this.setState({ isLoading: false });
      if (response.status) {
        if (response.data.count5mm > 0) {
          setFilledFlags(CONSTANT.KEYS.Key5mmTest, true);
        } else {
          setFilledFlags(CONSTANT.KEYS.Key5mmTest, false);
        }
        if (response.data.count3mm > 0) {
          setFilledFlags(CONSTANT.KEYS.Key3mmTest, true);
        } else {
          setFilledFlags(CONSTANT.KEYS.Key3mmTest, false);
        }

        setTimeout(() => {
          this.props.history.push("/progress", { isViewFrom: "progress" });
          console.log("state ** ", this.state);
          if (this.state.isOnBoardingComplete) {
            this.props.history.push("/progress", { isViewFrom: "progress" });
            localStorage.setItem("isViewFrom", "home");
          }
        }, 3000);
      } else {
        console.log("response" + response.status);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getUserPhoto() {
    try {
      const data = new FormData();
      data.append("iUserId", this.state._id);
      let response = await WebService.getPhotos(data);

      this.setState({ isLoading: false });
      if (response.status) {
        let responseData = response.data.userPhotoData;
        if (responseData.length > 0) {
          if (
            responseData[0].sNonSmilePhoto &&
            responseData[0].sNonSmilePhoto !== "" &&
            responseData[0].sSmilePhoto &&
            responseData[0].sSmilePhoto !== "" &&
            responseData[0].sLeftPhoto &&
            responseData[0].sLeftPhoto !== "" &&
            responseData[0].sRightPhoto &&
            responseData[0].sRightPhoto !== ""
          ) {
            setFilledFlags(CONSTANT.KEYS.KeyPhotoAdded, true);
          } else {
            setFilledFlags(CONSTANT.KEYS.KeyPhotoAdded, false);
          }
        } else {
          setFilledFlags(CONSTANT.KEYS.KeyPhotoAdded, false);
        }
      } else {
        console.log("response" + response.status);
      }
    } catch (error) {
      console.log(error);
    }
  }

  navigateToAdmin() {
    this.props.history.push("/user");
  }

  render() {
    let { autohide } = this.props;
    let {
      verificationCode,
      message,
      toaster,
      isLoading,
      isShow,
      btnDisable,
      isWarning,
      errorText,
      showOverlay,
    } = this.state;

    return (
      <div className="main-Loginpage">
        {showOverlay && <Overlay />}
        <div className="inner-Loginpage">
          {toaster && (
            <ToastComponent
              isWarning={isWarning}
              message={message}
              show={isShow}
              onClose={() => {
                this.setState({ toaster: false });
              }}
              delay={3000}
              autohide
            ></ToastComponent>
          )}
          <div className="logoSection">
            <img src={logo} className="logo" alt="logo" />
          </div>
          <label className="cust-Label mBottom65">
            Customer Login can be used with a desktop/laptop (not smartphone)
            after onboarding is completed on your phone. You will be able to
            view your data here, but you will not be able to enter data.
          </label>
          <InputMask
            autoFocus={true}
            {...this.props}
            placeholder={"Enter your 6 digit code"}
            onKeyDown={this.onKeyDown.bind(this)}
            mask="999999"
            value={verificationCode}
            onChange={this.handleChange}
            type="tel"
            className="loginTextContent"
            maskChar={null}
          />
          {errorText === true ? (
            <span className="error-msg">
              The first time you use your 6 digit code has to be on your
              smartphone. Once you have fitted your devices and set your
              programme, you can login here with your code using a laptop or
              desktop to monitor your progress. If you want to use your
              smartphone then you must use your App
            </span>
          ) : (
            <span className="error-msg" style={{ opacity: "0" }}></span>
          )}
          {errorText}
          <Button
            style={{ width: "100%" }}
            loading={isLoading}
            title="Proceed"
            disabled={btnDisable}
            onClick={() => this.onGoClick()}
          ></Button>
          <div
            className="or-text"
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "0",
              fontWeight: "bold",
            }}
          >
            OR
          </div>

          <Button
            style={{ width: "100%" }}
            title="Login via email"
            disabled={false}
            onClick={() => this.navigateToAdmin()}
          ></Button>
        </div>
      </div>
    );
  }
}

export default Login;
