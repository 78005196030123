import React, { Component } from 'react';
import { setLocalActiveClass, getStoredData, setStoredData, getFormDataObject } from "../../../utils/helper";
import './profile.scss';
import { Scrollbars } from "react-custom-scrollbars";
import HomeSideMenu from '../../../components/homesidebar/homesidebar';
import HomeHeader from '../../../components/homeheader/homeheader';
import { Row, Col } from 'react-bootstrap';
import TextField from '../../../components/textfield/textfield';
import Button from "../../../components/Button/button";
import CONSTANTS from '../../../utils/constants';
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import WebService from "../../../utils/Webservice";
import AdminSideMenu from '../../../components/adminsidebar/adminsidebar';
import AdminHeader from '../../../components/adminheader/adminheader';
import userProfile from "../../../assets/images/profile.png";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profession: '',
            _id: (this.props.history.location.state.isViewFrom === 'adminProfile') ? getStoredData("userId") : getStoredData("_id"),
            isViewFrom: this.props.history.location.state.isViewFrom,
            imagePreviewUrl: '',
            isEdit: true,
            email: { value: "", isValid: true, message: "" },
            sFullName: { value: "", isValid: true, message: "" },
            sPhoneNumber: { value: "", isValid: true, message: "" },
            errorMessage: '',
            isToastShow: false,
            showPass: false,
            fileStatus: getStoredData("sProfilePhoto") !== 'undefined',
            successData: false,
            message: '',
            isImageChanged: false, // New state to track image change
        };
        setLocalActiveClass("HomeProfile");
    }

    componentDidMount() {
        this.getUserWebService();
    }

    async getUserWebService() {
        try {
            const response = await WebService.getUserDetails({ _id: this.state._id });
            if (response.status) {
                this.handleSuccessResponse(response);
            } else {
                this.handleErrorResponse(response);
            }

            localStorage.setItem('sProfilePhoto', response.data.sProfilePhoto);
            localStorage.setItem('sUserFirstName', response.data.sUserFirstName);
            localStorage.setItem('sUserLastName', response.data.sUserLastName);

            console.log("response.data", response.data.sUserFirstName, response.data.sUserLastName);
        } catch (error) {
            console.log(error);
        }
    }

    handleSuccessResponse(response) {
        const { sUserFirstName, sUserLastName, sPhoneNumber, sEmailId, sNewEmailId, sProfilePhoto } = response.data;
        this.setState({
            sFullName: { value: `${sUserFirstName} ${sUserLastName}`, isValid: true, message: '' },
            sPhoneNumber: { value: sPhoneNumber, isValid: true, message: '' },
            email: { value: sEmailId || sNewEmailId, isValid: true, message: '' },
            imagePreviewUrl: sProfilePhoto,
        });
    }

    onEdit = () => {
        this.setState({
            isEdit: true,
            isFormValid: this.state.email.isValid && this.state.sPhoneNumber.isValid && this.state.sFullName.isValid
        });
    }

    _handleImageChange = e => {
        if (e.target.files.length <= 0) return;

        const file = e.target.files[0];
        const allowedExtensions = [".jpg", ".png", ".jpeg"];
        const hasValidExtension = allowedExtensions.some(extension => file.name.toLowerCase().endsWith(extension));

        if (!hasValidExtension) {
            this.setState({
                isToastShow: true,
                message: CONSTANTS.PHOTOS.JPGALLOW,
                isWarning: true
            });
            return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            this.setState({
                fileStatus: !!this.state.imagePreviewUrl,
                file,
                imagePreviewUrl: reader.result,
                isImageChanged: true, // Set flag to true when image is changed
            }, this.validateForm);
        };
        reader.readAsDataURL(file);
    }

    handleUserInput = e => {
        const { name, value } = e.target;
        this.setState(
            prevState => ({
                [name]: { ...prevState[name], value }
            }),
            () => this.validateField(name, value)
        );
    }

    validateField(fieldName, value) {
        let isValid = false;
        let message = "";

        switch (fieldName) {
            case "email":
                isValid = CONSTANTS.REGEX.EMAIL.test(value);
                message = isValid ? "" : CONSTANTS.MESSAGES.VALIDATION.EMAIL;
                break;
            case "sFullName":
                isValid = value.length > 0;
                message = isValid ? "" : CONSTANTS.MESSAGES.VALIDATION.FULNAME;
                break;
            case "sPhoneNumber":
                isValid = CONSTANTS.REGEX.PHONE_NUMBER.test(value);
                message = isValid ? "" : CONSTANTS.MESSAGES.VALIDATION.PHONE;
                break;
            default:
                break;
        }

        this.setState(
            prevState => ({
                [fieldName]: { ...prevState[fieldName], isValid, message }
            }),
            this.validateForm
        );
    }

    validateForm() {
        const { email, sPhoneNumber, sFullName, isImageChanged } = this.state;
        this.setState({ isFormValid: (email.isValid && sPhoneNumber.isValid && sFullName.isValid) || isImageChanged });
    }

    onSubmit = () => {
        const { email, sFullName, sPhoneNumber } = this.state;
        this.validateField('email', email.value);
        this.validateField('sFullName', sFullName.value);
        this.validateField('sPhoneNumber', sPhoneNumber.value);
        this.userProfileEditWebServices();
    }

    async userProfileEditWebServices() {
        const fullName = this.state.sFullName.value.split(" ");
        const sUserFirstName = fullName[0];
        const sUserLastName = fullName.slice(1).join(" ");

        try {
            const data = getFormDataObject({
                _id: this.state._id,
                sUserFirstName,
                sUserLastName,
                sEmailId: this.state.email.value,
                sPhoneNumber: this.state.sPhoneNumber.value,
                sProfilePhoto: this.state.file
            });

            const response = await WebService.userProfileUpdate(data);
            if (response.status) {
                this.setState({
                    isToastShow: true,
                    errorMessage: response.MESSAGES,
                    isWarning: false
                });
                this.handleSuccessUserProfileUpdate();
            } else {
                this.setState({
                    isToastShow: true,
                    errorMessage: response.MESSAGES,
                    isWarning: true
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleSuccessUserProfileUpdate() {
        setTimeout(() => {
            this.setState({ isEdit: false });
            window.location.reload();
        }, 3000);
    }

    render() {
        const { isViewFrom, imagePreviewUrl, isEdit, email, sFullName, sPhoneNumber, errorMessage, isToastShow, isWarning, isFormValid } = this.state;

        return (
            <div className='main-Profile'>
                {isViewFrom === 'adminProfile' && <AdminHeader {...this.props} />}
                {isViewFrom === 'home' ? <HomeSideMenu {...this.props} showMorePopup /> : <AdminSideMenu {...this.props} showMorePopup />}
                {isViewFrom !== 'adminProfile' && <HomeHeader {...this.props} />}
                <div className='inner-Profile'>
                    <Scrollbars className='home-Scroll'>
                        {isToastShow && <ToastComponent isWarning={isWarning} message={errorMessage} show={isToastShow} onClose={() => this.setState({ isToastShow: false })} delay={3000} autohide />}
                        <div className='main-Container'>
                            {isViewFrom !== 'adminProfile' && (
                                <div className="tooltip_main">
                                    <div className={this.state.infoView ? 'divclass show' : 'divclass'} onClick={() => this.setState({ infoView: !this.state.infoView })}></div>
                                    <div className={this.state.infoView ? 'tool-Tips show' : 'tool-Tips'}>
                                        <a className="tip icon-info cursor" onClick={() => this.setState({ infoView: !this.state.infoView })}>
                                            <span className="info-detail">You can change your details here, look at FAQs, add your thumbnail and please leave us feedback!</span>
                                        </a>
                                    </div>
                                </div>
                            )}
                            <div className='img-upload'>
                                <img
                                    src={imagePreviewUrl || userProfile}
                                    className='profileimg'
                                    alt='Profile'
                                />
                                {isEdit && (
                                    <div className="img-edit">
                                        <label htmlFor="file-input">
                                            <span className='icon-camera cameraclass' />
                                        </label>
                                        <input id="file-input" accept="image/*" type="file" onChange={this._handleImageChange} />
                                    </div>
                                )}
                            </div>
                            <div className='form'>
                                <TextField
                                    title="Full Name"
                                    name="sFullName"
                                    autoFocus={isEdit}
                                    placeholder='john doe'
                                    readOnly={!isEdit}
                                    value={sFullName.value}
                                    onChange={this.handleUserInput}
                                    errorMessage={sFullName.message}
                                />
                                <TextField
                                    title="Email"
                                    name="email"
                                    readOnly
                                    // readOnly={!isEdit}
                                    value={email.value}
                                    onChange={this.handleUserInput}
                                    placeholder='johndoe@gmail.com'
                                    errorMessage={email.message}
                                />
                            </div>
                            {isViewFrom !== 'adminProfile' && (
                                isEdit ?
                                    <Button title="Save" disabled={!isFormValid} onClick={this.onSubmit} /> :
                                    <a onClick={this.onEdit} className='aLink'>Edit</a>
                            )}
                        </div>
                    </Scrollbars>
                </div>
            </div>
        )
    }
}

export default Profile;
