import React, { useState } from "react";
import { setStoredData } from "../../../utils/helper";
import "./admindashboard.scss";
import { Scrollbars } from "react-custom-scrollbars";
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import AdminHeader from "../../../components/adminheader/adminheader";
import WebService from "../../../utils/Webservice";
import SearchBox from "../../../components/SearchBox/SearchBox";
import userProfile from "../../../assets/images/profile.png"
import Loader from "react-spinner-loader";
import userStatusBanner from "../../../assets/images/oralift_user_status.svg"
class AdminDashboard extends React.Component {
  constructor(props) {
    // console.log("userProfile;;;", userProfile)
    super(props);
    this.state = {
      userDetails: [],
      searchTxt: "",
      isLoading: true,

    };
  }

  componentDidMount() {
    this.getUserDetailsWebServices();
  }
  componentWillReceiveProps(newProps) {
    if (this.state?.userDetails?.length) {
      this.setState({
        isLoading: false
      })
    } else {
      this.setState({
        isLoading: true
      })
    }
  }
  handleSelect(eventKey) {
    this.setState({ nMaritalStatus: eventKey });
  }
  onItemSelect(e, option, eventKey) {
    this.setState({
      title: e.target.text,
      selOption: option,
      value: eventKey,
    });
  }
  onItemSelectValue(e, option, eventKey) {
    this.setState({
      sEthnicityTitle: e.target.text,
      selOption: option,
      value: eventKey,
    });
  }
  onUserDetailsClick(i) {
    this.props.history.push("/progress", { isViewFrom: "adminDashboard" });
    setStoredData("userId", i);
  }

  async getUserDetailsWebServices() {
    try {
      let body = {
        searchText: this.state.searchTxt,
      };
      let response = await WebService.adminDashboard(body);
      this.setState({ isLoading: false, userDetails: [] });

      if (response.status) {
        // console.log("response" + response.status);
        this.handleSuccessResponse(response.data);
      } else {
        // console.log("response" + response.status);
      }
    } catch (error) {
      // console.log(error);
    }
  }
  handleSuccessResponse(response) {
    this.setState({
      totalUserCount: response.totalUser,
      activeUser: response.activeUser,
      recentlyJoinUser: response.recentlyJoinUser,
      userDetails: response.userDetails,
    });
  }

  onChangeSearchbox(searchTxt) {
    this.setState({ searchTxt }, () => {
      this.getUserDetailsWebServices();
    });
  }
  clearSearchBox = () => {
    this.setState({ searchTxt: "" }, () => {
      this.getUserDetailsWebServices();
    });
  };
  render() {
    let maritalTitle =
      this.state.title === null || undefined ? "Select" : this.state.title;
    let { totalUserCount, activeUser, recentlyJoinUser } = this.state;
    return (
      <div className="main-AdminDashboard">
        <ToastComponent
          message={this.state.errorMessage}
          show={this.state.isToastShow}
        ></ToastComponent>
        <AdminHeader {...this.props} menuIcon={false}></AdminHeader>
        <div className="inner-AdminDashboard">
          <Scrollbars className="home-Scroll">
            <div className="main-Container">
              {/* <div className='searchSection'>
                                <SearchBox placeholder='Search user '></SearchBox>
                            </div> */}
              <div className="users-Details">
                <ul className="user-Ul">
                  <li className="user-Li">
                    <div className="user-Listing">
                      <div className="inner-Listing">
                        <div className="img-Section">
                          <span className="icon-userno iconF whiteC"></span>
                        </div>
                        <div className="content-Section">
                          <p className="innerTitle">Total Number of Users</p>
                          <h1 className="user-No">{totalUserCount}</h1>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="user-Li">
                    <div className="user-Listing">
                      <div className="inner-Listing">
                        <div className="img-Section">
                          <span className="icon-useractive1 iconF secondaryC"></span>
                        </div>
                        <div className="content-Section">
                          <p className="innerTitle greyC">Total Active Users</p>
                          <h1 className="user-No secondaryC">{activeUser}</h1>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="user-Li">
                    <div className="user-Listing">
                      <div className="inner-Listing">
                        <div className="img-Section">
                          <span className="icon-useractive1 iconF secondaryC"></span>
                        </div>
                        <div className="content-Section">
                          <p className="innerTitle greyC">
                            Recently Joined Users
                          </p>
                          <h1 className="user-No secondaryC">
                            {recentlyJoinUser}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="searchSection">
                  <SearchBox
                    // value={this.state.searchTxt}
                    onSearch={(val) => {
                      this.onChangeSearchbox(val);
                    }}
                    placeholder="Search user"
                    onClear={() => this.clearSearchBox()}
                  ></SearchBox>
                </div>
              </div>
              <div className="user-Dashboard">
              <h2 className="deviceUsersTitle"> Device Users  <span className="userStatusBanner"><img src={userStatusBanner} alt="status"/> </span></h2>
                <div className="grid-Section">
                  {this.state.userDetails.length > 0 ? (
                    <>
                      <ul className="grid-Ul">
                        {this.state.userDetails.map((item) => {
                          // debugger
                          return (
                            <li
                            className={ item.isOnBoardingComplete ? "grid-li" : "grid-li disable" }
                              onClick={() => this.onUserDetailsClick(item._id)}
                              style={{
                                backgroundColor: item.isDeleted ? '#f5cbcb' : item.isOnBoardingComplete ? '' : '#e4e4e4',
                              }}>
                              <div className="bg-Image"></div>
                              <div className="grid-Details">
                                <div className="img-Profile">
                                  <img
                                    src={
                                      item?.sProfilePhoto == undefined || item?.sProfilePhoto == null
                                        ? userProfile
                                        : item.sProfilePhoto
                                    }
                                  ></img>
                                </div>
                                <div className="user-Details">
                                  <p className="innerTitle">
                                    {item.sUserFirstName} {item.sUserLastName}
                                  </p>
                                  <p className="innerTitle lightG">
                                    {item.sEmailId}
                                  </p>
                                  <p className="innerTitle lightG">
                                    {item.sPhoneNumber}
                                  </p>
                                  <p className="innerTitle lightG">
                                    {item.nVerificationCode}
                                  </p>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : (
                    <Loader id="cover-spin" className="isLoading" show={this.state.isLoading} type="box" message="Please wait while data is loading..." />
                  //   <div className="noData">
                  //     <p>No data found</p>
                  //  </div>
                  )}
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default AdminDashboard;
