import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import "./homesidebar.scss";
import { setLocalActiveClass, getStoredData, setStoredData } from "../../utils/helper";
import logo from "../../assets/images/logo.svg";
import DropdownMenu from '../dropdown/dropdown';
const options = ['Married', 'Unmarried']
class HomeSideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMorepopup: this.props.showMorePopup || false,
      showPopup: this.props.showPopup || false,
    }
  }
  toggleActive() {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  };
  toggleClass(element, className) {
    if (!element || !className) {
      return;
    }

    var classString = element.className,
      nameIndex = classString.indexOf(className);
    if (nameIndex == -1) {
      classString += " " + className;
    } else {
      classString =
        classString.substr(0, nameIndex) +
        classString.substr(nameIndex + className.length);
    }
    element.className = classString;
  }

  onSideItemClick(page) {
    localStorage.setItem("menuActiveitem", page);
    localStorage.setItem("MainActiveClass", "");
    this.setState({
      activeClass: page,
      MainActiveClass: "",
      hover: false,
    });

    switch (page) {
      case "Home":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/3mmhome", { isViewFrom: "home" });
        break;
      case "Agreement":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/agreement", { isViewFrom: "home" });
        break;
      case "Questionnaire":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/questionnaire");
        break;
      case "Photo":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/photos", { isViewFrom: "homeSidemenu" });
        break;
      case "HomeProgress":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/progress", { isViewFrom: "homeSidemenu" });
        break;
      case "HomeInstructions":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/instructions", { isViewFrom: "homeSidemenu" });
        break;
      case "HomeProfile":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/profile", { isViewFrom: "home" });
        break;
      case "Savepreferences":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/savepreferences", { isViewFrom: "homeSetPreferences" });
        break;
      case "ViewQuestionnaire":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/ViewQuestionnaire", { isViewFrom: "homeViewQuestionnaire" });
        break;

      case "ViewPreferences":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/ViewPreferences", { isViewFrom: "homeSetPreferences" });
        break;
      case "viewQuestionnaireBiweekly":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/viewQuestionnaireBiweekly", { isViewFrom: "homeQuestionnaire-Biweekly" });
        break;
      case "feedback":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/feedback", { isViewFrom: "home" });
        break;
      case "freewaySpaceView":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/freewayspaceview", { isViewFrom: "homeFreewaySpace" });
        break;
      case "ViewReport":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/viewreport", { isViewFrom: "homeViewReport" });
        break;
      case "StartingPoint":
        this.toggleClass(document.body, "toggleOn");
        this.props.history.push("/startingPoint", { isViewFrom: "homeStartingPoint" });
        break;
      case "logout":
        localStorage.clear();
        break;
      default: localStorage.clear();;
    }

  }


  render() {
    let { } = this.props;
    let activeClass = localStorage.getItem("menuActiveitem");
    
    if (window.location.pathname === "/progress") {
      activeClass = "HomeProgress";
    }
    return (
      <div className="home-sideMenu">
        <div className="sideMenuOptions">
          <div className="img-Logo">
            <img src={logo} className="logo" alt="logo" />
            <span
              onClick={() => this.toggleClass(document.body, "toggleOn")}
              className="icon-close"
            ></span>
          </div>
          <Scrollbars className="menuScroll">
            <ul className="innerMenu">
              {/* <li className={activeClass === "Home" && "active"}>
                <a href="javascript:void(0)" onClick={() => this.onSideItemClick("Home")} >Home</a>
              </li> */}
                <li className={activeClass === "HomeProgress" ? "active" : ""}>
            <a href="javascript:void(0)" onClick={() => this.onSideItemClick("HomeProgress")}>
              Progress
            </a>
          </li>



              {/* <li className={activeClass === "HomeInstructions" && "active"}>
                <a href="javascript:void(0)" className={(activeClass === 'overuse' || activeClass === 'Monitorphoto' || activeClass === 'simpleStep') && 'activelink'}
                  onClick={() => {
                    this.setState({ showPopup: !this.state.showPopup })
                    localStorage.setItem("menuActiveitem", "HomeInstructions");
                    !window.location.href.includes('instructions') && this.props.history.push('/instructions', { isViewFrom: "homeSidemenu" })
                  }}>
                 Instructions
                  < span className={activeClass === "HomeInstructions" ? (this.state.showPopup ? 'icon-down icon-css icon-up' : 'icon-down icon-css icon-right') : 'icon-right icon-css'} ></span>
                </a>
              </li> */}
              {this.state.showPopup &&
                <div className="moreOptions">
                  <ul className='moreUl'>
                    <li className={activeClass === 'overuse' ? "moreLi active" : "moreLi"}> <a href="https://vimeo.com/393712968/c5c402fa21" target="_blank" > Signs of overuse </a></li>
                    <li className={activeClass === 'Monitorphoto' ? "moreLi active" : "moreLi"}> <a href="https://vimeo.com/395418581/9ef15f1b21" target="_blank">Monitoring your photographs</a></li>
                    <li className={activeClass === 'simpleStep' ? "moreLi active" : "moreLi"}> <a href="https://res.cloudinary.com/nimble-chapps/image/upload/v1594893252/OraLift/qezhgsfudxbso2umzflu.pdf" target="_blank">How to calculate your programme-5 Simple Steps</a></li>
                  </ul>
                </div>
              }
              <li className={activeClass === "Photo" && "active"}>
                <a href="javascript:void(0)" onClick={() => this.onSideItemClick("Photo")}>
                  Photos
                </a>
              </li>
              <li className={activeClass === "HomeProfile" && "active"}>
                <a href="javascript:void(0)" className={(activeClass === 'ViewQuestionnaire' || activeClass === 'ViewPreferences' || activeClass === 'Agreement' || activeClass === 'viewQuestionnaireBiweekly'
                  || activeClass === 'faq' || activeClass === 'feedback' || activeClass === 'freewaySpaceView' || activeClass === 'ViewReport') &&
                  'activelink'}
                  onClick={() => {
                    this.setState({ showMorepopup: !this.state.showMorepopup })
                    localStorage.setItem("menuActiveitem", "HomeProfile");
                    !window.location.href.includes('profile') && this.props.history.push('/profile', { isViewFrom: "home" })
                  }}
                >
                  Your Data
                  <span className={activeClass === "HomeProfile" ? (this.state.showMorepopup ? 'icon-down  icon-css icon-up' : 'icon-down icon-css icon-right') : 'icon-right icon-css'} ></span>
                </a>

                {this.state.showMorepopup &&
                  <div className="moreOptions">
                    <ul className='moreUl'>
                      <li className={activeClass === 'ViewQuestionnaire' ? "moreLi active" : "moreLi"}> <a href="javascript:void(0)" onClick={() => { this.onSideItemClick("ViewQuestionnaire") }} > Questionnaire</a></li>
                      <li className={activeClass === 'StartingPoint' ? "moreLi active" : "moreLi"}> <a href="javascript:void(0)" onClick={() => { this.onSideItemClick("StartingPoint") }}>Your starting point</a></li>
                      <li className={activeClass === 'Agreement' ? "moreLi active" : "moreLi"}> <a href="javascript:void(0)" onClick={() => { this.onSideItemClick("Agreement") }}>Agreement</a></li>
                      <li className={activeClass === 'viewQuestionnaireBiweekly' ? "moreLi active" : "moreLi"}> <a href="javascript:void(0)" onClick={() => { this.onSideItemClick("viewQuestionnaireBiweekly") }}>Questionnaire - Biweekly</a></li>
                      {/* <li className={activeClass === 'faq' ? "moreLi active" : "moreLi"}> <a href="https://oralift.com/gb/faqs/" target="_blank" onClick={() => { this.onSideItemClick("faq") }}>FAQ</a></li> */}
                      {/* <li className={activeClass === 'feedback' ? "moreLi active" : "moreLi"}> <a href="javascript:void(0)" onClick={() => { this.onSideItemClick("feedback") }}>Feedback</a></li> */}
                      <li className={activeClass === 'freewaySpaceView' ? "moreLi active" : "moreLi"}> <a href="javascript:void(0)" onClick={() => { this.onSideItemClick("freewaySpaceView") }}>Freeway - Space</a></li>
                      <li className={activeClass === 'ViewReport' ? "moreLi active" : "moreLi"}> <a href="javascript:void(0)" onClick={() => { this.onSideItemClick("ViewReport") }}>Signs of overuse</a></li>
                      <li className='moreLi'> <a href="/" onClick={() => { this.onSideItemClick("logout") }} >Logout</a></li>
                    </ul>
                  </div>
                }
              </li>

            </ul>
          </Scrollbars>
        </div>
      </div >
    );
  }
}
export default HomeSideMenu;
