import React from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import './thankyou.scss';
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from '../../../components/sidebar/sidemenu'
import Header from '../../../components/header/header';
import Button from '../../../components/Button/button'
import ThankyouImg from '../../../assets/images/thumbsUp.svg';
import TextField from '../../../components/textfield/textfield';
import { Row, Col } from 'react-bootstrap';

class ThankYou extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
        setLocalActiveClass("3mmTouchTest")
    }
    onNextClick() {
        this.props.history.push('/5simplesteps')
    }

    render() {
        return (
            <div className='main-Thankyou'>
                <Header></Header>
                <SideMenu {...this.props}></SideMenu>
                <div className='inner-Thankyou'>
                    <Scrollbars>
                        <div className='main-Container'>
                            <div className='img-Container'>
                                <img src={ThankyouImg} className="logo" alt="logo" />
                            </div>
                            <h2 className='paddingB15'>Thank You-For Completing Both Touch Tests</h2>
                            <div className="content-Section">
                                <h5 className="paddingB15">
                                    You do not have to wear your device for the next 2 days. You will be sent a reminder on Thursday 9th May.
                                </h5>
                                <h5 className="paddingB15">
                                    In the meantime, please read the section about Signs of Overuse and report any of these when you receive the reminder. This will help to tailor your programme.
                                </h5>
                                <h5 className="paddingB15">In 3 days wear your device for 1 minute in the morning and 1 minute in the evening.</h5>
                            </div>
                            <h5 className='aLink' ><a onClick={() => this.onNextClick()} className=''>See how your programme is calculated<span className='icon-right icon-css'></span></a></h5>
                        </div>
                    </Scrollbars>
                </div>
            </div>

        )
    }
}




export default ThankYou;

