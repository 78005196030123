import React from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import { Scrollbars } from "react-custom-scrollbars";
import './touchtest.scss';
import SideMenu from '../../../components/sidebar/sidemenu'
import Header from '../../../components/header/header';
import Button from '../../../components/Button/button'
import TouchTestImg from '../../../assets/images/touchClick.svg';
import ReactPlayer from "../../../components/videoplayer/videoplayer";
import HomeSideMenu from '../../../components/homesidebar/homesidebar';

class TouchTest extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isViewFrom: this.props.history.location.state.isViewFrom
        }
        // setLocalActiveClass("TouchTest")
        { this.state.isViewFrom === 'homeTouchTest' ? setLocalActiveClass("HomeInstructions") : setLocalActiveClass("TouchTest") }
    }
    onGoClick() {

        { this.state.isViewFrom === 'homeTouchTest' ? this.props.history.push("/instructions", { isViewFrom: this.state.isViewFrom }) : this.props.history.push('/5mmtouchtest', { isViewFrom: '5mm Touch Test' }) }

    }

    render() {
        return (
            <div className='main-TouchTest'>
                <Header></Header>
                {this.state.isViewFrom === 'homeTouchTest' ? <HomeSideMenu {...this.props} ></HomeSideMenu> : <SideMenu {...this.props}></SideMenu>}

                {/* <SideMenu {...this.props}></SideMenu> */}
                <div className='inner-TouchTest'>
                    <Scrollbars>
                        <div className='main-Container'>
                            <div className='img-Container'>
                                <img src={TouchTestImg} className="logo" alt="logo" />
                            </div>
                            <h2 className='paddingB15'>Touch Test Introduction</h2>
                            <div className='content-Section'>
                                <h5>Now that you have fitted your 5mm device, it is time to do the Touch Test. Please watch the Touch Test video.</h5>
                            </div>
                            {/* <VideoPlayer></VideoPlayer> */}
                            <ReactPlayer url={"https://vimeo.com/371354808/a7eb901aa5"} controls={true}></ReactPlayer>
                            <Button title={this.state.isViewFrom === 'homeTouchTest' ? 'go to instruction' : 'take a touch test'}
                                onClick={() => this.onGoClick()}
                            ></Button>
                        </div>
                    </Scrollbars>
                </div>
            </div>

        )
    }
}




export default TouchTest;

